import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    updateRequestParameters,
    addService,
    addServiceValidation,
    deleteService,
    editService,
    requestServices,
    resetServicesPage,
    startEditingService,
    stopEditingService,
    updateService
} from './actions';

import '../../css/features/Services.scss';
import classNames from 'classnames';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';

import { Accordion, AccordionTab } from '../base/CustomAccordion';
import EditButtons from '../base/EditButtons';
import DeleteDialog from '../base/DeleteDialog';
import UserCustomFields from '../base/UserCustomFields';
import FormButtons from '../base/FormButtons';
import CustomChips from '../base/CustomChips';
import CustomInputTextarea from '../base/CustomInputTextarea';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import { Spinner } from 'primereact/spinner';
import { Config } from '../../config/Config';
import { Button } from 'primereact/button';

export class Services extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;
        this.defaultServiceFields = Config.multitenantServices
            ? {
                  //general settings
                  _id: '',
                  name: '',
                  logo: '',
                  publicServiceFields: [],
                  originRestrictions: [],
                  active: true,
                  serviceEmailAddress: '',

                  //meetings settings
                  'meetings.communicatorUrl': '',
                  'meetings.adviserUrl': '',
                  'meetings.participantsLimit': 0,
                  'meetings.enableAdHoc': false,
                  'meetings.enableRecording': true,
                  'meetings.enableRecordingConfirmation': false,
                  'meetings.enableAutoRecording': false,
                  'meetings.enableCommunicatorContactForm': false,
                  'meetings.disableCommunicatorFileTransfer': false,
                  'meetings.disableFileAttachmentsForParticipants': false,
                  'meetings.disableFileDownloadsForParticipants': false,
                  'meetings.maxTotalAttachmentsSize': 0,
                  'meetings.fileMaxSize': 0,
                  'meetings.acceptDocumentsTypesModerators': '',
                  'meetings.acceptDocumentsTypesAttendees': '',
                  'meetings.allowedMimes': '',
                  'meetings.showInsign': false,
                  'meetings.meetingsLimit': null,
                  'meetings.addCurrentUserToNewMeeting': false,
                  'meetings.addAdditionalModerators': false,
                  'meetings.keldanoFeDomain': '',
                  'meetings.keldanoApiDomain': '',
                  'meetings.attachAgenda': false,
                  'meetings.attachProtocol': false,
                  'meetings.attendeesSendPostProcessingMailEnabled': false,
                  'meetings.hideToolbar': false,
                  'meetings.preselectInvitationMailModerators': false,
                  'meetings.preselectInvitationMailAttendees': false,
                  'meetings.preselectPostProcessingMailModerators': false,
                  'meetings.preselectPostProcessingMailAttendees': false,

                  'meetings.localesOverwrites': {},
                  'meetings.mailTemplate.logo': '',
                  'meetings.mailTemplate.company': '',
                  'meetings.mailTemplate.address': '',
                  'meetings.mailTemplate.phone': '',
                  'meetings.mailTemplate.webPage': '',
                  'meetings.mailTemplate.faxNumber': '',

                  'meetings.mail.credentials.username': '',
                  'meetings.mail.credentials.password': '',
                  'meetings.mail.fromAddress': '',
                  'meetings.mail.fromName': '',
                  'meetings.mail.replyToAddress': '',
                  'meetings.mail.replyToName': '',
                  'meetings.mail.returnPathAddress': '',
                  'meetings.mail.returnPathName': '',
                  'meetings.mail.smtpServer': '',
                  'meetings.mail.smtpPort': 0,
                  'meetings.mail.method': '',

                  'meetings.keycloak.domain': '',

                  //webRtc settings
                  'webRtc.mode': 'liveSwitchSfu',
                  'webRtc.url': '',
                  'webRtc.secret': '',
                  'webRtc.applicationId': '',
                  'webRtc.apiKey': '',
                  'webRtc.maxAudioBitrate': 0,
                  'webRtc.maxVideoBitrate': 0,
                  'webRtc.useSimulcast': true,
                  'webRtc.iceServerAddress': '',
                  'webRtc.relayUsername': '',
                  'webRtc.relayPassword': '',

                  //keycloak settings
                  'keycloak.url': '',
                  'keycloak.realm': '',
                  'keycloak.clientId': '',
                  'keycloak.kid': '',
                  'keycloak.publicKey': '',

                  //keldanoApi settings
                  'keldanoApi.url': '',
                  'keldanoApi.clientId': '',
                  'keldanoApi.clientSecret': '',
                  'keldanoApi.username': '',
                  'keldanoApi.password': '',
                  'keldanoApi.apiEndpoint': '',

                  //webinar settings
                  'webinar.maxAdvisers': 2,
                  'webinar.maxParticipants': 30,
                  'webinar.disableVideoForParticipants': false,

                  //phoneConsultingSettings
                  'phoneConsulting.enableAdHoc': false,

                  //nepatec settings
                  'nepatecSettings.active': false,
                  'nepatecSettings.signatureTemplates': true,
                  'nepatecSettings.useSSL': true,
                  'nepatecSettings.individualLogin': false,
                  'nepatecSettings.url': '',
                  'nepatecSettings.controllerName': '',
                  'nepatecSettings.controllerPassword': '',

                  //settings (flags)
                  experimentalMode: false,
                  meetings: false,
                  oneToOne: false,
                  videoIdent: false,
                  branch: false,
                  keldanoApi: false,
                  webinar: false,
                  hideAdviserList: false,
                  phoneConsulting: false,

                  //logging flags
                  logSessions: false,
                  logChat: false,
                  adviserTracking: false,

                  //adviserUserCustomFields
                  adviserUserCustomFields: [
                      {
                          defaultValue: '',
                          description: '',
                          label: 'First Name',
                          options: [],
                          pattern: '',
                          required: false,
                          type: 'text',
                          usage: 'both',
                          _id: 'firstName'
                      },
                      {
                          defaultValue: '',
                          description: '',
                          label: 'E-Mail',
                          options: [],
                          pattern: '',
                          required: false,
                          type: 'email',
                          usage: 'both',
                          _id: 'email'
                      }
                  ]
              }
            : {
                  //general settings
                  _id: '',
                  name: '',
                  logo: '',
                  authMethod: 'default',
                  publicServiceFields: [],
                  originRestrictions: [],
                  active: true,
                  serviceEmailAddress: '',

                  //meetings settings
                  'meetings.sharedApplicationsUrl': '',
                  'meetings.meetingsManagerUrl': '',
                  'meetings.communicatorUrl': '',
                  'meetings.adviserUrl': '',
                  'meetings.participantsLimit': 0,
                  'meetings.enableAdHoc': false,
                  'meetings.enableRecording': true,
                  'meetings.enableRecordingConfirmation': false,
                  'meetings.enableAutoRecording': false,
                  'meetings.disableCommunicatorFileTransfer': false,
                  'meetings.enableCommunicatorContactForm': false,

                  //webRtc settings
                  'webRtc.mode': 'liveSwitchSfu',
                  'webRtc.url': '',
                  'webRtc.secret': '',
                  'webRtc.applicationId': '',
                  'webRtc.apiKey': '',
                  'webRtc.maxAudioBitrate': 0,
                  'webRtc.maxVideoBitrate': 0,
                  'webRtc.useSimulcast': true,
                  'webRtc.iceServerAddress': '',
                  'webRtc.relayUsername': '',
                  'webRtc.relayPassword': '',

                  //keycloak settings
                  'keycloak.url': '',
                  'keycloak.realm': '',
                  'keycloak.clientId': '',
                  'keycloak.kid': '',
                  'keycloak.publicKey': '',

                  //keldanoApi settings
                  'keldanoApi.url': '',
                  'keldanoApi.clientId': '',
                  'keldanoApi.clientSecret': '',
                  'keldanoApi.username': '',
                  'keldanoApi.password': '',
                  'keldanoApi.apiEndpoint': '',

                  //webinar settings
                  'webinar.maxAdvisers': 2,
                  'webinar.maxParticipants': 30,
                  'webinar.disableVideoForParticipants': false,

                  //phoneConsultingSettings
                  'phoneConsulting.enableAdHoc': false,

                  //settings (flags)
                  experimentalMode: false,
                  meetings: false,
                  oneToOne: false,
                  videoIdent: false,
                  branch: false,
                  keldanoApi: false,
                  webinar: false,
                  hideAdviserList: false,
                  phoneConsulting: false,

                  //logging flags
                  logSessions: false,
                  logChat: false,
                  adviserTracking: false,

                  //adviserUserCustomFields
                  adviserUserCustomFields: [
                      {
                          defaultValue: '',
                          description: '',
                          label: 'First Name',
                          options: [],
                          pattern: '',
                          required: false,
                          type: 'text',
                          usage: 'both',
                          _id: 'firstName'
                      },
                      {
                          defaultValue: '',
                          description: '',
                          label: 'E-Mail',
                          options: [],
                          pattern: '',
                          required: false,
                          type: 'email',
                          usage: 'both',
                          _id: 'email'
                      }
                  ]
              };

        this.state = {
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleEditForm = this.handleEditForm.bind(this);
        this.handleAddValidation = this.handleAddValidation.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.updateRequestParameters({
            range: { startIndex: 0, endIndex: this.rows - 1 }
        });
        this.props.requestServices();
    }

    componentWillUnmount() {
        this.props.resetServicesPage();
    }

    handleSearchChange(searchKey) {
        this.props.updateRequestParameters({
            search: searchKey ? [`_id=${searchKey}`, `name=${searchKey}`] : [],
            range: { startIndex: 0, endIndex: this.rows - 1 }
        });
        this.props.requestServices();
    }

    handlePageClick(event) {
        this.props.updateRequestParameters({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            }
        });
        this.props.requestServices();
    }

    handleEditClick(serviceId) {
        if (
            this.props.isEditing &&
            serviceId === this.props.serviceToEdit._id
        ) {
            this.props.stopEditingService();
        } else {
            //find service object with matching serviceId to edit
            const service = this.props.services.find(item => {
                return item._id === serviceId;
            });

            //build service object using given property values or default values if necessary
            let serviceData = { _id: service._id };
            Object.keys(this.defaultServiceFields).forEach(prop => {
                if (service.hasOwnProperty(prop)) {
                    serviceData[prop] = service[prop];
                } else {
                    serviceData[prop] = this.defaultServiceFields[prop];
                }
            });

            this.props.startEditingService(serviceData);
        }
    }

    handleDeleteClick(serviceId) {
        this.setState({
            showDeleteDialog: true,
            itemToDeleteId: serviceId,
            itemToDeleteName: serviceId
        });
    }

    handleDeleteConfirm() {
        const { services, contentRange } = this.props;
        let currentItems = { ...contentRange.currentItems };

        // if the item to be deleted is the last one on the currently selected table page, request the items for the previous table page
        if (
            currentItems.startIndex === currentItems.endIndex &&
            services[services.length - 1]._id === this.state.itemToDeleteId
        ) {
            currentItems = {
                startIndex: currentItems.startIndex - this.rows,
                endIndex: currentItems.startIndex - 1
            };
            if (currentItems.startIndex < 0) {
                currentItems = undefined;
            }
        }

        this.props.updateRequestParameters({
            range: currentItems
        });
        this.props.deleteService(this.state.itemToDeleteId);

        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleDeleteCancel() {
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleEditForm(currentFields, update) {
        this.props.editService(currentFields, update);
    }

    handleAddValidation(validationType) {
        const validationSet = this.props.isEditing
            ? this.props.editValidationSet
            : this.props.addValidationSet;
        if (!validationSet.hasOwnProperty(validationType)) {
            this.props.addServiceValidation(validationType);
        }
    }

    handleSaveClick() {
        if (this.props.isEditing) {
            this.props.updateService(this.props.serviceToEdit._id);
        } else {
            this.props.addService();
        }
    }

    handleCancelClick() {
        this.props.stopEditingService();
    }

    render() {
        const formProps = {
            isLoading: this.props.isLoading,
            isEditing: this.props.isEditing,
            serviceFormFields:
                (this.props.isEditing
                    ? this.props.serviceToEdit
                    : this.props.serviceToAdd) || this.defaultServiceFields,
            validationSet: this.props.isEditing
                ? this.props.editValidationSet
                : this.props.addValidationSet,
            user: this.props.user,
            language: this.props.language,
            onEditForm: this.handleEditForm,
            onAddValidation: this.handleAddValidation,
            onSaveClick: this.handleSaveClick,
            onCancelClick: this.handleCancelClick
        };

        return (
            <div className="p-grid">
                <div className="p-col-12 p-xl-8">
                    <ServicesTable
                        isLoading={this.props.isLoading}
                        editedRowId={
                            this.props.isEditing
                                ? this.props.serviceToEdit._id
                                : undefined
                        }
                        services={this.props.services}
                        language={this.props.language}
                        rows={this.rows}
                        contentRange={this.props.contentRange}
                        onSearchChange={this.handleSearchChange}
                        onPageClick={this.handlePageClick}
                        onEditClick={this.handleEditClick}
                        onDeleteClick={this.handleDeleteClick}
                    />
                </div>

                <div className="p-col-12 p-xl-4">
                    {Config.multitenantServices ? (
                        <MultitenantServiceForm {...formProps} />
                    ) : (
                        <ServiceForm {...formProps} />
                    )}
                </div>

                <DeleteDialog
                    visible={this.state.showDeleteDialog}
                    header={'servicesDeleteHeader'}
                    dialog={'servicesDeleteDialog'}
                    itemToDelete={this.state.itemToDeleteName}
                    onDeleteConfirm={this.handleDeleteConfirm}
                    onDeleteCancel={this.handleDeleteCancel}
                />
            </div>
        );
    }
}

class ServicesTable extends Component {
    constructor(props) {
        super(props);

        this.fetchSearchResultsTimer = null;

        this.state = {
            searchKey: ''
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(e) {
        const searchKey = e.target.value;
        this.setState({ searchKey: searchKey });

        if (this.fetchSearchResultsTimer !== null) {
            clearTimeout(this.fetchSearchResultsTimer);
            this.fetchSearchResultsTimer = null;
        }

        this.fetchSearchResultsTimer = setTimeout(() => {
            this.props.onSearchChange(searchKey);

            clearTimeout(this.fetchSearchResultsTimer);
            this.fetchSearchResultsTimer = null;
        }, 1000);
    }

    renderSearchField() {
        const { language } = this.props;
        return (
            <div className="search-field">
                <form>
                    <InputText
                        value={this.state.searchKey}
                        placeholder={getTranslatedString(
                            language,
                            'servicesSearch'
                        )}
                        onChange={this.handleSearchChange}
                    />
                </form>
            </div>
        );
    }

    renderDataTable() {
        const {
            services,
            isLoading,
            rows,
            editedRowId,
            contentRange
        } = this.props;

        const servicesTableEntries = services.map((item, key) => {
            let service = { ...item };

            if (service.hasOwnProperty('logo')) {
                service.logo = (
                    <img
                        className="logo"
                        key={key}
                        src={service.logo}
                        alt={''}
                    />
                );
            }

            if (service.hasOwnProperty('active')) {
                service.active = service.active ? (
                    <span className="pi-md-check icon active" key={key} />
                ) : (
                    <span className="pi-md-block icon inactive" key={key} />
                );
            }

            if (service.hasOwnProperty('logSessions')) {
                service.logSessions = service.logSessions ? (
                    <span className="pi-md-check icon active" key={key} />
                ) : (
                    <span className="pi-md-block icon inactive" key={key} />
                );
            }

            if (service.hasOwnProperty('logChat')) {
                service.logChat = service.logChat ? (
                    <span className="pi-md-check icon active" key={key} />
                ) : (
                    <span className="pi-md-block icon inactive" key={key} />
                );
            }

            service.editServiceButtons = (
                <EditButtons
                    key={key}
                    id={service._id}
                    name={service._id}
                    isLoading={isLoading}
                    onEditClick={this.props.onEditClick}
                    onDeleteClick={this.props.onDeleteClick}
                />
            );

            return service;
        });

        return (
            <DataTable
                className="table"
                autoLayout={true}
                responsive={true}
                value={servicesTableEntries}
                rows={rows}
                rowClassName={rowData => {
                    return {
                        'row-bg': rowData && rowData._id === editedRowId
                    };
                }}
                paginator={true}
                paginatorPosition={'top'}
                alwaysShowPaginator={true}
                lazy={true}
                totalRecords={
                    contentRange ? contentRange.totalItems : undefined
                }
                first={contentRange ? contentRange.currentItems.startIndex : 0}
                onPage={this.props.onPageClick}
            >
                <Column
                    className="column id-col"
                    field="_id"
                    header={<TranslatedString id={'servicesTableId'} />}
                />
                <Column
                    className="column name-col"
                    field="name"
                    header={<TranslatedString id={'servicesTableName'} />}
                />
                <Column
                    className="column logo-col"
                    field="logo"
                    header={<TranslatedString id={'servicesTableLogo'} />}
                />
                <Column
                    className="column sessionLog-col"
                    field="logSessions"
                    header={<TranslatedString id={'servicesTableSessionLog'} />}
                />
                <Column
                    className="column chatLog-col"
                    field="logChat"
                    header={<TranslatedString id={'servicesTableChatLog'} />}
                />
                <Column
                    className="column active-col"
                    field="active"
                    header={<TranslatedString id={'servicesTableActive'} />}
                />
                <Column
                    className="column edit-buttons-col"
                    field="editServiceButtons"
                />
            </DataTable>
        );
    }

    render() {
        return (
            <Card title={getTranslatedString(this.props.language, 'services')}>
                <React.Fragment>
                    {this.renderSearchField()}
                    {this.renderDataTable()}
                </React.Fragment>
            </Card>
        );
    }
}

class ServiceForm extends Component {
    constructor(props) {
        super(props);

        const authMethod = this.props.serviceFormFields.authMethod;
        const meetings = this.props.serviceFormFields.meetings;
        const webinar = this.props.serviceFormFields.webinar;
        const phoneConsulting = this.props.serviceFormFields.phoneConsulting;
        const keldanoApi = this.props.serviceFormFields.keldanoApi;

        const tabIds = {
            generalSettings: 0,
            options: 1,
            webRtcSettings: 2,
            meetingsSettings: 3,
            webinarSettings: 4,
            phoneConsultingSettings: 5,
            keldanoApiSettings: 6,
            keycloakSettings: 7,
            nepatecSettings: 8
        };

        this.state = {
            tabIds: tabIds,
            activeTabs: [
                tabIds.generalSettings,
                tabIds.options,
                tabIds.webRtcSettings,
                ...(meetings ? [tabIds.meetingsSettings] : []),
                ...(webinar ? [tabIds.webinarSettings] : []),
                ...(phoneConsulting ? [tabIds.phoneConsultingSettings] : []),
                ...(keldanoApi ? [tabIds.keldanoApiSettings] : []),
                ...(authMethod === 'keycloak' ? [tabIds.keycloakSettings] : []),
                tabIds.nepatecSettings
            ],
            currentAuthMethod: authMethod,
            currentMeetings: meetings,
            currentWebinar: webinar,
            currentPhoneConsulting: phoneConsulting,
            currentKeldanoApi: keldanoApi
        };

        this.validators = {
            nonEmpty: input => {
                // any non-empty input is valid
                return input ? input.trim() !== '' : false;
            },
            id: input => {
                // any non-empty input using the characters [a-zA-Z0-9-_] is valid
                return !!input.match(/^[A-Za-z0-9-_]+$/);
            }
        };
    }

    /**
     * handle active tabs of accordion depending on state of authMethod and meeetings flag
     * @param props
     * @param state
     * @returns {null|{activeTabs: T[], currentAuthMethod: string, currentMeetings: *}}
     */
    static getDerivedStateFromProps(props, state) {
        if (
            props.serviceFormFields.meetings !== state.currentMeetings ||
            props.serviceFormFields.webinar !== state.currentWebinar ||
            props.serviceFormFields.phoneConsulting !==
                state.currentPhoneConsulting ||
            props.serviceFormFields.authMethod !== state.currentAuthMethod ||
            props.serviceFormFields.keldanoApi !== state.currentKeldanoApi
        ) {
            const tabIds = state.tabIds;
            const authMethod = props.serviceFormFields.authMethod;
            const meetings = props.serviceFormFields.meetings;
            const webinar = props.serviceFormFields.webinar;
            const phoneConsulting = props.serviceFormFields.phoneConsulting;
            const keldanoApi = props.serviceFormFields.keldanoApi;
            const nepatec = props.serviceFormFields.nepatec;
            let activeTabs = state.activeTabs.slice(0);

            // show/hide meetings settings tab depending on whether 'meetings' flag is set
            if (meetings && !activeTabs.includes(tabIds.meetingsSettings)) {
                activeTabs.push(tabIds.meetingsSettings);
            } else if (
                !meetings &&
                activeTabs.includes(tabIds.meetingsSettings)
            ) {
                activeTabs = activeTabs.filter(
                    tab => tab !== tabIds.meetingsSettings
                );
            }

            // show/hide webinar settings tab depending on whether 'webinar' flag is set
            if (meetings && !activeTabs.includes(tabIds.webinarSettings)) {
                activeTabs.push(tabIds.webinarSettings);
            } else if (
                !meetings &&
                activeTabs.includes(tabIds.webinarSettings)
            ) {
                activeTabs = activeTabs.filter(
                    tab => tab !== tabIds.webinarSettings
                );
            }

            // show/hide phoneConsulting settings tab depending on whether 'webinar' flag is set
            if (
                meetings &&
                !activeTabs.includes(tabIds.phoneConsultingSettings)
            ) {
                activeTabs.push(tabIds.phoneConsultingSettings);
            } else if (
                !meetings &&
                activeTabs.includes(tabIds.phoneConsultingSettings)
            ) {
                activeTabs = activeTabs.filter(
                    tab => tab !== tabIds.phoneConsultingSettings
                );
            }

            // show/hide api settings tab depending on whether 'keldanoApi' flag is set and/or authMethod === 'keycloak'
            if (
                (keldanoApi || authMethod === 'keycloak') &&
                !activeTabs.includes(tabIds.keldanoApiSettings)
            ) {
                activeTabs.push(tabIds.keldanoApiSettings);
            } else if (
                !(keldanoApi || authMethod === 'keycloak') &&
                activeTabs.includes(tabIds.keldanoApiSettings)
            ) {
                activeTabs = activeTabs.filter(
                    tab => tab !== tabIds.keldanoApiSettings
                );
            }

            // show/hide keycloak settings tab depending on whether authMethod === 'keycloak'
            if (
                authMethod === 'keycloak' &&
                !activeTabs.includes(tabIds.keycloakSettings)
            ) {
                activeTabs.push(tabIds.keycloakSettings);
            } else if (
                authMethod !== 'keycloak' &&
                activeTabs.includes(tabIds.keycloakSettings)
            ) {
                activeTabs = activeTabs.filter(
                    tab => tab !== tabIds.keycloakSettings
                );
            }

            // show/hide nepatec settings tab depending on whether 'keldanoApi' flag is set
            if (keldanoApi && !activeTabs.includes(tabIds.nepatecSettings)) {
                activeTabs.push(tabIds.nepatecSettings);
            } else if (
                !keldanoApi &&
                activeTabs.includes(tabIds.nepatecSettings)
            ) {
                activeTabs = activeTabs.filter(
                    tab => tab !== tabIds.nepatecSettings
                );
            }

            return {
                activeTabs: activeTabs,
                currentAuthMethod: authMethod,
                currentMeetings: meetings,
                currentWebinar: webinar,
                currentPhoneConsulting: phoneConsulting,
                currentKeldanoApi: keldanoApi,
                currentNepatec: nepatec
            };
        }
        return null;
    }

    /**
     * onChange event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.serviceFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate service form fields
     * @param serviceFormFields
     * @param invalidCustomFieldIds
     * @returns {{invalidInputFields: {communicatorUrl: boolean, webRtcUrl: boolean, webRtcSecret: boolean, webRtcApplicationId: boolean, name: boolean, id: boolean, adviserUrl: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(serviceFormFields, invalidCustomFieldIds) {
        const invalidInputFields = {
            id: !this.validators.id(serviceFormFields._id),
            name: !this.validators.nonEmpty(serviceFormFields.name),
            webRtcUrl: !this.validators.nonEmpty(
                serviceFormFields['webRtc.url']
            ),
            webRtcSecret: !this.validators.nonEmpty(
                serviceFormFields['webRtc.secret']
            ),
            webRtcApplicationId: !this.validators.nonEmpty(
                serviceFormFields['webRtc.applicationId']
            ),
            webRtcApiKey: !this.validators.nonEmpty(
                serviceFormFields['webRtc.apiKey']
            ),
            invalidCustomFields: invalidCustomFieldIds.reduce(
                (acc, curr) => acc || curr,
                false
            )
        };
        if (serviceFormFields.authMethod === 'keycloak') {
            invalidInputFields.keycloakUrl = !this.validators.nonEmpty(
                serviceFormFields['keycloak.url']
            );
            invalidInputFields.keycloakRealm = !this.validators.nonEmpty(
                serviceFormFields['keycloak.realm']
            );
            invalidInputFields.keycloakClientId = !this.validators.nonEmpty(
                serviceFormFields['keycloak.clientId']
            );
            invalidInputFields.keycloakKid = !this.validators.nonEmpty(
                serviceFormFields['keycloak.kid']
            );
            invalidInputFields.keycloakPublicKey = !this.validators.nonEmpty(
                serviceFormFields['keycloak.publicKey']
            );
        }
        if (serviceFormFields.meetings) {
            invalidInputFields.communicatorUrl = !this.validators.nonEmpty(
                serviceFormFields['meetings.communicatorUrl']
            );
            invalidInputFields.adviserUrl = !this.validators.nonEmpty(
                serviceFormFields['meetings.adviserUrl']
            );
        }
        if (
            serviceFormFields.keldanoApi ||
            serviceFormFields.authMethod === 'keycloak'
        ) {
            invalidInputFields.keldanoApiUrl = !this.validators.nonEmpty(
                serviceFormFields['keldanoApi.url']
            );
            invalidInputFields.keldanoApiClientId = !this.validators.nonEmpty(
                serviceFormFields['keldanoApi.clientId']
            );
            invalidInputFields.keldanoApiClientSecret = !this.validators.nonEmpty(
                serviceFormFields['keldanoApi.clientSecret']
            );
            invalidInputFields.keldanoApiUsername = !this.validators.nonEmpty(
                serviceFormFields['keldanoApi.username']
            );
            invalidInputFields.keldanoApiPassword = !this.validators.nonEmpty(
                serviceFormFields['keldanoApi.password']
            );
        }
        if (serviceFormFields.keldanoApi) {
            invalidInputFields.keldanoApiEndpoint = !this.validators.nonEmpty(
                serviceFormFields['keldanoApi.apiEndpoint']
            );
        }

        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderGeneralSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesGeneral'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.id &&
                                        validationSet.hasOwnProperty('id')
                                })}
                                readOnly={this.props.isEditing}
                                value={serviceFormFields._id}
                                onChange={this.handleOnChange('_id')}
                                onBlur={this.handleOnBlur('id')}
                            />
                            <label>
                                <TranslatedString id={'servicesId'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.name &&
                                        validationSet.hasOwnProperty('name')
                                })}
                                value={serviceFormFields.name}
                                onChange={this.handleOnChange('name')}
                                onBlur={this.handleOnBlur('name')}
                            />
                            <label>
                                <TranslatedString id={'servicesName'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={serviceFormFields.logo}
                                onChange={this.handleOnChange('logo')}
                            />
                            <label>
                                <TranslatedString id={'servicesLogo'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Dropdown
                            className="form-input"
                            value={serviceFormFields.authMethod}
                            options={[
                                {
                                    label: 'default',
                                    value: 'default'
                                },
                                {
                                    label: 'keycloak',
                                    value: 'keycloak'
                                }
                            ]}
                            onChange={this.handleOnChange('authMethod')}
                            onBlur={this.handleOnBlur('authMethod')}
                        />
                        <label className="dropdown-label-float">
                            <TranslatedString id={'servicesAuthMethod'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <CustomChips
                            className="form-input"
                            value={serviceFormFields.publicServiceFields}
                            onChange={this.handleOnChange(
                                'publicServiceFields'
                            )}
                            label={
                                <TranslatedString
                                    id={'servicesPublicServiceFields'}
                                />
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <CustomChips
                            className="form-input"
                            value={serviceFormFields.originRestrictions}
                            onChange={this.handleOnChange('originRestrictions')}
                            label={
                                <TranslatedString
                                    id={'servicesOriginRestrictions'}
                                />
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={serviceFormFields.serviceEmailAddress}
                                onChange={this.handleOnChange(
                                    'serviceEmailAddress'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesServiceEmailAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields.active}
                            checked={serviceFormFields.active}
                            onChange={this.handleOnChange('active')}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesActive'} />
                        </label>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderMeetingsSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;
        const required = serviceFormFields.meetings;

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesMeetingsSettings'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.sharedApplicationsUrl'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.sharedApplicationsUrl'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesSharedApplicationsUrl'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.meetingsManagerUrl'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.meetingsManagerUrl'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMeetingsManagerUrl'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.communicatorUrl &&
                                        validationSet.hasOwnProperty(
                                            'communicatorUrl'
                                        )
                                })}
                                value={
                                    serviceFormFields[
                                        'meetings.communicatorUrl'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.communicatorUrl'
                                )}
                                onBlur={this.handleOnBlur('communicatorUrl')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesCommunicatorUrl'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.adviserUrl &&
                                        validationSet.hasOwnProperty(
                                            'adviserUrl'
                                        )
                                })}
                                value={serviceFormFields['meetings.adviserUrl']}
                                onChange={this.handleOnChange(
                                    'meetings.adviserUrl'
                                )}
                                onBlur={this.handleOnBlur('adviserUrl')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesAdviserUrl'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'meetings.participantsLimit'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'meetings.participantsLimit'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesParticipantLimit'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['meetings.enableAdHoc']}
                            checked={serviceFormFields['meetings.enableAdHoc']}
                            onChange={this.handleOnChange(
                                'meetings.enableAdHoc'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesEnableAdHoc'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields['meetings.enableRecording']
                            }
                            checked={
                                serviceFormFields['meetings.enableRecording']
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableRecording'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesEnableRecording'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.enableRecordingConfirmation'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.enableRecordingConfirmation'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableRecordingConfirmation'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesEnableRecordingConfirmation'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.enableAutoRecording'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.enableAutoRecording'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableAutoRecording'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesEnableAutoRecording'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.disableCommunicatorFileTransfer'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.disableCommunicatorFileTransfer'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.disableCommunicatorFileTransfer'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesDisableCommunicatorFileTransfer'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.enableCommunicatorContactForm'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.enableCommunicatorContactForm'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableCommunicatorContactForm'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesEnableCommunicatorContactForm'}
                            />
                        </label>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderWebRtcSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesWebRtcSettings'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <Dropdown
                            className="form-input"
                            value={serviceFormFields['webRtc.mode']}
                            options={[
                                {
                                    label: 'liveSwitchPeer',
                                    value: 'liveSwitchPeer'
                                },
                                {
                                    label: 'liveSwitchSfu',
                                    value: 'liveSwitchSfu'
                                },
                                {
                                    label: 'adiaP2P',
                                    value: 'adiaP2P'
                                }
                            ]}
                            onChange={this.handleOnChange('webRtc.mode')}
                        />
                        <label className="dropdown-label-float">
                            <TranslatedString id={'servicesWebRtcMode'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.webRtcUrl &&
                                        validationSet.hasOwnProperty(
                                            'webRtcUrl'
                                        )
                                })}
                                value={serviceFormFields['webRtc.url']}
                                onChange={this.handleOnChange('webRtc.url')}
                                onBlur={this.handleOnBlur('webRtcUrl')}
                            />
                            <label>
                                <TranslatedString id={'servicesWebRtcUrl'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.webRtcSecret &&
                                        validationSet.hasOwnProperty(
                                            'webRtcSecret'
                                        )
                                })}
                                value={serviceFormFields['webRtc.secret']}
                                onChange={this.handleOnChange('webRtc.secret')}
                                onBlur={this.handleOnBlur('webRtcSecret')}
                            />
                            <label>
                                <TranslatedString id={'servicesWebRtcSecret'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.webRtcApplicationId &&
                                        validationSet.hasOwnProperty(
                                            'webRtcApplicationId'
                                        )
                                })}
                                value={
                                    serviceFormFields['webRtc.applicationId']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.applicationId'
                                )}
                                onBlur={this.handleOnBlur(
                                    'webRtcApplicationId'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcApplicationId'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.webRtcApiKey &&
                                        validationSet.hasOwnProperty(
                                            'webRtcApiKey'
                                        )
                                })}
                                value={serviceFormFields['webRtc.apiKey']}
                                onChange={this.handleOnChange('webRtc.apiKey')}
                                onBlur={this.handleOnBlur('webRtcApiKey')}
                            />
                            <label>
                                <TranslatedString id={'servicesWebRtcApiKey'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'webRtc.maxAudioBitrate'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'webRtc.maxAudioBitrate'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcMaxAudioBitrate'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'webRtc.maxVideoBitrate'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'webRtc.maxVideoBitrate'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcMaxVideoBitrate'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['webRtc.useSimulcast']}
                            checked={serviceFormFields['webRtc.useSimulcast']}
                            onChange={this.handleOnChange(
                                'webRtc.useSimulcast'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesWebRtcUseSimulcast'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['webRtc.iceServerAddress']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.iceServerAddress'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcIceServerAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['webRtc.relayUsername']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.relayUsername'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcRelayUsername'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['webRtc.relayPassword']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.relayPassword'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcRelayPassword'}
                                />
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderKeycloakSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;
        const required = serviceFormFields.authMethod === 'keycloak';

        return !required ? null : (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesKeycloakSettings'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakUrl &&
                                        validationSet.hasOwnProperty(
                                            'keycloakUrl'
                                        )
                                })}
                                value={serviceFormFields['keycloak.url']}
                                onChange={this.handleOnChange('keycloak.url')}
                                onBlur={this.handleOnBlur('keycloakUrl')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakUrl'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakRealm &&
                                        validationSet.hasOwnProperty(
                                            'keycloakRealm'
                                        )
                                })}
                                value={serviceFormFields['keycloak.realm']}
                                onChange={this.handleOnChange('keycloak.realm')}
                                onBlur={this.handleOnBlur('keycloakRealm')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakRealm'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakClientId &&
                                        validationSet.hasOwnProperty(
                                            'keycloakClientId'
                                        )
                                })}
                                value={serviceFormFields['keycloak.clientId']}
                                onChange={this.handleOnChange(
                                    'keycloak.clientId'
                                )}
                                onBlur={this.handleOnBlur('keycloakClientId')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakClientId'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakKid &&
                                        validationSet.hasOwnProperty(
                                            'keycloakKid'
                                        )
                                })}
                                value={serviceFormFields['keycloak.kid']}
                                onChange={this.handleOnChange('keycloak.kid')}
                                onBlur={this.handleOnBlur('keycloakKid')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakKid'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <CustomInputTextarea
                                language={this.props.language}
                                className={classNames(
                                    'form-input',
                                    'input-textarea',
                                    {
                                        'p-error':
                                            required &&
                                            invalidInputFields.keycloakPublicKey &&
                                            validationSet.hasOwnProperty(
                                                'keycloakPublicKey'
                                            )
                                    }
                                )}
                                value={serviceFormFields['keycloak.publicKey']}
                                onChange={this.handleOnChange(
                                    'keycloak.publicKey'
                                )}
                                onBlur={this.handleOnBlur('keycloakPublicKey')}
                                label={
                                    getTranslatedString(
                                        this.props.language,
                                        'servicesKeycloakPublicKey'
                                    ) + (required ? ' *' : '')
                                }
                            />
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderApiSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;
        const required =
            serviceFormFields.keldanoApi ||
            serviceFormFields.authMethod === 'keycloak';

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesKeldanoApiSettings'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiUrl &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiUrl'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.url']}
                                onChange={this.handleOnChange('keldanoApi.url')}
                                onBlur={this.handleOnBlur('keldanoApiUrl')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiUrl'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiClientId &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiClientId'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.clientId']}
                                onChange={this.handleOnChange(
                                    'keldanoApi.clientId'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiClientId')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiClientId'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiClientSecret &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiClientSecret'
                                        )
                                })}
                                value={
                                    serviceFormFields['keldanoApi.clientSecret']
                                }
                                onChange={this.handleOnChange(
                                    'keldanoApi.clientSecret'
                                )}
                                onBlur={this.handleOnBlur(
                                    'keldanoApiClientSecret'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiClientSecret'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiUsername &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiUsername'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.username']}
                                onChange={this.handleOnChange(
                                    'keldanoApi.username'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiUsername')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiUsername'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiPassword &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiPassword'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.password']}
                                onChange={this.handleOnChange(
                                    'keldanoApi.password'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiPassword')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiPassword'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        serviceFormFields.keldanoApi &&
                                        invalidInputFields.keldanoApiEndpoint &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiEndpoint'
                                        )
                                })}
                                value={
                                    serviceFormFields['keldanoApi.apiEndpoint']
                                }
                                onChange={this.handleOnChange(
                                    'keldanoApi.apiEndpoint'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiEndpoint')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeldanoApiEndpoint'
                                ) + (serviceFormFields.keldanoApi ? ' *' : '')}
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderWebinarSettingsPanel() {
        const { serviceFormFields } = this.props;
        const required = serviceFormFields.webinar;

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesWebinarSettings'
                )}
                key={85}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields['webinar.maxAdvisers']
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                max={5}
                                onChange={this.handleOnChange(
                                    'webinar.maxAdvisers'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebinarAdvisers'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'webinar.maxParticipants'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                max={100}
                                onChange={this.handleOnChange(
                                    'webinar.maxParticipants'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebinarParticipants'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'webinar.disableVideoForParticipants'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'webinar.disableVideoForParticipants'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'webinar.disableVideoForParticipants'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={
                                    'servicesWebinarDisableVideoForParticipants'
                                }
                            />
                        </label>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderPhoneConsultingSettingsPanel() {
        const { serviceFormFields } = this.props;
        const required = serviceFormFields.phoneConsulting;

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesPhoneConsultingSettings'
                )}
                key={86}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'phoneConsulting.enableAdHoc'
                                ]
                            }
                            checked={
                                serviceFormFields['phoneConsulting.enableAdHoc']
                            }
                            onChange={this.handleOnChange(
                                'phoneConsulting.enableAdHoc'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesEnableAdHoc'} />
                        </label>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderOptionsPanel() {
        const serviceFormFields = this.props.serviceFormFields;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesOptions'
                )}
            >
                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.experimentalMode}
                        checked={serviceFormFields.experimentalMode}
                        onChange={this.handleOnChange('experimentalMode')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesExperimentalMode'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.meetings}
                        checked={serviceFormFields.meetings}
                        onChange={this.handleOnChange('meetings')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesMeetings'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.oneToOne}
                        checked={serviceFormFields.oneToOne}
                        onChange={this.handleOnChange('oneToOne')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesOneToOne'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.videoIdent}
                        checked={serviceFormFields.videoIdent}
                        onChange={this.handleOnChange('videoIdent')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesVideoIdent'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.branch}
                        checked={serviceFormFields.branch}
                        onChange={this.handleOnChange('branch')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesBranch'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.keldanoApi}
                        checked={serviceFormFields.keldanoApi}
                        onChange={this.handleOnChange('keldanoApi')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesKeldanoApi'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.webinar}
                        checked={serviceFormFields.webinar}
                        onChange={this.handleOnChange('webinar')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesWebinar'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.phoneConsulting}
                        checked={serviceFormFields.phoneConsulting}
                        onChange={this.handleOnChange('phoneConsulting')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesPhoneConsulting'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.hideAdviserList}
                        checked={serviceFormFields.hideAdviserList}
                        onChange={this.handleOnChange('hideAdviserList')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesHideAdviserList'} />
                    </label>
                </div>
            </AccordionTab>
        );
    }

    renderLoggingPanel() {
        const serviceFormFields = this.props.serviceFormFields;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesLogging'
                )}
            >
                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.logSessions}
                        checked={serviceFormFields.logSessions}
                        onChange={this.handleOnChange('logSessions')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesSessionLog'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.logChat}
                        checked={serviceFormFields.logChat}
                        onChange={this.handleOnChange('logChat')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesChatLog'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.adviserTracking}
                        checked={serviceFormFields.adviserTracking}
                        onChange={this.handleOnChange('adviserTracking')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesAdviserTracking'} />
                    </label>
                </div>
            </AccordionTab>
        );
    }

    renderAdviserUserCustomFieldsPanel() {
        const serviceFormFields = this.props.serviceFormFields;
        const customFieldIds = (
            serviceFormFields.adviserUserCustomFields || []
        ).map(customField => customField._id);
        const invalidCustomFieldIds = customFieldIds.map(
            customFieldId =>
                customFieldId === '' ||
                customFieldId === 'username' ||
                customFieldId === 'name' ||
                customFieldId === 'id' ||
                customFieldId === 'password' ||
                customFieldId === 'passwordConfirmation'
        );
        const adviserUserCustomFieldsPanel = (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesAdviserUserCustomFields'
                )}
            >
                <UserCustomFields
                    isLoading={this.props.isLoading}
                    serviceFormFields={serviceFormFields}
                    type={'adviserUserCustomFields'}
                    userCustomFields={serviceFormFields.adviserUserCustomFields}
                    error={invalidCustomFieldIds}
                    onEditForm={this.props.onEditForm}
                />
            </AccordionTab>
        );

        return {
            invalidCustomFieldIds: invalidCustomFieldIds,
            adviserUserCustomFieldsPanel: adviserUserCustomFieldsPanel
        };
    }

    render() {
        const {
            invalidCustomFieldIds,
            adviserUserCustomFieldsPanel
        } = this.renderAdviserUserCustomFieldsPanel();
        const { invalidInputFields, invalidInputs } = this.validateFormFields(
            this.props.serviceFormFields,
            invalidCustomFieldIds
        );
        const generalSettingsPanel = this.renderGeneralSettingsPanel(
            invalidInputFields
        );
        const webRtcSettingsPanel = this.renderWebRtcSettingsPanel(
            invalidInputFields
        );
        const optionsPanel = this.renderOptionsPanel();
        const loggingPanel = this.renderLoggingPanel();
        const meetingsSettingsPanel = this.renderMeetingsSettingsPanel(
            invalidInputFields
        );
        const apiSettingsPanel = this.renderApiSettingsPanel(
            invalidInputFields
        );
        const keycloakSettingsPanel = this.renderKeycloakSettingsPanel(
            invalidInputFields
        );
        const webinarSettingsPanel = this.renderWebinarSettingsPanel();
        const phoneConsultingSettingsPanel = this.renderPhoneConsultingSettingsPanel();

        return (
            <Card
                title={getTranslatedString(
                    this.props.language,
                    this.props.isEditing ? 'servicesEdit' : 'servicesAdd'
                )}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <Accordion
                            className="accordion"
                            multiple={true}
                            activeIndex={this.state.activeTabs}
                            onTabChange={e => {
                                this.setState({ activeTabs: e.index });
                            }}
                        >
                            {generalSettingsPanel}
                            {optionsPanel}
                            {webRtcSettingsPanel}
                            {meetingsSettingsPanel}
                            {webinarSettingsPanel}
                            {phoneConsultingSettingsPanel}
                            {apiSettingsPanel}
                            {keycloakSettingsPanel}
                            {loggingPanel}
                            {adviserUserCustomFieldsPanel}
                        </Accordion>
                    </div>

                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

class MultitenantServiceForm extends Component {
    constructor(props) {
        super(props);

        this.validators = {
            nonEmpty: input => {
                // any non-empty input is valid
                return input ? input.trim() !== '' : false;
            },
            id: input => {
                // any non-empty input using the characters [a-zA-Z0-9-_] is valid
                return !!input.match(/^[A-Za-z0-9-_]+$/);
            },
            logo: input => {
                // input must be a valid image address
                // 1st step: url validator
                if (
                    !input.match(
                        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\w]*))?)/
                    )
                ) {
                    return false;
                }

                // 2nd step: validate file ending
                // TODO: allow numbers as ending to handle VEMA case (direct url without file ending), find better solution?
                return !!input.match(/.*(.jpg|.jpeg|.png|[0-9])$/);
                // return !!input.match(/.*(.jpg|.jpeg|.png)$/);
            }
        };
    }

    /**
     * onChange event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.serviceFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate service form fields
     * @param serviceFormFields
     * @param invalidCustomFieldIds
     * @returns {{invalidInputFields: {communicatorUrl: boolean, webRtcUrl: boolean, webRtcSecret: boolean, webRtcApplicationId: boolean, name: boolean, id: boolean, adviserUrl: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(serviceFormFields, invalidCustomFieldIds) {
        const invalidInputFields = {
            id: !this.validators.id(serviceFormFields._id),
            name: !this.validators.nonEmpty(serviceFormFields.name),
            mailTemplateLogo: !this.validators.logo(
                serviceFormFields['meetings.mailTemplate.logo']
            ),
            ...(Array.isArray(invalidCustomFieldIds) && {
                invalidCustomFields: invalidCustomFieldIds.reduce(
                    (acc, curr) => acc || curr,
                    false
                )
            })
        };

        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderClientSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesClientSettings'
                )}
                key={0}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.id &&
                                        validationSet.hasOwnProperty('id')
                                })}
                                readOnly={this.props.isEditing}
                                value={serviceFormFields._id}
                                onChange={this.handleOnChange('_id')}
                                onBlur={this.handleOnBlur('id')}
                            />
                            <label>
                                <TranslatedString id={'servicesId'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.name &&
                                        validationSet.hasOwnProperty('name')
                                })}
                                value={serviceFormFields.name}
                                onChange={this.handleOnChange('name')}
                                onBlur={this.handleOnBlur('name')}
                            />
                            <label>
                                <TranslatedString id={'servicesName'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={serviceFormFields.logo}
                                onChange={this.handleOnChange('logo')}
                            />
                            <label>
                                <TranslatedString id={'servicesLogo'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.mailTemplateLogo &&
                                        validationSet.hasOwnProperty(
                                            'mailTemplateLogo'
                                        )
                                })}
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.logo'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.logo'
                                )}
                                onBlur={this.handleOnBlur('mailTemplateLogo')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateLogo'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.company'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.company'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateCompany'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.address'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.address'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.phone'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.phone'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplatePhone'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.webPage'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.webPage'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateWebPage'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.faxNumber'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.faxNumber'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateFaxNumber'}
                                />
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderGeneralSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesGeneral'
                )}
                key={10}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.id &&
                                        validationSet.hasOwnProperty('id')
                                })}
                                readOnly={this.props.isEditing}
                                value={serviceFormFields._id}
                                onChange={this.handleOnChange('_id')}
                                onBlur={this.handleOnBlur('id')}
                            />
                            <label>
                                <TranslatedString id={'servicesId'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.name &&
                                        validationSet.hasOwnProperty('name')
                                })}
                                value={serviceFormFields.name}
                                onChange={this.handleOnChange('name')}
                                onBlur={this.handleOnBlur('name')}
                            />
                            <label>
                                <TranslatedString id={'servicesName'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={serviceFormFields.logo}
                                onChange={this.handleOnChange('logo')}
                            />
                            <label>
                                <TranslatedString id={'servicesLogo'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <CustomChips
                            className="form-input"
                            value={serviceFormFields.publicServiceFields}
                            onChange={this.handleOnChange(
                                'publicServiceFields'
                            )}
                            label={
                                <TranslatedString
                                    id={'servicesPublicServiceFields'}
                                />
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <CustomChips
                            className="form-input"
                            value={serviceFormFields.originRestrictions}
                            onChange={this.handleOnChange('originRestrictions')}
                            label={
                                <TranslatedString
                                    id={'servicesOriginRestrictions'}
                                />
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={serviceFormFields.serviceEmailAddress}
                                onChange={this.handleOnChange(
                                    'serviceEmailAddress'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesServiceEmailAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields.active}
                            checked={serviceFormFields.active}
                            onChange={this.handleOnChange('active')}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesActive'} />
                        </label>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderMeetingsSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesMeetingsSettings'
                )}
                key={20}
            >
                <br />
                <div className="p-grid form-group">
                    {serviceFormFields['meetings.sharedApplicationsUrl'] && (
                        <div className="p-col-12">
                            <span className="md-inputfield">
                                <InputText
                                    className="form-input"
                                    readOnly
                                    value={
                                        serviceFormFields[
                                            'meetings.sharedApplicationsUrl'
                                        ]
                                    }
                                    onChange={this.handleOnChange(
                                        'meetings.sharedApplicationsUrl'
                                    )}
                                />
                                <label>
                                    <TranslatedString
                                        id={'servicesSharedApplicationsUrl'}
                                    />
                                </label>
                            </span>
                        </div>
                    )}

                    {serviceFormFields['meetings.meetingsManagerUrl'] && (
                        <div className="p-col-12">
                            <span className="md-inputfield">
                                <InputText
                                    className="form-input"
                                    readOnly
                                    value={
                                        serviceFormFields[
                                            'meetings.meetingsManagerUrl'
                                        ]
                                    }
                                    onChange={this.handleOnChange(
                                        'meetings.meetingsManagerUrl'
                                    )}
                                />
                                <label>
                                    <TranslatedString
                                        id={'servicesMeetingsManagerUrl'}
                                    />
                                </label>
                            </span>
                        </div>
                    )}

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.communicatorUrl &&
                                        validationSet.hasOwnProperty(
                                            'communicatorUrl'
                                        )
                                })}
                                value={
                                    serviceFormFields[
                                        'meetings.communicatorUrl'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.communicatorUrl'
                                )}
                                onBlur={this.handleOnBlur('communicatorUrl')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesCommunicatorUrl'
                                ) + ' *'}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.adviserUrl &&
                                        validationSet.hasOwnProperty(
                                            'adviserUrl'
                                        )
                                })}
                                value={serviceFormFields['meetings.adviserUrl']}
                                onChange={this.handleOnChange(
                                    'meetings.adviserUrl'
                                )}
                                onBlur={this.handleOnBlur('adviserUrl')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesAdviserUrl'
                                ) + ' *'}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'meetings.participantsLimit'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'meetings.participantsLimit'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesParticipantLimit'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['meetings.enableAdHoc']}
                            checked={serviceFormFields['meetings.enableAdHoc']}
                            onChange={this.handleOnChange(
                                'meetings.enableAdHoc'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesEnableAdHoc'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields['meetings.enableRecording']
                            }
                            checked={
                                serviceFormFields['meetings.enableRecording']
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableRecording'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesEnableRecording'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.enableRecordingConfirmation'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.enableRecordingConfirmation'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableRecordingConfirmation'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesEnableRecordingConfirmation'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.enableAutoRecording'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.enableAutoRecording'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableAutoRecording'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesEnableAutoRecording'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.disableCommunicatorFileTransfer'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.disableCommunicatorFileTransfer'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.disableCommunicatorFileTransfer'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesDisableCommunicatorFileTransfer'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.enableCommunicatorContactForm'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.enableCommunicatorContactForm'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.enableCommunicatorContactForm'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesEnableCommunicatorContactForm'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.disableFileAttachmentsForParticipants'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.disableFileAttachmentsForParticipants'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.disableFileAttachmentsForParticipants'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={
                                    'servicesDisableFileAttachmentsForParticipants'
                                }
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.disableFileDownloadsForParticipants'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.disableFileDownloadsForParticipants'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.disableFileDownloadsForParticipants'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={
                                    'servicesDisableFileDownloadsForParticipants'
                                }
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'meetings.maxTotalAttachmentsSize'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'meetings.maxTotalAttachmentsSize'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMaxTotalAttachmentsSize'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'meetings.fileMaxSize'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'meetings.fileMaxSize'
                                )}
                            />
                            <label>
                                <TranslatedString id={'servicesFileMaxSize'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.acceptDocumentsTypesModerators'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.acceptDocumentsTypesModerators'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={
                                        'servicesAcceptDocumentsTypesModerators'
                                    }
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.acceptDocumentsTypesAttendees'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.acceptDocumentsTypesAttendees'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesAcceptDocumentsTypesAttendees'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['meetings.allowedMimes']
                                }
                                onChange={this.handleOnChange(
                                    'meetings.allowedMimes'
                                )}
                            />
                            <label>
                                <TranslatedString id={'servicesAllowedMimes'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'meetings.meetingsLimit'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'meetings.meetingsLimit'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMeetingsLimit'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.addCurrentUserToNewMeeting'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.addCurrentUserToNewMeeting'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.addCurrentUserToNewMeeting'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesAddCurrentUserToNewMeeting'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.addAdditionalModerators'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.addAdditionalModerators'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.addAdditionalModerators'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesAddAdditionalModerators'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.keldanoFeDomain'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.keldanoFeDomain'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoFeDomain'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.keldanoApiDomain'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.keldanoApiDomain'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiDomain'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['meetings.attachAgenda']}
                            checked={serviceFormFields['meetings.attachAgenda']}
                            onChange={this.handleOnChange(
                                'meetings.attachAgenda'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesAttachAgenda'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields['meetings.attachProtocol']
                            }
                            checked={
                                serviceFormFields['meetings.attachProtocol']
                            }
                            onChange={this.handleOnChange(
                                'meetings.attachProtocol'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesAttachProtocol'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.attendeesSendPostProcessingMailEnabled'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.attendeesSendPostProcessingMailEnabled'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.attendeesSendPostProcessingMailEnabled'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={
                                    'servicesAttendeesSendPostProcessingMailEnabled'
                                }
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['meetings.hideToolbar']}
                            checked={serviceFormFields['meetings.hideToolbar']}
                            onChange={this.handleOnChange(
                                'meetings.hideToolbar'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesHideToolbar'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.preselectInvitationMailModerators'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.preselectInvitationMailModerators'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.preselectInvitationMailModerators'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesPreselectInvitationMailModerators'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.preselectInvitationMailAttendees'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.preselectInvitationMailAttendees'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.preselectInvitationMailAttendees'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesPreselectInvitationMailAttendees'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.preselectPostProcessingMailModerators'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.preselectPostProcessingMailModerators'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.preselectPostProcessingMailModerators'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={
                                    'servicesPreselectPostProcessingMailModerators'
                                }
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'meetings.preselectPostProcessingMailAttendees'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'meetings.preselectPostProcessingMailAttendees'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'meetings.preselectPostProcessingMailAttendees'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={
                                    'servicesPreselectPostProcessingMailAttendees'
                                }
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['meetings.showInsign']}
                            checked={serviceFormFields['meetings.showInsign']}
                            onChange={this.handleOnChange(
                                'meetings.showInsign'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesShowInsign'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.keycloak.domain'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.keycloak.domain'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeycloakDomain'}
                                />
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderLocalesOverwrites() {
        const serviceFormFields = this.props.serviceFormFields;
        const localesOverwrites =
            serviceFormFields['meetings.localesOverwrites'];

        const invalidOverwriteKeys = {};
        Object.keys(localesOverwrites || {}).forEach(language => {
            const overwriteKeys = (localesOverwrites[language] || []).map(
                overwrite => overwrite[0]
            );

            invalidOverwriteKeys[language] = overwriteKeys.map(
                (overwriteKey, index) =>
                    overwriteKey === '' ||
                    overwriteKeys.indexOf(overwriteKey) !== index
            );
        });

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesLocalesOverwrites'
                )}
                key={30}
            >
                <LocalesOverwrites
                    language={this.props.language}
                    isLoading={this.props.isLoading}
                    serviceFormFields={serviceFormFields}
                    localesOverwrites={localesOverwrites}
                    error={invalidOverwriteKeys}
                    onEdit={this.props.onEditForm}
                />
            </AccordionTab>
        );
    }

    renderMailTemplateSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesMailTemplateSettings'
                )}
                key={40}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields[
                                            'meetings.mailTemplate.logo'
                                        ] &&
                                        validationSet.hasOwnProperty(
                                            'meetings.mailTemplate.logo'
                                        )
                                })}
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.logo'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.logo'
                                )}
                                onBlur={this.handleOnBlur(
                                    'meetings.mailTemplate.logo'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateLogo'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.company'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.company'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateCompany'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.address'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.address'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.phone'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.phone'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplatePhone'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.webPage'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.webPage'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateWebPage'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mailTemplate.faxNumber'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mailTemplate.faxNumber'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailTemplateFaxNumber'}
                                />
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderMailSettingsPanel() {
        const serviceFormFields = this.props.serviceFormFields;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesMailSettings'
                )}
                key={50}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.credentials.username'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.credentials.username'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailCredentialsUsername'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                type="password"
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.credentials.password'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.credentials.password'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailCredentialsPassword'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.fromAddress'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.fromAddress'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailFromAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['meetings.mail.fromName']
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.fromName'
                                )}
                            />
                            <label>
                                <TranslatedString id={'servicesMailFromName'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.replyToAddress'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.replyToAddress'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailReplyToAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.replyToName'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.replyToName'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailReplyToName'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.returnPathAddress'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.returnPathAddress'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailReturnPathAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.returnPathName'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.returnPathName'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailReturnPathName'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields[
                                        'meetings.mail.smtpServer'
                                    ]
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.smtpServer'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesMailSmtpServer'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'meetings.mail.smtpPort'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'meetings.mail.smtpPort'
                                )}
                            />
                            <label>
                                <TranslatedString id={'servicesMailSmtpPort'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['meetings.mail.method']
                                }
                                onChange={this.handleOnChange(
                                    'meetings.mail.method'
                                )}
                            />
                            <label>
                                <TranslatedString id={'servicesMailMethod'} />
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderWebRtcSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesWebRtcSettings'
                )}
                key={60}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <Dropdown
                            className="form-input"
                            value={serviceFormFields['webRtc.mode']}
                            options={[
                                {
                                    label: 'liveSwitchPeer',
                                    value: 'liveSwitchPeer'
                                },
                                {
                                    label: 'liveSwitchSfu',
                                    value: 'liveSwitchSfu'
                                },
                                {
                                    label: 'adiaP2P',
                                    value: 'adiaP2P'
                                }
                            ]}
                            onChange={this.handleOnChange('webRtc.mode')}
                        />
                        <label className="dropdown-label-float">
                            <TranslatedString id={'servicesWebRtcMode'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.webRtcUrl &&
                                        validationSet.hasOwnProperty(
                                            'webRtcUrl'
                                        )
                                })}
                                value={serviceFormFields['webRtc.url']}
                                onChange={this.handleOnChange('webRtc.url')}
                                onBlur={this.handleOnBlur('webRtcUrl')}
                            />
                            <label>
                                <TranslatedString id={'servicesWebRtcUrl'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.webRtcSecret &&
                                        validationSet.hasOwnProperty(
                                            'webRtcSecret'
                                        )
                                })}
                                value={serviceFormFields['webRtc.secret']}
                                onChange={this.handleOnChange('webRtc.secret')}
                                onBlur={this.handleOnBlur('webRtcSecret')}
                            />
                            <label>
                                <TranslatedString id={'servicesWebRtcSecret'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames(
                                    'form-input'
                                    /*, {
                                        'p-error':
                                            invalidInputFields.webRtcApplicationId &&
                                            validationSet.hasOwnProperty(
                                                'webRtcApplicationId'
                                            )
                                    }*/
                                )}
                                readOnly
                                value={
                                    serviceFormFields['webRtc.applicationId']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.applicationId'
                                )}
                                /*onBlur={this.handleOnBlur(
                                    'webRtcApplicationId'
                                )}*/
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcApplicationId'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.webRtcApiKey &&
                                        validationSet.hasOwnProperty(
                                            'webRtcApiKey'
                                        )
                                })}
                                value={serviceFormFields['webRtc.apiKey']}
                                onChange={this.handleOnChange('webRtc.apiKey')}
                                onBlur={this.handleOnBlur('webRtcApiKey')}
                            />
                            <label>
                                <TranslatedString id={'servicesWebRtcApiKey'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'webRtc.maxAudioBitrate'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'webRtc.maxAudioBitrate'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcMaxAudioBitrate'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'webRtc.maxVideoBitrate'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                onChange={this.handleOnChange(
                                    'webRtc.maxVideoBitrate'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcMaxVideoBitrate'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['webRtc.useSimulcast']}
                            checked={serviceFormFields['webRtc.useSimulcast']}
                            onChange={this.handleOnChange(
                                'webRtc.useSimulcast'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={'servicesWebRtcUseSimulcast'}
                            />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['webRtc.iceServerAddress']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.iceServerAddress'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcIceServerAddress'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['webRtc.relayUsername']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.relayUsername'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcRelayUsername'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    serviceFormFields['webRtc.relayPassword']
                                }
                                onChange={this.handleOnChange(
                                    'webRtc.relayPassword'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebRtcRelayPassword'}
                                />
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderKeycloakSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;
        const required = serviceFormFields['keycloak.url'];

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesKeycloakSettings'
                )}
                key={70}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakUrl &&
                                        validationSet.hasOwnProperty(
                                            'keycloakUrl'
                                        )
                                })}
                                value={serviceFormFields['keycloak.url']}
                                onChange={this.handleOnChange('keycloak.url')}
                                onBlur={this.handleOnBlur('keycloakUrl')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakUrl'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakRealm &&
                                        validationSet.hasOwnProperty(
                                            'keycloakRealm'
                                        )
                                })}
                                value={serviceFormFields['keycloak.realm']}
                                onChange={this.handleOnChange('keycloak.realm')}
                                onBlur={this.handleOnBlur('keycloakRealm')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakRealm'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakClientId &&
                                        validationSet.hasOwnProperty(
                                            'keycloakClientId'
                                        )
                                })}
                                value={serviceFormFields['keycloak.clientId']}
                                onChange={this.handleOnChange(
                                    'keycloak.clientId'
                                )}
                                onBlur={this.handleOnBlur('keycloakClientId')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakClientId'
                                ) + (required ? ' *' : '')}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keycloakKid &&
                                        validationSet.hasOwnProperty(
                                            'keycloakKid'
                                        )
                                })}
                                value={serviceFormFields['keycloak.kid']}
                                onChange={this.handleOnChange('keycloak.kid')}
                                onBlur={this.handleOnBlur('keycloakKid')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeycloakKid'
                                )}
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <CustomInputTextarea
                                language={this.props.language}
                                className={classNames(
                                    'form-input',
                                    'input-textarea',
                                    {
                                        'p-error':
                                            required &&
                                            invalidInputFields.keycloakPublicKey &&
                                            validationSet.hasOwnProperty(
                                                'keycloakPublicKey'
                                            )
                                    }
                                )}
                                value={serviceFormFields['keycloak.publicKey']}
                                onChange={this.handleOnChange(
                                    'keycloak.publicKey'
                                )}
                                onBlur={this.handleOnBlur('keycloakPublicKey')}
                                label={
                                    getTranslatedString(
                                        this.props.language,
                                        'servicesKeycloakPublicKey'
                                    ) + (required ? ' *' : '')
                                }
                            />
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderApiSettingsPanel(invalidInputFields) {
        const serviceFormFields = this.props.serviceFormFields;
        const validationSet = this.props.validationSet;
        const required = serviceFormFields['keldanoApi.url'];

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesKeldanoApiSettings'
                )}
                key={80}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiUrl &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiUrl'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.url']}
                                onChange={this.handleOnChange('keldanoApi.url')}
                                onBlur={this.handleOnBlur('keldanoApiUrl')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiUrl'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiClientId &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiClientId'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.clientId']}
                                onChange={this.handleOnChange(
                                    'keldanoApi.clientId'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiClientId')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiClientId'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiClientSecret &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiClientSecret'
                                        )
                                })}
                                value={
                                    serviceFormFields['keldanoApi.clientSecret']
                                }
                                onChange={this.handleOnChange(
                                    'keldanoApi.clientSecret'
                                )}
                                onBlur={this.handleOnBlur(
                                    'keldanoApiClientSecret'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiClientSecret'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiUsername &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiUsername'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.username']}
                                onChange={this.handleOnChange(
                                    'keldanoApi.username'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiUsername')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiUsername'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        required &&
                                        invalidInputFields.keldanoApiPassword &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiPassword'
                                        )
                                })}
                                value={serviceFormFields['keldanoApi.password']}
                                onChange={this.handleOnChange(
                                    'keldanoApi.password'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiPassword')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesKeldanoApiPassword'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        serviceFormFields.keldanoApi &&
                                        invalidInputFields.keldanoApiEndpoint &&
                                        validationSet.hasOwnProperty(
                                            'keldanoApiEndpoint'
                                        )
                                })}
                                value={
                                    serviceFormFields['keldanoApi.apiEndpoint']
                                }
                                onChange={this.handleOnChange(
                                    'keldanoApi.apiEndpoint'
                                )}
                                onBlur={this.handleOnBlur('keldanoApiEndpoint')}
                            />
                            <label>
                                {getTranslatedString(
                                    this.props.language,
                                    'servicesKeldanoApiEndpoint'
                                ) + (serviceFormFields.keldanoApi ? ' *' : '')}
                            </label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderWebinarSettingsPanel() {
        const { serviceFormFields } = this.props;
        const required = serviceFormFields.webinar;

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesWebinarSettings'
                )}
                key={85}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields['webinar.maxAdvisers']
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                max={5}
                                onChange={this.handleOnChange(
                                    'webinar.maxAdvisers'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebinarAdvisers'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        serviceFormFields[
                                            'webinar.maxParticipants'
                                        ]
                                    ) || 0
                                }
                                step={1}
                                min={0}
                                max={100}
                                onChange={this.handleOnChange(
                                    'webinar.maxParticipants'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesWebinarParticipants'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'webinar.disableVideoForParticipants'
                                ]
                            }
                            checked={
                                serviceFormFields[
                                    'webinar.disableVideoForParticipants'
                                ]
                            }
                            onChange={this.handleOnChange(
                                'webinar.disableVideoForParticipants'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString
                                id={
                                    'servicesWebinarDisableVideoForParticipants'
                                }
                            />
                        </label>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderPhoneConsultingSettingsPanel() {
        const { serviceFormFields } = this.props;
        const required = serviceFormFields.phoneConsulting;

        return !required ? null : (
            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={getTranslatedString(
                    this.props.language,
                    'servicesPhoneConsultingSettings'
                )}
                key={86}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <Checkbox
                            value={
                                !serviceFormFields[
                                    'phoneConsulting.enableAdHoc'
                                ]
                            }
                            checked={
                                serviceFormFields['phoneConsulting.enableAdHoc']
                            }
                            onChange={this.handleOnChange(
                                'phoneConsulting.enableAdHoc'
                            )}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesEnableAdHoc'} />
                        </label>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderOptionsPanel() {
        const serviceFormFields = this.props.serviceFormFields;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesOptions'
                )}
                key={90}
            >
                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.experimentalMode}
                        checked={serviceFormFields.experimentalMode}
                        onChange={this.handleOnChange('experimentalMode')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesExperimentalMode'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.meetings}
                        checked={serviceFormFields.meetings}
                        onChange={this.handleOnChange('meetings')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesMeetings'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.oneToOne}
                        checked={serviceFormFields.oneToOne}
                        onChange={this.handleOnChange('oneToOne')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesOneToOne'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.videoIdent}
                        checked={serviceFormFields.videoIdent}
                        onChange={this.handleOnChange('videoIdent')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesVideoIdent'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.branch}
                        checked={serviceFormFields.branch}
                        onChange={this.handleOnChange('branch')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesBranch'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.keldanoApi}
                        checked={serviceFormFields.keldanoApi}
                        onChange={this.handleOnChange('keldanoApi')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesKeldanoApi'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.webinar}
                        checked={serviceFormFields.webinar}
                        onChange={this.handleOnChange('webinar')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesWebinar'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.phoneConsulting}
                        checked={serviceFormFields.phoneConsulting}
                        onChange={this.handleOnChange('phoneConsulting')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesPhoneConsulting'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.hideAdviserList}
                        checked={serviceFormFields.hideAdviserList}
                        onChange={this.handleOnChange('hideAdviserList')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesHideAdviserList'} />
                    </label>
                </div>
            </AccordionTab>
        );
    }

    renderNepatecSettingsPanel() {
        const { serviceFormFields } = this.props;
        const required = serviceFormFields.keldanoApi;

        return !required ? null : (
            // TODO: translated strings
            // TODO: validation?

            <AccordionTab
                headerClassName={classNames({ 'hidden-panel': !required })}
                header={'Nepatec Settings'}
                key={120}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <Checkbox
                            value={!serviceFormFields['nepatecSettings.active']}
                            checked={
                                serviceFormFields['nepatecSettings.active']
                            }
                            onChange={this.handleOnChange(
                                'nepatecSettings.active'
                            )}
                        />
                        <label className="p-checkbox-label">{'Active'}</label>
                    </div>
                    {serviceFormFields['nepatecSettings.active'] && (
                        <React.Fragment>
                            <div className="p-col-12">
                                <Checkbox
                                    value={
                                        !serviceFormFields[
                                            'nepatecSettings.signatureTemplates'
                                        ]
                                    }
                                    checked={
                                        serviceFormFields[
                                            'nepatecSettings.signatureTemplates'
                                        ]
                                    }
                                    onChange={this.handleOnChange(
                                        'nepatecSettings.signatureTemplates'
                                    )}
                                />
                                <label className="p-checkbox-label">
                                    {'Signature Templates'}
                                </label>
                            </div>
                            <div className="p-col-12">
                                <Checkbox
                                    value={
                                        !serviceFormFields[
                                            'nepatecSettings.useSSL'
                                        ]
                                    }
                                    checked={
                                        serviceFormFields[
                                            'nepatecSettings.useSSL'
                                        ]
                                    }
                                    onChange={this.handleOnChange(
                                        'nepatecSettings.useSSL'
                                    )}
                                />
                                <label className="p-checkbox-label">
                                    {'Use SSL'}
                                </label>
                            </div>
                            <div className="p-col-12">
                                <Checkbox
                                    value={
                                        !serviceFormFields[
                                            'nepatecSettings.individualLogin'
                                        ]
                                    }
                                    checked={
                                        serviceFormFields[
                                            'nepatecSettings.individualLogin'
                                        ]
                                    }
                                    onChange={this.handleOnChange(
                                        'nepatecSettings.individualLogin'
                                    )}
                                />
                                <label className="p-checkbox-label">
                                    {'Individual Login'}
                                </label>
                            </div>
                            <div className="p-col-12">
                                <span className="md-inputfield">
                                    <InputText
                                        className="form-input"
                                        value={
                                            serviceFormFields[
                                                'nepatecSettings.url'
                                            ]
                                        }
                                        onChange={this.handleOnChange(
                                            'nepatecSettings.url'
                                        )}
                                    />
                                    <label>{'URL'}</label>
                                </span>
                            </div>
                            {!serviceFormFields[
                                'nepatecSettings.individualLogin'
                            ] && (
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <span className="md-inputfield">
                                            <InputText
                                                className="form-input"
                                                value={
                                                    serviceFormFields[
                                                        'nepatecSettings.controllerName'
                                                    ]
                                                }
                                                onChange={this.handleOnChange(
                                                    'nepatecSettings.controllerName'
                                                )}
                                            />
                                            <label>{'Controller Name'}</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12">
                                        <span className="md-inputfield">
                                            <InputText
                                                className="form-input"
                                                type="password"
                                                value={
                                                    serviceFormFields[
                                                        'nepatecSettings.controllerPassword'
                                                    ]
                                                }
                                                onChange={this.handleOnChange(
                                                    'nepatecSettings.controllerPassword'
                                                )}
                                            />
                                            <label>
                                                {'Controller Password'}
                                            </label>
                                        </span>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </AccordionTab>
        );
    }

    renderLoggingPanel() {
        const serviceFormFields = this.props.serviceFormFields;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesLogging'
                )}
                key={100}
            >
                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.logSessions}
                        checked={serviceFormFields.logSessions}
                        onChange={this.handleOnChange('logSessions')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesSessionLog'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.logChat}
                        checked={serviceFormFields.logChat}
                        onChange={this.handleOnChange('logChat')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesChatLog'} />
                    </label>
                </div>

                <div className="p-col-12">
                    <Checkbox
                        value={!serviceFormFields.adviserTracking}
                        checked={serviceFormFields.adviserTracking}
                        onChange={this.handleOnChange('adviserTracking')}
                    />
                    <label className="p-checkbox-label">
                        <TranslatedString id={'servicesAdviserTracking'} />
                    </label>
                </div>
            </AccordionTab>
        );
    }

    renderAdviserUserCustomFieldsPanel() {
        const serviceFormFields = this.props.serviceFormFields;
        const customFieldIds = (
            serviceFormFields.adviserUserCustomFields || []
        ).map(customField => customField._id);
        const invalidCustomFieldIds = customFieldIds.map(
            customFieldId =>
                customFieldId === '' ||
                customFieldId === 'username' ||
                customFieldId === 'name' ||
                customFieldId === 'id' ||
                customFieldId === 'password' ||
                customFieldId === 'passwordConfirmation'
        );
        const adviserUserCustomFieldsPanel = (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'servicesAdviserUserCustomFields'
                )}
                key={110}
            >
                <UserCustomFields
                    isLoading={this.props.isLoading}
                    serviceFormFields={serviceFormFields}
                    type={'adviserUserCustomFields'}
                    userCustomFields={serviceFormFields.adviserUserCustomFields}
                    error={invalidCustomFieldIds}
                    onEditForm={this.props.onEditForm}
                />
            </AccordionTab>
        );

        return {
            invalidCustomFieldIds: invalidCustomFieldIds,
            adviserUserCustomFieldsPanel: adviserUserCustomFieldsPanel
        };
    }

    render() {
        const {
            serviceFormFields,
            isEditing,
            isLoading,
            user,
            language
        } = this.props;
        let invalidInputFields, invalidInputs, settingsPanels;

        if (isEditing) {
            if (
                user &&
                Array.isArray(user.roles) &&
                user.roles.includes('restricted-admin')
            ) {
                ({
                    invalidInputFields,
                    invalidInputs
                } = this.validateFormFields(serviceFormFields));
                settingsPanels = this.renderClientSettingsPanel(
                    invalidInputFields
                );
            } else {
                const {
                    invalidCustomFieldIds,
                    adviserUserCustomFieldsPanel
                } = this.renderAdviserUserCustomFieldsPanel();

                ({
                    invalidInputFields,
                    invalidInputs
                } = this.validateFormFields(
                    serviceFormFields,
                    invalidCustomFieldIds
                ));
                settingsPanels = [
                    this.renderGeneralSettingsPanel(invalidInputFields),
                    this.renderOptionsPanel(),
                    this.renderWebRtcSettingsPanel(invalidInputFields),
                    this.renderMeetingsSettingsPanel(invalidInputFields),
                    this.renderWebinarSettingsPanel(),
                    this.renderPhoneConsultingSettingsPanel(),
                    this.renderMailTemplateSettingsPanel(invalidInputFields),
                    this.renderMailSettingsPanel(invalidInputFields),
                    this.renderLocalesOverwrites(),
                    this.renderApiSettingsPanel(invalidInputFields),
                    this.renderKeycloakSettingsPanel(invalidInputFields),
                    this.renderNepatecSettingsPanel(),
                    this.renderLoggingPanel(),
                    adviserUserCustomFieldsPanel
                ];
            }
        } else {
            ({ invalidInputFields, invalidInputs } = this.validateFormFields(
                serviceFormFields
            ));
            settingsPanels = this.renderClientSettingsPanel(invalidInputFields);
        }

        return (
            <Card
                title={getTranslatedString(
                    language,
                    isEditing ? 'servicesEdit' : 'servicesAdd'
                )}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <Accordion className="accordion" multiple={true}>
                            {settingsPanels}
                        </Accordion>
                    </div>

                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={isLoading}
                            isEditing={isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

class LocalesOverwrites extends Component {
    handleOnChange = language => value => {
        this.props.onEdit(this.props.serviceFormFields, {
            field: 'meetings.localesOverwrites',
            value: { ...this.props.localesOverwrites, [language]: value }
        });
    };

    renderLocaleOverwrites(language) {
        return (
            <AccordionTab
                header={getTranslatedString(this.props.language, language)}
            >
                <LocaleOverwrites
                    overwrites={this.props.localesOverwrites[language]}
                    error={this.props.error[language]}
                    onEdit={this.handleOnChange(language)}
                />
            </AccordionTab>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Accordion className="accordion" multiple={true}>
                    {this.renderLocaleOverwrites('de')}
                    {this.renderLocaleOverwrites('en')}
                    {this.renderLocaleOverwrites('fr')}
                    {this.renderLocaleOverwrites('it')}
                </Accordion>
            </React.Fragment>
        );
    }
}

class LocaleOverwrites extends Component {
    constructor(props) {
        super(props);

        let validationSet = this.props.additionalMetadata
            ? new Array(this.props.additionalMetadata.length).fill(false)
            : [];

        this.state = {
            validationSet: validationSet
        };

        this.defaultOverwriteField = {
            key: '',
            value: ''
        };
    }

    /**
     *
     * @param fieldId
     * @param index
     * @returns {Function}
     */
    handleOnChange = (fieldId, index) => e => {
        const overwriteFields = this.props.overwrites.map(field => {
            return field.slice(0);
        });

        overwriteFields[index][fieldId === 'key' ? 0 : 1] = e.target.value;
        this.props.onEdit(overwriteFields);
    };

    /**
     *
     * @param index
     * @returns {Function}
     */
    handleOnBlur = index => () => {
        let validationSet = this.state.validationSet.slice(0);
        validationSet[index] = true;
        this.setState({ validationSet: validationSet });
    };

    /**
     *
     * @returns {Function}
     */
    handleAddClick = () => () => {
        const overwriteFields = [
            ...(this.props.overwrites || []).slice(0),
            ['', '']
        ];

        this.props.onEdit(overwriteFields);

        let validationSet = this.state.validationSet.slice(0);
        validationSet.push(false);
        this.setState({ validationSet: validationSet });
    };

    /**
     *
     * @param index - index of the additionalMetadata property
     * @returns {Function}
     */
    handleRemoveClick = index => () => {
        const overwriteFields = [
            ...this.props.overwrites.slice(0, index),
            ...this.props.overwrites.slice(index + 1)
        ];

        this.props.onEdit(overwriteFields);

        let validationSet = this.state.validationSet.slice(0);
        validationSet.splice(index, 1);
        this.setState({ validationSet: validationSet });
    };

    renderOverwriteField(overwriteField, index) {
        return (
            <div className="edit-metadata-input-group" key={index}>
                <div className="p-grid edit-metadata-inputs">
                    <div className="p-col-6 metadata-input">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        this.state.validationSet[index] &&
                                        this.props.error &&
                                        this.props.error[index]
                                })}
                                value={
                                    overwriteField[0] ||
                                    this.defaultOverwriteField.key
                                }
                                onChange={this.handleOnChange('key', index)}
                                onBlur={this.handleOnBlur(index)}
                            />
                            <label>
                                <TranslatedString
                                    id={'meetingAdditionalMetadataLabel'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-6 metadata-input">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    overwriteField[1] ||
                                    this.defaultOverwriteField.value
                                }
                                onChange={this.handleOnChange('value', index)}
                            />
                            <label>
                                <TranslatedString
                                    id={'meetingAdditionalMetadataValue'}
                                />
                            </label>
                        </span>
                    </div>
                </div>

                <Button
                    className="delete-metadata-button"
                    icon="pi-md-delete"
                    disabled={this.props.isLoading}
                    onClick={this.handleRemoveClick(index)}
                />
            </div>
        );
    }

    renderAddOverwriteButton() {
        return (
            <div className="add-metadata-button-wrapper">
                <Button
                    icon="pi-md-add-circle-outline"
                    disabled={this.props.isLoading}
                    onClick={this.handleAddClick()}
                />
            </div>
        );
    }

    render() {
        const overwritesFields = (this.props.overwrites || []).map(
            (overwriteField, index) => {
                return this.renderOverwriteField(overwriteField, index);
            }
        );
        const addOverwriteButton = this.renderAddOverwriteButton();

        return (
            <div className="p-col-12 edit-metadata-input-wrapper">
                {overwritesFields.length > 0 && <br />}
                {overwritesFields}
                {addOverwriteButton}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.services.isLoading,
        isEditing: state.services.isEditing,
        serviceToAdd: state.services.serviceToAdd,
        addValidationSet: state.services.addValidationSet,
        serviceToEdit: state.services.serviceToEdit,
        editValidationSet: state.services.editValidationSet,
        services: state.services.services,
        contentRange: state.services.contentRange,

        user: state.auth.user,

        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    updateRequestParameters,
    requestServices,
    addService,
    updateService,
    deleteService,
    startEditingService,
    stopEditingService,
    editService,
    addServiceValidation,
    resetServicesPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
