import Keycloak from 'keycloak-js';
import jwtDecode from 'jwt-decode';
import {
    readFromLocalStorage,
    readFromSessionStorage,
    removeFromSessionStorage,
    log
} from './helpers';
import {isAdminUser, isAdminRoute} from './accessControl';
import {
    AUTH_MAX_INACTIVITY_TIME,
    AUTH_MIN_TOKEN_VALIDITY,
    AUTH_REFRESH_TOKEN_INTERVAL
} from '../../../constants/constants';

//private fields
let kc;
let authMethod;
let keycloakSettings;

let refreshHandler;
let logoutHandler;

let inactivityTimer;
let refreshTokenInterval;

//private methods:
/**
 * login using keycloak
 * @param serviceId
 * @param loginSuccess - callback if login with keycloak succeeds
 * @param loginFailure - callback if login with keycloak fails
 */
function keycloakLogin(serviceId, loginSuccess, loginFailure) {
    kc.init({
        onLoad: 'login-required',
        promiseType: 'native',
        /*checkLoginIframe: false*/
    })
        .then(authenticated => {
            if (authenticated) {
                const user = {
                    _id: kc.subject,
                    roles: kc.tokenParsed.realm_access.roles,
                    username: kc.tokenParsed.preferred_username,
                    name: kc.tokenParsed.family_name,
                    firstName: kc.tokenParsed.given_name,
                    email: kc.tokenParsed.email,
                    locale: kc.tokenParsed.locale
                };
                loginSuccess(user, serviceId);

            } else {
                loginFailure();
            }
        })
        .catch(() => {
            loginFailure();
        });
}

class AuthHelper {
    /**
     * login
     * @param loginType - admin/service
     * @param serviceId
     * @param method - authMethod: keycloak/default
     * @param settings - keycloakSettings
     * @param onLogin - code to execute for login - defined in authMiddleware.js
     * @param onLogout - code to execute for logout - defined in authMiddleware.js
     * @param onRefresh - code to execute on tokenRefresh -defined in authMiddleware.js
     */
    login(
        loginType,
        serviceId,
        method,
        settings,
        onLogin,
        onLogout,
        onRefresh
    ) {
        authMethod = method;
        keycloakSettings = settings;

        if (authMethod === 'keycloak') {
            kc = new Keycloak(keycloakSettings);

            // set logoutHandler for future keycloak logout
            logoutHandler = onLogout.keycloakLogout(kc);

            // login with keycloak
            keycloakLogin.call(
                this,
                serviceId,
                onLogin.keycloakLogin.success,
                onLogin.keycloakLogin.failure
            );
        } else {
            // set refreshHandler for token refreshing
            refreshHandler = onRefresh;

            if (loginType === 'admin') {
                // set logoutHandler for future admin logout
                logoutHandler = onLogout.defaultLogout.admin;
                //login as admin
                onLogin.defaultLogin.admin();
            } else {
                // set logoutHandler for future service logout
                logoutHandler = onLogout.defaultLogout.service;
                //login as service
                onLogin.defaultLogin.service();
            }
        }

        // initialize inactivity timer
        //this.resetInactivityTimer();
    }

    /**
     * logout with logout handler
     */
    logout() {
        //clearTimeout(inactivityTimer);
        clearInterval(refreshTokenInterval);
        refreshTokenInterval = null;

        removeFromSessionStorage('token');
        removeFromSessionStorage('user');
        removeFromSessionStorage('selectedService'); // admin only

        logoutHandler();
    }

    /**
     *
     * @param onReLogin - code to execute for re-login
     * @param onLogout - logout handler
     * @param onRefresh - refresh handler
     */
    reLogin(onReLogin, onLogout, onRefresh) {
        // set refreshHandler and logoutHandler
        refreshHandler = onRefresh;
        logoutHandler = onLogout;

        // re-login
        onReLogin();

        // initialize inactivity timer
        //this.resetInactivityTimer();
    }

    /**
     * check if re-login is possible
     * @param onReLoginCheck - callback if re-login is possible
     * @param path
     */
    reLoginPossible(onReLoginCheck, path) {
        // re-login is possible if there is a token and a user in the session storage;
        // additionally for a non-admin login the serviceId has to be in the local storage;
        // if the value of the token is 'keycloak' (set during a keycloak login), then proceed with a keycloak re-login

        let reLoginPossible = false;
        let keycloak = false;
        let user;
        let serviceId;

        const token = this.getToken(true);
        if (token) {
            user = this.getUser();
            if (user) {
                if (isAdminUser(user) && isAdminRoute(path)) {
                    reLoginPossible = true;

                } else {
                    serviceId = readFromLocalStorage('serviceId');
                    if (serviceId && path.startsWith(`/${serviceId}`)) {
                        reLoginPossible = true;
                        keycloak = token === 'keycloak';
                    }
                }
            }
        }

        if (reLoginPossible) {
            onReLoginCheck.possible(user, serviceId, keycloak);
        } else {
            onReLoginCheck.notPossible();
        }
    }

    /**
     * get keycloak/default token
     * @param minimal - if minimal is true only return token without resettingInactivityTimer or refreshToken
     * @returns {*|string}
     */
    getToken(minimal = false) {
        if (!minimal) {
            //this.resetInactivityTimer();
            this.refreshToken();
        }

        let token;
        if (authMethod === 'keycloak') {
            token = kc.token;
        } else {
            token = readFromSessionStorage('token');
        }

        return token || '';
    }

    /**
     * get logged in user
     * @returns {{roles: string[], username: *}}
     */
    getUser() {
        let user;
        if (authMethod === 'keycloak') {
            user = {
                _id: kc.subject,
                roles: kc.tokenParsed.realm_access.roles,
                username: kc.tokenParsed.preferred_username,
                locale: kc.tokenParsed.locale
            };
        } else {
            user = readFromSessionStorage('user');
            if (user) {
                try {
                    user = JSON.parse(user);
                } catch (e) {
                    user = undefined;
                }
            } else {
                user = undefined;
            }
        }
        return user;
    }

    /**
     * reset inactivity time to AUTH_MAX_INACTIVITY_TIME
     */
    resetInactivityTimer() {
        if (inactivityTimer) {
            clearTimeout(inactivityTimer);
            inactivityTimer = null;
        }

        inactivityTimer = setTimeout(() => {
            this.logout();
        }, AUTH_MAX_INACTIVITY_TIME * 1000);
    }

    /**
     * refresh the token if the remaining validity time is less than AUTH_MIN_TOKEN_VALIDITY
     */
    refreshToken() {
        if (authMethod === 'keycloak') {
            kc.updateToken(AUTH_MIN_TOKEN_VALIDITY)
                .catch(err => {
                    log.error(`Promise returned error in authHelper.refreshToken using keycloak: ${err}`);
                    this.logout();
                });
        } else {
            const token = this.getToken(true);
            if (token) {
                let tokenParsed;
                try {
                    tokenParsed = jwtDecode(token);
                } catch (e) {
                    //something with the token is wrong
                    log.error(`Could not decode token: ${e}`);
                    return;
                }
                if (tokenParsed) {
                    const now = Math.floor(Date.now() / 1000);
                    if (tokenParsed.exp >= now) {
                        //token is still valid
                        if (
                            tokenParsed.exp - AUTH_MIN_TOKEN_VALIDITY - now <=
                            0
                        ) {
                            //token is valid for less than 'AUTH_MIN_TOKEN_VALIDITY' seconds
                            refreshHandler(token);
                        }
                    }
                }
            }
        }
    }

    /**
     * initialize refreshTokenInterval to keep user logged in
     */
    startTokenRefreshInterval() {
        if (refreshTokenInterval) {
            clearInterval(refreshTokenInterval);
            refreshTokenInterval = null;
        }

        refreshTokenInterval = setInterval(() => {
            log.info('[refreshTokenInterval] try token refresh');
            this.refreshToken();
        }, AUTH_REFRESH_TOKEN_INTERVAL * 1000);
    }
}

export default new AuthHelper();
