export const english = {
    language: 'Language',

    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian',

    // ServiceInputPage
    next: 'Next',

    // LoginPage
    applicationName: 'adiaLive Manager',
    serviceInputFormHeader: 'Service',
    loginFormHeader: 'Login',
    username: 'Username',
    password: 'Password',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Sign In',

    edit: 'Edit',
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
    download: 'Download',

    excelExportHeader: 'Excel Export',
    excelExportLoadingMessage:
        'One moment please, your download is being prepared.',
    excelExportDownloadMessage: 'Done. You can now start the download.',
    excelExportErrorMessage: 'Oops! An error occurred.',

    // ADMINISTRATION
    administration: 'ADMINISTRATION',

    // Users
    users: 'Users',
    usersSearch: 'Search for users',

    userTableId: 'User ID',
    userTableAdditionalId: 'Additional ID',
    userTableName: 'Name',
    userTableUsername: 'Username',
    userTableRoles: 'Roles',

    userAdd: 'Add user',
    userEdit: 'Edit user',
    userDeleteHeader: 'Delete user?',
    userDeleteDialog: 'Are you sure you want to delete this user?',

    userGeneralSettings: 'IS THIS STILL USED?',
    userId: 'User ID',
    userUsername: 'Username *',
    userName: 'Name *',
    userRoles: 'Roles *',
    userAdditionalId: 'Additional ID',
    userPassword: 'Password *',
    userConfirmPassword: 'Confirm password *',
    userNewPassword: 'New password',
    userConfirmNewPassword: 'Confirm new password',
    userIdTooltip: 'Optional, e.g. email address or unique employee id',

    // Meetings
    meetings: 'Meetings',

    meetingTableDate: 'Date',
    meetingTableTitle: 'Title',
    meetingTableDescription: 'Description',
    meetingTableParticipants: 'Participants',
    meetingTableState: 'State',

    meetingAdd: 'Create new meeting',
    meetingEdit: 'Edit meeting',
    meetingDeleteHeader: 'Delete meeting',
    meetingDeleteDialog: 'Are you sure you want to delete this meeting?',

    meetingGeneral: 'General Settings',
    meetingDate: 'Date *',
    meetingTitle: 'Title *',
    meetingDescription: 'Description',
    meetingDuration: 'Duration (in minutes)',
    meetingLink: 'Link',
    meetingType: 'Type',
    meetingState: 'State',
    meetingStateOpen: 'open',
    meetingStateOngoing: 'ongoing',
    meetingStateCompleted: 'completed',
    meetingStateArchived: 'archived',
    meetingStateDeleted: 'deleted',

    meetingAdditionalMetadata: 'Additional Metadata',
    meetingAdditionalMetadataLabel: 'Key',
    meetingAdditionalMetadataValue: 'Value',

    meetingParticipants: 'Participants',
    meetingNewParticipant: 'New Participant',
    meetingParticipantName: 'Name *',
    meetingParticipantEmail: 'Email',
    meetingParticipantAdviserId: 'Adviser ID',
    meetingParticipantLink: 'Link',
    meetingParticipantPublic: 'Public',

    //ApiKeys
    apiKeys: 'API Keys',

    apiKeyTableTitle: 'Title',
    apiKeyTableRoles: 'Roles',
    apiKeyTableIp: 'IP',
    apiKeyTableActive: 'Active',

    apiKeyAdd: 'Create new API key',
    apiKeyEdit: 'Edit API key',
    apiKeyDeleteHeader: 'Delete API key',
    apiKeyDeleteDialog: 'Are you sure you want to delete this API key?',

    apiKeyTitle: 'Title *',
    apiKeyRoles: 'Roles *',
    apiKeyToken: 'Token',
    apiKeyIp: 'IP',
    apiKeyActive: 'Active',

    // ANALYTICS
    analytics: 'ANALYTICS',

    // Sessions
    sessions: 'Sessions',

    sessionFilter: 'Filters',
    sessionFilterHasRecordings: 'Recorded',

    sessionExportSelectDateMessage:
        'Please choose the desired date range for your export:',
    sessionExportSelectUntil: 'to',
    sessionExportSelectMaxDataMessage:
        'Note that a maximum of 20000 records can be exported at once.',
    sessionExportType: 'Export Type:',

    sessionDownloadRecordings: 'Download Recordings',
    sessionDownloadAudioRecordings: 'Download Audio Recordings',
    sessionPrepareRecordingsMessage: 'Preparing recordings for download.',
    sessionDownloadMessage: 'The recordings are ready to download.',
    sessionDownloadOngoingMessage:
        'The recordings are being downloaded. This may take some time.',
    sessionBackgroundDownload: 'Download in background',
    sessionDownloadNoRecordingsFound:
        'There exist no recordings for this session.',
    sessionDownloadNoDownloadAvailable:
        'There is no download for these recordings available yet.',
    sessionDownloadErrorMessage:
        'There was an error while preparing the recordings for download.',

    sessionTableDate: 'Date',
    sessionTableStartTime: 'Session Start',
    sessionTableDuration: 'Duration',
    sessionTableAdviser: 'Adviser',
    sessionTableParticipants: 'Participants',
    sessionTableRecordings: 'Recordings',

    sessionInfo: 'Session Info',
    sessionInfoMessage:
        'Choose a session in order to display additional information.',

    sessionMeetingInfo: 'Meeting Info',
    sessionMeetingInfoDate: 'Date',
    sessionMeetingInfoTitle: 'Title',
    sessionMeetingInfoDescription: 'Description',
    sessionMeetingInfoDuration: 'Meeting Duration',

    sessionParticipantInfo: 'Participant Info',
    sessionParticipantInfoName: 'Name',
    sessionParticipantInfoEmail: 'Email',
    sessionParticipantInfoIsAdviser: 'Adviser',

    sessionLog: 'Session Log',
    sessionInfoLogUnavailableMessage:
        'There is no log available for the selected session.',

    // ADMIN
    sysAdmin: 'SYSTEM ADMIN',

    // Admin Users
    adminUsers: 'Admin Users',
    adminUser: 'Admin User',

    adminUserTableId: 'ID',
    adminUserTableName: 'Name',
    adminUserTableUsername: 'Username',

    adminUserAdd: 'Add admin user',
    adminUserEdit: 'Edit admin user',
    adminUserDeleteHeader: 'Delete admin user?',
    adminUserDeleteDialog: 'Are you sure you want to delete this admin user?',

    adminUserUsername: 'Username *',
    adminUserName: 'Name *',
    adminUserId: 'ID',

    // Services
    services: 'Services',
    servicesSearch: 'Search for services',

    servicesTableId: 'ID',
    servicesTableName: 'Name',
    servicesTableLogo: 'Logo',
    servicesTableActive: 'Active',
    servicesTableSessionLog: 'Session log',
    servicesTableChatLog: 'Chat log',

    servicesAdd: 'Add service',
    servicesEdit: 'Edit service',
    servicesDeleteHeader: 'Delete service?',
    servicesDeleteDialog: 'Are you sure you want to delete this service?',

    servicesClientSettings: 'Client Settings',

    servicesGeneral: 'General Settings',
    servicesId: 'ID *',
    servicesName: 'Name *',
    servicesLogo: 'Logo',
    servicesAuthMethod: 'Authentication Method',
    servicesPublicServiceFields: 'Public Service Fields',
    servicesOriginRestrictions: 'Origin Restrictions',
    servicesActive: 'Active',
    servicesServiceEmailAddress: 'Contact Email Address',

    servicesMeetingsSettings: 'Meetings Settings',
    servicesSharedApplicationsUrl: 'Shared Applications Url',
    servicesMeetingsManagerUrl: 'Meetings Manager URL',
    servicesCommunicatorUrl: 'Communicator URL',
    servicesAdviserUrl: 'Adviser URL',
    servicesParticipantLimit: 'Participant Limit',
    servicesEnableAdHoc: 'Enable Ad hoc',
    servicesEnableRecording: 'Enable Recording',
    servicesEnableRecordingConfirmation: 'Enable Recording Confirmation',
    servicesEnableAutoRecording: 'Enable Auto-Recording',
    servicesDisableCommunicatorFileTransfer:
        'File Transfer disabled in Communicator',
    servicesEnableCommunicatorContactForm: 'Enable Communicator Contact Form',

    servicesDisableFileAttachmentsForParticipants:
        'Disable File Attachments for Participants',
    servicesDisableFileDownloadsForParticipants:
        'Disable File Downloads for Participants',
    servicesMaxTotalAttachmentsSize: 'Maximum Total Attachment Size',
    servicesFileMaxSize: 'Maximum File Size',
    servicesAcceptDocumentsTypesModerators:
        'Accepted Document Types (Moderators)',
    servicesAcceptDocumentsTypesAttendees:
        'Accepted Document Types (Attendees)',
    servicesAllowedMimes: 'Allowed Mime Types',
    servicesEnableNepatec: 'Enable Nepatec',
    servicesShowInsign: 'Enable Insign',
    servicesMeetingsLimit: 'Meetings Limit',
    servicesAddCurrentUserToNewMeeting: 'Add current User to new Meeting',
    servicesAddAdditionalModerators: 'Add additional Moderators',
    servicesKeldanoFeDomain: 'Keldano Fe Domain',
    servicesKeldanoApiDomain: 'Keldano Api Domain',
    servicesAttachAgenda: 'Attach Agenda',
    servicesAttachProtocol: 'Attach Protocol',
    servicesAttendeesSendPostProcessingMailEnabled:
        'Send Post-Processing Email Enabled (Attendees)',
    servicesHideToolbar: 'Hide Toolbar',
    servicesPreselectInvitationMailModerators:
        'Preselect Invitation Email (Moderators)',
    servicesPreselectInvitationMailAttendees:
        'Preselect Invitation Email (Attendees)',
    servicesPreselectPostProcessingMailModerators:
        'Preselect Post-Processing Email (Moderators)',
    servicesPreselectPostProcessingMailAttendees:
        'Preselect Post-Processing Email (Attendees)',

    servicesMailTemplateSettings: 'Mail Template Settings',
    servicesMailTemplateLogo: 'Mail Logo *',
    servicesMailTemplateCompany: 'Mail Company',
    servicesMailTemplateAddress: 'Mail Address',
    servicesMailTemplatePhone: 'Mail Phone',
    servicesMailTemplateWebPage: 'Mail Webpage',
    servicesMailTemplateFaxNumber: 'Mail Fax Number',

    servicesMailSettings: 'Mail Settings',
    servicesMailCredentialsUsername: 'Username',
    servicesMailCredentialsPassword: 'Password',
    servicesMailFromAddress: 'From Address',
    servicesMailFromName: 'From Name',
    servicesMailReplyToAddress: 'Reply To Address',
    servicesMailReplyToName: 'Reply To Name',
    servicesMailReturnPathAddress: 'Return Path Address',
    servicesMailReturnPathName: 'Return Path Name',
    servicesMailSmtpServer: 'SMTP Server',
    servicesMailSmtpPort: 'SMTP Port',
    servicesMailMethod: 'Method',
    servicesKeycloakDomain: 'Keycloak Domain',

    servicesLocalesOverwrites: 'Locales Overwrites',

    servicesWebRtcSettings: 'WebRTC Settings',
    servicesWebRtcMode: 'Mode',
    servicesWebRtcUrl: 'URL *',
    servicesWebRtcSecret: 'Secret *',
    servicesWebRtcApplicationId: 'Application ID *',
    servicesWebRtcApiKey: 'Api Key *',
    servicesWebRtcMaxAudioBitrate: 'Audio Input Max Bitrate (kbps)',
    servicesWebRtcMaxVideoBitrate: 'Video Input Max Bitrate (kbps)',
    servicesWebRtcUseSimulcast: 'Enable Simulcast',
    servicesWebRtcIceServerAddress: 'Turn Server',
    servicesWebRtcRelayUsername: 'Turn Username',
    servicesWebRtcRelayPassword: 'Turn Password',

    servicesKeycloakSettings: 'Keycloak Settings',
    servicesKeycloakUrl: 'URL',
    servicesKeycloakRealm: 'Realm',
    servicesKeycloakClientId: 'Client Id',
    servicesKeycloakKid: 'KID',
    servicesKeycloakPublicKey: 'Public Key',

    servicesKeldanoApiSettings: 'API Settings',
    servicesKeldanoApiUrl: 'URL *',
    servicesKeldanoApiClientId: 'Client Id *',
    servicesKeldanoApiClientSecret: 'Client Secret *',
    servicesKeldanoApiUsername: 'Username *',
    servicesKeldanoApiPassword: 'Password *',
    servicesKeldanoApiEndpoint: 'Keldano API Endpoint',

    servicesWebinarSettings: 'Webinar Settings',
    servicesWebinarAdvisers: 'Maximum Number of Advisers',
    servicesWebinarParticipants: 'Maximum Number of Participants',
    servicesWebinarDisableVideoForParticipants:
        'Disable Video for Participants',

    servicesPhoneConsultingSettings: 'Phone Consulting Settings',

    servicesOptions: 'Options',
    servicesExperimentalMode: 'Experimental Mode',
    servicesMeetings: 'Meetings',
    servicesOneToOne: 'One-To-One',
    servicesVideoIdent: 'Video Identification',
    servicesBranch: 'Branch',
    servicesKeldanoApi: 'Keldano API',
    servicesWebinar: 'Webinar',
    servicesHideAdviserList: 'Hide Adviserlist',
    servicesPhoneConsulting: 'Phone Consulting',

    servicesLogging: 'Logging',
    servicesSessionLog: 'Session log',
    servicesChatLog: 'Chat log',
    servicesAdviserTracking: 'Adviser tracking',

    //servicesUserCustomFields: 'Custom User Fields (Service User)',
    servicesAdviserUserCustomFields: 'Custom User Fields (User)',
    servicesNewCustomField: 'New Custom User Field',
    servicesFieldId: 'ID',
    servicesFieldType: 'Type',
    servicesFieldLabel: 'Label',
    servicesFieldDescription: 'Description',
    servicesFieldRequired: 'Required',
    servicesFieldDefault: 'Default value',
    servicesFieldPattern: 'Validation pattern',
    servicesFieldUsage: 'Usage',
    servicesFieldOptions: 'Dropdown options',
    servicesFieldOptionsLabel: 'Dropdown label',
    servicesFieldOptionsValue: 'Value',

    invalidCustomFieldId:
        'A valid ID must be set. The following may not be used: name, id, username, password, passwordConfirmation.',

    //calendar: {},
    pathDoesNotExist: "That page doesn't exist!",

    invalidUsername: "Only characters, numbers, '-', '_' and '.' allowed.",
    invalidPassword: 'The password must be at least 8 characters long.',
    invalidPasswordConfirmation: 'The passwords do not match.',

    logoutDialogHeader: 'Logout',
    logoutDialog:
        "By clicking 'Yes' you will be logged out in all adiaLive5 applications. When participating in an ongoing meeting you will be disconnected.",

    // messages
    connectionError: 'Connection to adiaLive server failed.',
    noServiceError:
        'There must exist at least one service to complete this action.',
    changeServiceError: 'Failed to change services.',
    editCurrentUserErrorMessage:
        'A currently logged in user may not be edited or deleted.',

    serverError: 'Oops! Something went wrong.',

    accessDenied: 'Access denied!',
    notAuthorized: 'Not authorized!',

    authInvalidIp: 'Invalid IP.',
    authInvalidUser: 'Invalid user.',
    authInvalidCaptcha: 'Invalid captcha.',
    authInvalidCredentials: 'Invalid credentials.',

    userRequestError: 'Failed to get user(s).',
    userAddSuccess: 'User has been added.',
    userConflict: 'A user with this username already exists.',
    userKeycloakConflict:
        'A user with this username and/or email already exists.',
    userAddError: 'Failed to add user.',
    userUpdateSuccess: 'User has been updated.',
    userNotFound: 'This user does not exist.',
    userUpdateError: 'Failed to update user.',
    userDeleteSuccess: 'User has been deleted.',
    userDeleteError: 'Failed to delete user.',
    userSyncSuccess: 'Users have been synchronized.',
    userSyncError: 'An error occurred while synchronizing users.',

    meetingRequestError: 'Failed to get meeting(s).',
    meetingAddSuccess: 'Meeting has been added.',
    meetingAddNotSupported: 'Cannot add a Keldano meeting.',
    meetingAddError: 'Failed to add meeting.',
    meetingUpdateSuccess: 'Meeting has been updated.',
    meetingNotFound: 'This meeting does not exist.',
    meetingUpdateError: 'Failed to update meeting.',
    meetingDeleteSuccess: 'Meeting has been deleted.',
    meetingDeleteNotSupported: 'Cannot delete a Keldano meeting.',
    meetingDeleteError: 'Failed to delete meeting.',

    apiKeyRequestError: 'Failed to get API key(s).',
    apiKeyAddSuccess: 'API key has been added.',
    apiKeyAddError: 'Failed to add API key.',
    apiKeyUpdateSuccess: 'API key has been updated.',
    apiKeyNotFound: 'This API key does not exist.',
    apiKeyUpdateError: 'Failed to update API key',
    apiKeyDeleteSuccess: 'API key has been deleted.',
    apiKeyDeleteError: 'Failed to delete API key.',

    sessionRequestError: 'Failed to get session(s).',
    sessionDownloadNotAllowed: 'You are not allowed to download recordings.',

    adminUserRequestError: 'Failed to get admin user(s).',
    adminUserAddSuccess: 'Admin user has been added.',
    adminUserConflict: 'An admin user with this username already exists.',
    adminUserAddError: 'Failed to add admin user.',
    adminUserUpdateSuccess: 'Admin user has been updated.',
    adminUserNotFound: 'This admin user does not exist.',
    adminUserUpdateError: 'Failed to update admin user.',
    adminUserDeleteSuccess: 'Admin user has been deleted.',
    adminUserDeleteError: 'Failed to delete admin user.',

    serviceRequestError: 'Failed to get service(s).',
    serviceAddSuccess: 'Service has been added.',
    serviceConflict: 'A service with this ID already exists.',
    serviceWebRtcError: 'Failed to set webRTC settings.',
    serviceAddError: 'Failed to add service.',
    serviceUpdateSuccess: 'Service has been updated.',
    serviceNotFound: 'This service does not exist.',
    serviceUpdateError: 'Failed to update service.',
    serviceDeleteSuccess: 'Service has been deleted.',
    serviceDeleteError: 'Failed to delete service.'

    // shorter errors
    /*
    getError: 'Failed to fetch data.',
    addError: 'Addition failed',
    updateError: 'Update failed',
    deleteError: 'Deletion failed',

    addSuccess: 'Added',
    updateSuccess: 'Updated',
    deleteSuccess: 'Deleted'
    */
};
