import { hasLanguage } from '../i18n/translations';

const Logger = require('loglevelnext/lib/LogLevel');

export const log = new Logger({
    level: 'silent',
    //level: 'debug',
    prefix: '{{time}} {{level}}'
});

/**
 * set logLevel from browser
 * @param level
 */
window.al5_setLogLevel = level => {
    try {
        log.level = level;
    } catch {
        console.error(
            `Invalid level! Available levels are: ${Object.keys(log.levels)}`
        );
    }
};

export const storeToLocalStorage = (field, data) => {
    try {
        localStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromLocalStorage = field => {
    try {
        return localStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const storeToSessionStorage = (field, data) => {
    try {
        sessionStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromSessionStorage = field => {
    try {
        return sessionStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const removeFromSessionStorage = field => {
    try {
        sessionStorage.removeItem(field);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 * replace :serviceId parameter in 'route' with 'serviceId'
 * @param route
 * @param serviceId
 * @returns {*}
 */
export const routeWithServiceId = (route, serviceId) => {
    return route.replace(':serviceId', serviceId);
};

/**
 *
 * @type {{settingsToFlags: adiaLiveSettings.settingsToFlags, Flags: {MEETINGS: number, BRANCH: number, LOG_SESSIONS: number, EXPERIMENTAL_MODE: number, LOG_CHAT: number, ADVISER_TRACKING: number, ONE_TO_ONE: number, VIDEO_IDENT: number}, flagsToSettings: adiaLiveSettings.flagsToSettings}}
 */
export const adiaLiveSettings = {
    Flags: {
        LOG_SESSIONS: 1,
        LOG_CHAT: 2,
        ADVISER_TRACKING: 4,
        EXPERIMENTAL_MODE: 8,
        MEETINGS: 16,
        ONE_TO_ONE: 32,
        VIDEO_IDENT: 64,
        BRANCH: 128,
        KELDANO_API: 256,
        WEBINAR: 512,
        HIDE_ADVISERLIST: 1024,
        PHONE_CONSULTING: 2048
    },

    settingsToFlags: function(service) {
        let flags = 0;

        if (!!service.logSessions) {
            flags += this.Flags.LOG_SESSIONS;
        }

        if (!!service.logChat) {
            flags += this.Flags.LOG_CHAT;
        }

        if (!!service.adviserTracking) {
            flags += this.Flags.ADVISER_TRACKING;
        }

        if (!!service.experimentalMode) {
            flags += this.Flags.EXPERIMENTAL_MODE;
        }

        if (!!service.meetings) {
            flags += this.Flags.MEETINGS;
        }

        if (!!service.oneToOne) {
            flags += this.Flags.ONE_TO_ONE;
        }

        if (!!service.videoIdent) {
            flags += this.Flags.VIDEO_IDENT;
        }

        if (!!service.branch) {
            flags += this.Flags.BRANCH;
        }

        if (!!service.keldanoApi) {
            flags += this.Flags.KELDANO_API;
        }

        if (!!service.webinar) {
            flags += this.Flags.WEBINAR;
        }

        if (!!service.hideAdviserList) {
            flags += this.Flags.HIDE_ADVISERLIST;
        }

        if (!!service.phoneConsulting) {
            flags += this.Flags.PHONE_CONSULTING;
        }

        delete service.logSessions;
        delete service.logChat;
        delete service.adviserTracking;
        delete service.experimentalMode;
        delete service.meetings;
        delete service.oneToOne;
        delete service.videoIdent;
        delete service.branch;
        delete service.keldanoApi;
        delete service.webinar;
        delete service.hideAdviserList;
        delete service.phoneConsulting;

        service.flags = flags;
    },

    flagsToSettings: function(service) {
        const flags = service.flags;

        service.logSessions = !!(flags & this.Flags.LOG_SESSIONS);
        service.logChat = !!(flags & this.Flags.LOG_CHAT);
        service.adviserTracking = !!(flags & this.Flags.ADVISER_TRACKING);
        service.experimentalMode = !!(flags & this.Flags.EXPERIMENTAL_MODE);
        service.meetings = !!(flags & this.Flags.MEETINGS);
        service.oneToOne = !!(flags & this.Flags.ONE_TO_ONE);
        service.videoIdent = !!(flags & this.Flags.VIDEO_IDENT);
        service.branch = !!(flags & this.Flags.BRANCH);
        service.keldanoApi = !!(flags & this.Flags.KELDANO_API);
        service.webinar = !!(flags & this.Flags.WEBINAR);
        service.hideAdviserList = !!(flags & this.Flags.HIDE_ADVISERLIST);
        service.phoneConsulting = !!(flags & this.Flags.PHONE_CONSULTING);
    }
};

/**
 * @param error - contains error message and status
 * @param onError - onError callbacks:
 *                      fatal: dispatch(forceLogout)
 *                      nonFatal: dispatch(requestFailure)
 */
export const handleError = (error, onError) => {
    log.error(error);

    if (typeof onError !== 'object' && typeof onError === 'function') {
        const callback = onError;
        onError = {
            fatal: callback,
            nonFatal: callback
        };
    }

    if (
        !error.hasOwnProperty('internalError') &&
        (!error.hasOwnProperty('errorNo') ||
            !error.hasOwnProperty('status') ||
            !error.hasOwnProperty('message'))
    ) {
        error = {
            errorNo: undefined,
            status: undefined,
            message: 'unknown error'
        };
    }

    // 401/403 responses are treated as fatal errors and will lead to a forced logout of the user
    if (error.status === 401 || error.status === 403) {
        onError.fatal(error);
    } else {
        onError.nonFatal(error);
    }
};

/**
 *
 * @param serviceUrl
 * @returns {*|string}
 */
export const serviceUrlToAPIUrl = serviceUrl => {
    let apiUrl = '';
    if (serviceUrl.indexOf(':3000') > -1) {
        apiUrl = serviceUrl.replace(':3000', ':3001');
    } else if (serviceUrl.indexOf(':5000') > -1) {
        apiUrl = serviceUrl.replace(':5000', ':5001');
    } else {
        apiUrl = serviceUrl;
    }

    return apiUrl;
};

/**
 * parse a time in ms to the format hh:mm:ss:(ms)
 * @param ms - time to parse in ms
 * @param precise - if precise is true ms are included in the output
 * @returns {string}
 */
export const parseTime = (ms, precise) => {
    let days = 0;
    while (ms >= 8.64e7) {
        // >= 24 hours
        ms -= 8.64e7;
        days++;
    }

    const limit = precise ? -1 : -5;
    let format = new Date(ms).toISOString().slice(11, limit);

    if (days) {
        format = format.split(':');
        let result = `${days * 24 + parseInt(format[0])}`;

        for (let i = 1; i < format.length; i++) {
            result += `:${format[i]}`;
        }
        return result;
    }
    return format;
};

/**
 * parse a date to an object
 * @param date - date to be parsed
 * @returns {{seconds: *, hours: *, month: *, year: *, minutes: *, day: *}}
 */
export const parseDate = date => {
    if (!(date instanceof Date)) {
        try {
            date = new Date(date);
        } catch (e) {
            return {};
        }
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const addLeadingZero = number =>
        (number < 10 ? '0' + number : number).toString();

    return {
        seconds: addLeadingZero(seconds),
        minutes: addLeadingZero(minutes),
        hours: addLeadingZero(hours),
        day: addLeadingZero(day),
        month: addLeadingZero(month),
        year: addLeadingZero(year)
    };
};

export const getPreloadLanguage = () => {
    let language;
    if (!language) {
        let userSystemLanguage = navigator.language || navigator.userLanguage;
        if (userSystemLanguage) {
            userSystemLanguage = userSystemLanguage.slice(0, 2);
            if (hasLanguage(userSystemLanguage)) {
                language = userSystemLanguage;
            }
        }
    }

    // if language is still no set, set it to English by default
    if (!language || !hasLanguage(language)) {
        language = 'en';
    }
    return language;
};

export const randomString = (length = 10, chars) => {
    let characters =
        '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';

    if (typeof chars === 'string' || Array.isArray(chars)) {
        characters = chars;
    }

    for (let i = length; i > 0; --i) {
        result += characters[Math.floor(Math.random() * characters.length)];
    }

    return result;
};
