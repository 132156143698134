let serviceUrl = '';
if (window.location.href.includes('dev-al5-manager01.adia.tv')) {
    serviceUrl = 'https://dev-al5-app01.adia.tv';
} else if (window.location.href.includes('int-al5-manager01.adia.tv')) {
    serviceUrl = 'https://int-al5-app01.adia.tv';
}

export const Config = {
    serviceUrl,
    multitenantServices: true
};
