export const AUTH_MIN_TOKEN_VALIDITY = 150; //in seconds
export const AUTH_MAX_INACTIVITY_TIME = 900; //in seconds
export const AUTH_REFRESH_TOKEN_INTERVAL = 60; //in seconds

export const ERRORS = {
    SERVER_UNREACHABLE_ERROR: {
        internalError: true,
        errorNo: 0,
        message: 'could not reach adiaLive server'
    },
    PARSING_ERROR: {
        internalError: true,
        errorNo: 1,
        message: 'could not parse response'
    }
};
