import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { getTranslatedString } from './i18n/translations';

class FormButtons extends PureComponent {
    render() {
        return (
            <React.Fragment>
                <Button
                    label={getTranslatedString(this.props.language, 'save')}
                    onClick={this.props.handleSaveClick}
                    disabled={this.props.isLoading || this.props.invalidInputs}
                />
                {this.props.isEditing && (
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'cancel'
                        )}
                        onClick={this.props.handleCancelClick}
                        disabled={this.props.isLoading}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,

        invalidInputs: ownProps.invalidInputs,
        isLoading: ownProps.isLoading,
        isEditing: ownProps.isEditing,
        handleSaveClick: ownProps.onSaveClick,
        handleCancelClick: ownProps.onCancelClick
    };
};

export default connect(
    mapStateToProps,
    undefined
)(FormButtons);
