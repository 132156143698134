export const Config = {
    serviceUrl: 'https://al5-app04.adia.tv',

    multitenantServices: true

    /*

    defaultMultitenantServiceSettings: {
        //general settings
        _id: '',
        name: '',
        logo: '',
        publicServiceFields: [],
        originRestrictions: [],
        active: true,

        //meetings settings
        'meetings.communicatorUrl':
            'https://meet.finfire.de/{{serviceId}}/?id={{id}}',
        'meetings.adviserUrl':
            'https://live-adviser.finfire.de/{{serviceId}}/meetings/{{id}}',
        'meetings.participantsLimit': 8,
        'meetings.disableCommunicatorFileTransfer': false,
        'meetings.disableFileAttachmentsForParticipants': false,
        'meetings.disableFileDownloadsForParticipants': false,
        'meetings.maxTotalAttachmentsSize': 20971520,
        'meetings.fileMaxSize': 20971520,
        'meetings.acceptDocumentsTypesModerators':
            '.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.png,.jpg,.jpeg,.gif',
        'meetings.acceptDocumentsTypesAttendees':
            '.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.png,.jpg,.jpeg,.gif',
        'meetings.allowedMimes':
            'officex, application/x-tika-msoffice, application/pdf, image/gif, application/x-tika-ooxml, image/jpeg, image/png',
        'meetings.setReturnPath': false,
        'meetings.mailFrom': null,
        'meetings.replyTo': null,
        'meetings.meetingsLimit': null,
        'meetings.addCurrentUserToNewMeeting': true,
        'meetings.addAdditionalModerators': true,
        'meetings.keldanoFeDomain': 'live-keldano.finfire.de',
        'meetings.keldanoApiDomain': 'live-keldanoapi.finfire.de',
        'meetings.attachAgenda': true,
        'meetings.attachProtocol': true,
        'meetings.attendeesSendPostProcessingMailEnabled': false,
        'meetings.hideToolbar': false,
        'meetings.preselectInvitationMailModerators': true,
        'meetings.preselectInvitationMailAttendees': true,
        'meetings.preselectPostProcessingMailModerators': true,
        'meetings.preselectPostProcessingMailAttendees': true,
        'meetings.localesOverwrites': {},
        'meetings.mailTemplate.logo': '',
        'meetings.mailTemplate.company': '',
        'meetings.mailTemplate.address': '',
        'meetings.mailTemplate.phone': '',
        'meetings.mail.credentials.username': '',
        'meetings.mail.credentials.password': '',
        'meetings.mail.fromAddress': '',
        'meetings.mail.fromName': '',
        'meetings.mail.smtpServer': '',
        'meetings.mail.smtpPort': '',
        'meetings.mail.method': 'starttls',
        'meetings.keycloak.domain': 'live-auth.finfire.de',

        //webRtc settings
        'webRtc.mode': 'liveSwitchSfu',
        'webRtc.url': 'https://al5-media01.adia.tv',
        'webRtc.secret': randomString(20),
        'webRtc.applicationId': 'al5-app04_{{serviceId}}',
        'webRtc.apiKey': 'e025f9df-1440-441d-9161-05c68e73365f',
        'webRtc.maxAudioBitrate': 0,
        'webRtc.maxVideoBitrate': 800,
        'webRtc.iceServerAddress': '',
        'webRtc.relayUsername': '',
        'webRtc.relayPassword': '',

        //keycloak settings
        'keycloak.url': '',
        'keycloak.realm': '',
        'keycloak.clientId': '',
        'keycloak.kid': '',
        'keycloak.publicKey': '',

        //keldanoApi settings
        'keldanoApi.url': '',
        'keldanoApi.clientId': '',
        'keldanoApi.clientSecret': '',
        'keldanoApi.username': '',
        'keldanoApi.password': '',
        'keldanoApi.apiEndpoint': '',

        //settings (flags)
        experimentalMode: false,
        meetings: true,
        oneToOne: false,
        videoIdent: false,
        branch: false,
        keldanoApi: true,
        hideAdviserList: false,

        //logging flags
        logSessions: true,
        logChat: true,
        adviserTracking: false,

        //adviserUserCustomFields
        adviserUserCustomFields: [
            {
                defaultValue: '',
                description: '',
                label: 'First Name',
                options: [],
                pattern: '',
                required: false,
                type: 'text',
                usage: 'both',
                _id: 'firstName'
            },
            {
                defaultValue: '',
                description: '',
                label: 'E-Mail',
                options: [],
                pattern: '',
                required: false,
                type: 'email',
                usage: 'both',
                _id: 'email'
            }
        ]
    }

     */
};
