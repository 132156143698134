import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../css/base/UserCustomFields.scss';
import classNames from 'classnames';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

import { Accordion, AccordionTab } from '../base/CustomAccordion';
import TranslatedString from './i18n/TranslatedString';
import { getTranslatedString } from './i18n/translations';

class UserCustomFields extends Component {
    constructor(props) {
        super(props);

        let validationSet = this.props.userCustomFields
            ? new Array(this.props.userCustomFields.length).fill(false)
            : [];

        this.state = {
            validationSet: validationSet
        };

        this.defaultUserCustomField = {
            _id: '',
            type: 'text',
            label: '',
            description: '',
            required: false,
            defaultValue: '',
            pattern: '',
            usage: 'token',
            options: []
        };
    }

    handleOnBlur = index => () => {
        let validationSet = this.state.validationSet.slice(0);
        validationSet[index] = true;
        this.setState({ validationSet: validationSet });
    };

    /**
     * onChange event handler for custom field properties
     * @param customField - customField object before the change
     * @param index - index of the customField object in the customFields array
     * @param fieldId - object property name of the value to be changed
     * @returns {Function}
     */
    handleOnChange = (customField, index, fieldId) => e => {
        this.handleEdit({ ...customField, [fieldId]: e.target.value }, index);
    };

    /**
     * onChange event handler for dropdown options
     * @param option - label/value object before the change
     * @param optionIndex - index of label/value object in the options array
     * @param customField - customField object before the change
     * @param index - index of the customField object in the customFields array
     * @param fieldId - object property name of the value to be changed
     * @returns {Function}
     */
    handleOnChangeOption = (
        option,
        optionIndex,
        customField,
        index,
        fieldId
    ) => e => {
        const options = [
            ...customField.options.slice(0, optionIndex),
            { ...option, [fieldId]: e.target.value },
            ...customField.options.slice(optionIndex + 1)
        ];
        this.handleEdit({ ...customField, options: options }, index);
    };

    /**
     * onClick event handler for the 'add option button'
     * @param customField - customField object before the change
     * @param index - index of the customField object in the customFields array
     * @returns {Function}
     */
    handleAddOptionClick = (customField, index) => () => {
        const options = [
            ...customField.options.slice(0),
            { label: '', value: '' }
        ];
        this.handleEdit({ ...customField, options: options }, index);
    };

    /**
     * onClick event handler for the 'remove option button'
     * @param optionIndex - index of label/value object in the options array
     * @param customField - customField object before the change
     * @param index - index of the customField object in the customFields array
     * @returns {Function}
     */
    handleRemoveOptionClick = (optionIndex, customField, index) => () => {
        const options = [
            ...customField.options.slice(0, optionIndex),
            ...customField.options.slice(optionIndex + 1)
        ];
        this.handleEdit({ ...customField, options: options }, index);
    };

    /**
     * onClick event handler for the 'add customField button'
     * @returns {Function}
     */
    handleAddClick = () => () => {
        let userCustomFields;
        if (this.props.userCustomFields) {
            userCustomFields = [...this.props.userCustomFields];
            userCustomFields.push({ ...this.defaultUserCustomField });
        } else {
            userCustomFields = [{ ...this.defaultUserCustomField }];
        }

        this.props.onEditForm(this.props.serviceFormFields, {
            field: this.props.type,
            value: userCustomFields
        });
        /*this.props.onEditForm({
            ...this.props.serviceFormFields,
            [this.props.type]: userCustomFields
        });*/

        let validationSet = this.state.validationSet.slice(0);
        validationSet.push(false);
        this.setState({ validationSet: validationSet });
    };

    /**
     * onClick event handler for the 'remove customField button'
     * @param index - index of the customField object in the customFields array
     * @returns {Function}
     */
    handleRemoveClick = index => () => {
        const userCustomFields = [
            ...this.props.userCustomFields.slice(0, index),
            ...this.props.userCustomFields.slice(index + 1)
        ];

        this.props.onEditForm(this.props.serviceFormFields, {
            field: this.props.type,
            value: userCustomFields
        });
        /*this.props.onEditForm({
            ...this.props.serviceFormFields,
            [this.props.type]: userCustomFields
        });*/

        let validationSet = this.state.validationSet.slice(0);
        validationSet.splice(index, 1);
        this.setState({ validationSet: validationSet });
    };

    /**
     *
     * @param customField - customField being edited
     * @param index - index of the customField being edited
     */
    handleEdit(customField, index) {
        const userCustomFields = [
            ...this.props.userCustomFields.slice(0, index),
            customField,
            ...this.props.userCustomFields.slice(index + 1)
        ];

        this.props.onEditForm(this.props.serviceFormFields, {
            field: this.props.type,
            value: userCustomFields
        });
        /*this.props.onEditForm({
            ...this.props.serviceFormFields,
            [this.props.type]: userCustomFields
        });*/
    }

    renderUserCustomField(customField, index) {
        const id = customField._id || this.defaultUserCustomField._id;
        const type = customField.type || this.defaultUserCustomField.type;
        const label = customField.label || this.defaultUserCustomField.label;
        const description =
            customField.description || this.defaultUserCustomField.description;
        const defaultValue =
            customField.defaultValue ||
            this.defaultUserCustomField.defaultValue;
        const pattern =
            customField.pattern || this.defaultUserCustomField.pattern;
        const usage = customField['usage'] || this.defaultUserCustomField.usage;
        const header =
            id ||
            getTranslatedString(this.props.language, 'servicesNewCustomField');
        const onChange = fieldId =>
            this.handleOnChange(customField, index, fieldId);

        return (
            <AccordionTab header={header} key={index}>
                <br />
                <div className="p-grid form-group" key={index}>
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        this.state.validationSet[index] &&
                                        this.props.error[index]
                                })}
                                value={id}
                                onChange={onChange('_id')}
                                onBlur={this.handleOnBlur(index)}
                            />
                            <label>
                                <TranslatedString id={'servicesFieldId'} />
                            </label>
                        </span>
                        {this.state.validationSet[index] &&
                            this.props.error[index] && (
                                <span className="error-message">
                                    <TranslatedString
                                        id={'invalidCustomFieldId'}
                                    />
                                </span>
                            )}
                    </div>

                    <div className="p-col-12">
                        <Dropdown
                            className="form-input"
                            value={type}
                            options={[
                                { label: 'text', value: 'text' },
                                { label: 'email', value: 'email' },
                                { label: 'date', value: 'date' },
                                { label: 'number', value: 'number' },
                                { label: 'dropdown', value: 'dropdown' }
                            ]}
                            onChange={onChange('type')}
                        />
                        <label className="dropdown-label-float">
                            <TranslatedString id={'servicesFieldType'} />
                        </label>
                    </div>

                    {customField.type === 'dropdown' &&
                        this.renderDropdownOptionsInput(customField, index)}

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={label}
                                onChange={onChange('label')}
                            />
                            <label>
                                <TranslatedString id={'servicesFieldLabel'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={description}
                                onChange={onChange('description')}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesFieldDescription'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!customField.required}
                            checked={customField.required}
                            onChange={onChange('required')}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'servicesFieldRequired'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={defaultValue}
                                onChange={onChange('defaultValue')}
                            />
                            <label>
                                <TranslatedString id={'servicesFieldDefault'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={pattern}
                                onChange={onChange('pattern')}
                            />
                            <label>
                                <TranslatedString id={'servicesFieldPattern'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Dropdown
                            className="form-input"
                            value={usage}
                            options={[
                                { label: 'token', value: 'token' },
                                { label: 'userobj', value: 'userobj' },
                                { label: 'both', value: 'both' }
                            ]}
                            onChange={onChange('usage')}
                        />
                        <label className="dropdown-label-float">
                            <TranslatedString id={'servicesFieldUsage'} />
                        </label>
                    </div>
                </div>

                <div className="delete-custom-field-button-wrapper">
                    <Button
                        icon="pi-md-delete"
                        disabled={this.props.isLoading}
                        onClick={this.handleRemoveClick(index)}
                    />
                </div>
            </AccordionTab>
        );
    }

    renderDropdownOptionsInput(customField, index) {
        const customFieldOptions = customField.options.map(
            (option, optionIndex) => (
                <div className="edit-option-input-group" key={optionIndex}>
                    <div className="edit-option-inputs">
                        <span className="md-inputfield option-input option-label">
                            <InputText
                                className="form-input"
                                value={option.label}
                                onChange={this.handleOnChangeOption(
                                    option,
                                    optionIndex,
                                    customField,
                                    index,
                                    'label'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesFieldOptionsLabel'}
                                />
                            </label>
                        </span>

                        <span className="md-inputfield option-input option-value">
                            <InputText
                                className="form-input"
                                value={option.value}
                                onChange={this.handleOnChangeOption(
                                    option,
                                    optionIndex,
                                    customField,
                                    index,
                                    'value'
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'servicesFieldOptionsValue'}
                                />
                            </label>
                        </span>
                    </div>

                    <Button
                        className="delete-option-button"
                        icon="pi-md-delete"
                        disabled={this.props.isLoading}
                        onClick={this.handleRemoveOptionClick(
                            optionIndex,
                            customField,
                            index
                        )}
                    />
                </div>
            )
        );

        return (
            <div className="p-col-12 edit-option-input-wrapper">
                {customFieldOptions}
                <div className="add-option-button-wrapper">
                    <Button
                        icon="pi-md-add-circle-outline"
                        disabled={this.props.isLoading}
                        onClick={this.handleAddOptionClick(customField, index)}
                    />
                </div>
            </div>
        );
    }

    renderAddCustomFieldButton(numberOfCustomFields) {
        const className = classNames('add-custom-field-button-wrapper', {
            hasCustomFields: numberOfCustomFields > 0
        });
        return (
            <div className={className}>
                <Button
                    icon="pi-md-add-circle-outline"
                    disabled={this.props.isLoading}
                    onClick={this.handleAddClick()}
                />
            </div>
        );
    }

    render() {
        const userCustomFields = (this.props.userCustomFields || []).map(
            (customField, index) =>
                this.renderUserCustomField(customField, index)
        );
        const addCustomFieldButton = this.renderAddCustomFieldButton(
            userCustomFields.length
        );

        return (
            <React.Fragment>
                <Accordion className="accordion" multiple={true}>
                    {userCustomFields}
                </Accordion>
                {addCustomFieldButton}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.i18n.language,
        isLoading: ownProps.isLoading,
        serviceFormFields: ownProps.serviceFormFields,
        type: ownProps.type,
        userCustomFields: ownProps.userCustomFields,
        error: ownProps.error,
        onEditForm: ownProps.onEditForm
    };
};

export default connect(
    mapStateToProps,
    undefined
)(UserCustomFields);
