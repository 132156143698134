let serviceUrl = '';
if (window.location.href.includes('ovk-meet-manager.vzch.com')) {
    serviceUrl = 'https://ovk-meet-app01.vzch.com';
} else if (window.location.href.includes('ovk-meet-int-manager.vzch.com')) {
    serviceUrl = 'https://ovk-meet-int-app01.vzch.com';
}

export const Config = {
    serviceUrl,
    multitenantServices: true
};
