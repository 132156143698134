import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    addMeeting,
    addMeetingValidation,
    deleteMeeting,
    editMeeting,
    requestMeetings,
    resetMeetingsPage,
    startEditingMeeting,
    stopEditingMeeting,
    updateMeeting
} from './actions';

import classNames from 'classnames';
import '../../css/features/Meetings.scss';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Spinner } from 'primereact/spinner';

import EditButtons from '../base/EditButtons';
import DeleteDialog from '../base/DeleteDialog';
import FormButtons from '../base/FormButtons';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import { Accordion, AccordionTab } from '../base/CustomAccordion';
import { Dropdown } from 'primereact/dropdown';
import { parseDate } from '../base/util/helpers';

export class Meetings extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;
        this.defaultMeetingFields = {
            date: '',
            title: '',
            description: '',
            type: 1,
            state: 'open',
            link: '',
            durationInMinutes: 30,
            additionalMetadata: undefined,
            participants: undefined
        };

        this.state = {
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleEditForm = this.handleEditForm.bind(this);
        this.handleAddValidation = this.handleAddValidation.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    componentDidMount() {
        this.props.requestMeetings({
            range: { startIndex: 0, endIndex: this.rows - 1 },
            sort: ['-date']
        });
    }

    componentDidUpdate(prevProps) {
        // reset meetings page if the service was changed
        if (prevProps.service !== this.props.service && this.props.service) {
            this.props.resetMeetingsPage(true, {
                range: {
                    startIndex: 0,
                    endIndex: this.rows - 1
                },
                sort: ['-date']
            });

            this.setState({
                showDeleteDialog: false,
                itemToDeleteId: undefined,
                itemToDeleteName: undefined
            });
        }
    }

    componentWillUnmount() {
        this.props.resetMeetingsPage();
    }

    handlePageClick(event) {
        this.props.requestMeetings({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            },
            sort: ['-date']
        });
    }

    handleEditClick(meetingId) {
        if (
            this.props.isEditing &&
            meetingId === this.props.meetingToEdit._id
        ) {
            this.props.stopEditingMeeting();
        } else {
            //find meeting object with matching meetingId to edit
            const meeting = this.props.meetings.find(item => {
                return item._id === meetingId;
            });

            //build meeting object with using given property values or default values if necessary
            let meetingData = { _id: meeting._id };
            Object.keys(this.defaultMeetingFields).forEach(prop => {
                if (meeting.hasOwnProperty(prop)) {
                    meetingData[prop] = meeting[prop];
                } else {
                    meetingData[prop] = this.defaultMeetingFields[prop];
                }
            });

            this.props.startEditingMeeting(meetingData);
        }
    }

    handleDeleteClick(meetingId, meetingTitle) {
        this.setState({
            showDeleteDialog: true,
            itemToDeleteId: meetingId,
            itemToDeleteName: meetingTitle
        });
    }

    handleDeleteConfirm() {
        const meetings = this.props.meetings;
        let currentItems = { ...this.props.contentRange.currentItems };

        // if the item to be deleted is the last one on the currently selected table page, request the items for the previous table page
        if (
            currentItems.startIndex === currentItems.endIndex &&
            meetings[meetings.length - 1]._id === this.state.itemToDeleteId
        ) {
            currentItems = {
                startIndex: currentItems.startIndex - this.rows,
                endIndex: currentItems.startIndex - 1
            };
            if (currentItems.startIndex < 0) {
                currentItems = undefined;
            }
        }

        this.props.deleteMeeting(this.state.itemToDeleteId, {
            range: currentItems,
            sort: ['-date']
        });
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleDeleteCancel() {
        this.setState({
            showDeleteDialog: false,
            itemToDeleteId: undefined,
            itemToDeleteName: undefined
        });
    }

    handleEditForm(currentFields, update) {
        this.props.editMeeting(currentFields, update);
    }

    handleAddValidation(validationType) {
        const validationSet = this.props.isEditing
            ? this.props.editValidationSet
            : this.props.addValidationSet;
        if (!validationSet.hasOwnProperty(validationType)) {
            this.props.addMeetingValidation(validationType);
        }
    }

    handleSaveClick() {
        let currentItems;
        if (this.props.contentRange) {
            currentItems = { ...this.props.contentRange.currentItems };
        }

        if (this.props.isEditing) {
            this.props.updateMeeting(this.props.meetingToEdit._id, {
                range: currentItems,
                sort: ['-date']
            });
        } else {
            if (currentItems) {
                currentItems.endIndex = currentItems.startIndex + this.rows - 1;
            }
            this.props.addMeeting({ range: currentItems, sort: ['-date'] });
        }
    }

    handleCancelClick() {
        this.props.stopEditingMeeting();
    }

    render() {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8">
                        <MeetingsTable
                            isLoading={this.props.isLoading}
                            editedRowId={
                                this.props.isEditing
                                    ? this.props.meetingToEdit._id
                                    : undefined
                            }
                            meetings={this.props.meetings}
                            language={this.props.language}
                            rows={this.rows}
                            contentRange={this.props.contentRange}
                            onPageClick={this.handlePageClick}
                            onEditClick={this.handleEditClick}
                            onDeleteClick={this.handleDeleteClick}
                        />
                    </div>

                    <div className="p-col-12 p-xl-4">
                        <MeetingForm
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            meetingFormFields={
                                (this.props.isEditing
                                    ? this.props.meetingToEdit
                                    : this.props.meetingToAdd) ||
                                this.defaultMeetingFields
                            }
                            validationSet={
                                this.props.isEditing
                                    ? this.props.editValidationSet
                                    : this.props.addValidationSet
                            }
                            language={this.props.language}
                            onEditForm={this.handleEditForm}
                            onAddValidation={this.handleAddValidation}
                            onSaveClick={this.handleSaveClick}
                            onCancelClick={this.handleCancelClick}
                        />
                    </div>
                </div>

                <DeleteDialog
                    visible={this.state.showDeleteDialog}
                    header={'meetingDeleteHeader'}
                    dialog={'meetingDeleteDialog'}
                    itemToDelete={this.state.itemToDeleteName}
                    onDeleteConfirm={this.handleDeleteConfirm}
                    onDeleteCancel={this.handleDeleteCancel}
                />
            </React.Fragment>
        );
    }
}

class MeetingsTable extends Component {
    render() {
        const meetingTableEntries = this.props.meetings.map((item, key) => {
            let meeting = { ...item };

            // convert date object to desired format (DD.MM.YYYY, hh:mm)
            const date = parseDate(meeting.date);
            meeting.date = `${date.day}.${date.month}.${date.year}, ${date.hours}:${date.minutes}`;

            meeting.numberOfParticipants = (meeting.participants || []).reduce(
                acc => acc + 1,
                0
            );

            switch (meeting.state) {
                case 'open':
                    meeting.state = (
                        <span
                            className="pi-md-fiber-manual-record icon open"
                            key={key}
                        />
                    );
                    break;
                case 'ongoing':
                    meeting.state = (
                        <span
                            className="pi-md-fiber-manual-record icon ongoing "
                            key={key}
                        />
                    );
                    break;
                case 'completed':
                    meeting.state = (
                        <span
                            className="pi-md-fiber-manual-record icon completed"
                            key={key}
                        />
                    );
                    break;
                case 'archived':
                    meeting.state = (
                        <span
                            className="pi-md-fiber-manual-record icon archived"
                            key={key}
                        />
                    );
                    break;
                case 'deleted':
                    meeting.state = (
                        <span
                            className="pi-md-fiber-manual-record icon deleted"
                            key={key}
                        />
                    );
                    break;
                default:
                    meeting.state = '';
            }

            meeting.editMeetingButtons = (
                <EditButtons
                    key={key}
                    id={meeting._id}
                    name={meeting.title}
                    isLoading={this.props.isLoading}
                    onEditClick={this.props.onEditClick}
                    onDeleteClick={this.props.onDeleteClick}
                />
            );

            return meeting;
        });

        return (
            <Card title={getTranslatedString(this.props.language, 'meetings')}>
                <DataTable
                    className="table"
                    autoLayout={true}
                    responsive={true}
                    value={meetingTableEntries}
                    rows={this.props.rows}
                    rowClassName={rowData => {
                        return {
                            'row-bg':
                                rowData &&
                                rowData._id === this.props.editedRowId
                        };
                    }}
                    paginator={true}
                    paginatorPosition={'top'}
                    alwaysShowPaginator={true}
                    lazy={true}
                    totalRecords={
                        this.props.contentRange
                            ? this.props.contentRange.totalItems
                            : undefined
                    }
                    first={
                        this.props.contentRange
                            ? this.props.contentRange.currentItems.startIndex
                            : 0
                    }
                    onPage={this.props.onPageClick}
                >
                    <Column
                        className="column date-col"
                        field="date"
                        header={<TranslatedString id={'meetingTableDate'} />}
                    />
                    <Column
                        className="column title-col"
                        field="title"
                        header={<TranslatedString id={'meetingTableTitle'} />}
                    />
                    <Column
                        className="column description-col"
                        field="description"
                        header={
                            <TranslatedString id={'meetingTableDescription'} />
                        }
                    />
                    <Column
                        className="column participants-col"
                        field="numberOfParticipants"
                        header={
                            <TranslatedString id={'meetingTableParticipants'} />
                        }
                    />
                    <Column
                        className="column state-col"
                        field="state"
                        header={<TranslatedString id={'meetingTableState'} />}
                    />
                    <Column
                        className="column edit-buttons-col"
                        field="editMeetingButtons"
                    />
                </DataTable>
            </Card>
        );
    }
}

class MeetingForm extends Component {
    constructor(props) {
        super(props);

        this.validators = {
            date: date => {
                return date !== '';
            },
            title: title => {
                return title.trim() !== '';
            }
        };
    }

    /**
     * onChange event handler input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.props.onEditForm(this.props.meetingFormFields, {
            field: fieldId,
            value: e.target.value
        });
    };

    /**
     * onBlur event handler for input field
     * @param fieldId - property name of input field
     * @returns {Function}
     */
    handleOnBlur = fieldId => () => {
        this.props.onAddValidation(fieldId);
    };

    /**
     * validate meeting form fields
     * @param meetingFormFields
     * @param invalidMetadataKeys
     * @returns {{invalidInputFields: {date: boolean, title: boolean}, invalidInputs: (boolean|*)}}
     */
    validateFormFields(meetingFormFields, invalidMetadataKeys) {
        const invalidInputFields = {
            date: !this.validators.date(meetingFormFields.date),
            title: !this.validators.title(meetingFormFields.title),
            additionalMetadata: invalidMetadataKeys.reduce(
                (acc, curr) => acc || curr,
                false
            )
        };
        return {
            invalidInputFields: invalidInputFields,
            invalidInputs: Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        };
    }

    renderGeneralSettingsPanel(invalidInputFields) {
        const meetingFormFields = this.props.meetingFormFields;
        const validationSet = this.props.validationSet;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'meetingGeneral'
                )}
            >
                <br />
                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Calendar
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.date &&
                                        validationSet.hasOwnProperty('date')
                                })}
                                value={meetingFormFields.date}
                                dateFormat="dd.mm.yy, "
                                showTime={true}
                                readOnlyInput
                                locale={getTranslatedString(
                                    this.props.language,
                                    'calendar'
                                )}
                                onChange={this.handleOnChange('date')}
                                onBlur={this.handleOnBlur('date')}
                            />
                            <label>
                                <TranslatedString id={'meetingDate'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        invalidInputFields.title &&
                                        validationSet.hasOwnProperty('title')
                                })}
                                value={meetingFormFields.title}
                                onChange={this.handleOnChange('title')}
                                onBlur={this.handleOnBlur('title')}
                            />
                            <label>
                                <TranslatedString id={'meetingTitle'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={meetingFormFields.description}
                                onChange={this.handleOnChange('description')}
                            />
                            <label>
                                <TranslatedString id={'meetingDescription'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <Spinner
                                className="form-input"
                                value={
                                    parseInt(
                                        meetingFormFields.durationInMinutes
                                    ) || 0
                                }
                                step={1}
                                min={1}
                                onChange={this.handleOnChange(
                                    'durationInMinutes'
                                )}
                            />
                            <label>
                                <TranslatedString id={'meetingDuration'} />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Dropdown
                            className="form-input"
                            value={meetingFormFields.type}
                            options={[
                                {
                                    label: 'meeting',
                                    value: 1
                                },
                                {
                                    label: 'webinar',
                                    value: 2
                                },
                                {
                                    label: 'phoneConsulting',
                                    value: 4
                                }
                            ]}
                            onChange={this.handleOnChange('type')}
                        />
                        <label className="dropdown-label-float">
                            <TranslatedString id={'meetingType'} />
                        </label>
                    </div>

                    <div className="p-col-12">
                        <Dropdown
                            className="form-input"
                            value={meetingFormFields.state}
                            options={[
                                {
                                    label: getTranslatedString(
                                        this.props.language,
                                        'meetingStateOpen'
                                    ),
                                    value: 'open'
                                },
                                {
                                    label: getTranslatedString(
                                        this.props.language,
                                        'meetingStateOngoing'
                                    ),
                                    value: 'ongoing'
                                },
                                {
                                    label: getTranslatedString(
                                        this.props.language,
                                        'meetingStateCompleted'
                                    ),
                                    value: 'completed'
                                },
                                {
                                    label: getTranslatedString(
                                        this.props.language,
                                        'meetingStateArchived'
                                    ),
                                    value: 'archived'
                                },
                                {
                                    label: getTranslatedString(
                                        this.props.language,
                                        'meetingStateDeleted'
                                    ),
                                    value: 'deleted'
                                }
                            ]}
                            onChange={this.handleOnChange('state')}
                        />
                        <label className="dropdown-label-float">
                            <TranslatedString id={'meetingState'} />
                        </label>
                    </div>

                    {meetingFormFields.link && (
                        <div className="p-col-12">
                            <span className="md-inputfield">
                                <InputText
                                    className="form-input"
                                    value={meetingFormFields.link}
                                    readOnly={true}
                                />
                                <label>
                                    <TranslatedString id={'meetingLink'} />
                                </label>
                            </span>
                        </div>
                    )}
                </div>
            </AccordionTab>
        );
    }

    renderAdditionalMetadataPanel() {
        const meetingFormFields = this.props.meetingFormFields;
        const metadataKeys = (meetingFormFields.additionalMetadata || []).map(
            field => field[0]
        );
        const invalidMetadataKeys = metadataKeys.map(
            (metadataKey, index) =>
                metadataKey === '' ||
                metadataKeys.indexOf(metadataKey) !== index
        );
        const additionalMetadataPanel = (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'meetingAdditionalMetadata'
                )}
            >
                <MeetingAdditionalMetadata
                    language={this.props.language}
                    isLoading={this.props.isLoading}
                    meetingFormFields={meetingFormFields}
                    additionalMetadata={meetingFormFields.additionalMetadata}
                    error={invalidMetadataKeys}
                    onEditForm={this.props.onEditForm}
                />
            </AccordionTab>
        );

        return {
            invalidMetadataKeys: invalidMetadataKeys,
            additionalMetadataPanel: additionalMetadataPanel
        };
    }

    renderParticipantsPanel() {
        const meetingFormFields = this.props.meetingFormFields;

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'meetingParticipants'
                )}
            >
                <MeetingParticipants
                    language={this.props.language}
                    isLoading={this.props.isLoading}
                    meetingFormFields={meetingFormFields}
                    meetingParticipants={meetingFormFields.participants}
                    validationSet={this.props.validationSet}
                    onEditForm={this.props.onEditForm}
                />
            </AccordionTab>
        );
    }

    render() {
        const {
            invalidMetadataKeys,
            additionalMetadataPanel
        } = this.renderAdditionalMetadataPanel();
        const { invalidInputFields, invalidInputs } = this.validateFormFields(
            this.props.meetingFormFields,
            invalidMetadataKeys
        );
        const generalSettingsPanel = this.renderGeneralSettingsPanel(
            invalidInputFields
        );
        const participantsPanel = this.renderParticipantsPanel();

        return (
            <Card
                title={getTranslatedString(
                    this.props.language,
                    this.props.isEditing ? 'meetingEdit' : 'meetingAdd'
                )}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <Accordion
                            className="accordion"
                            multiple={true}
                            activeIndex={[0]}
                        >
                            {generalSettingsPanel}
                            {additionalMetadataPanel}
                            {participantsPanel}
                        </Accordion>
                    </div>

                    <div className="p-col-12">
                        <FormButtons
                            invalidInputs={invalidInputs}
                            isLoading={this.props.isLoading}
                            isEditing={this.props.isEditing}
                            onSaveClick={this.props.onSaveClick}
                            onCancelClick={this.props.onCancelClick}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

class MeetingAdditionalMetadata extends Component {
    constructor(props) {
        super(props);

        let validationSet = this.props.additionalMetadata
            ? new Array(this.props.additionalMetadata.length).fill(false)
            : [];

        this.state = {
            validationSet: validationSet
        };

        this.defaultAdditionalMetadata = {
            key: '',
            value: ''
        };
    }

    /**
     *
     * @param additionalMetadataField
     * @param fieldId
     * @param index
     * @returns {Function}
     */
    handleOnChange = (additionalMetadataField, fieldId, index) => e => {
        const additionalMetadata = this.props.additionalMetadata.map(field =>
            field.slice(0)
        );
        additionalMetadata[index][fieldId === 'key' ? 0 : 1] = e.target.value;

        this.props.onEditForm(this.props.meetingFormFields, {
            field: 'additionalMetadata',
            value: additionalMetadata
        });
    };

    /**
     *
     * @param index
     * @returns {Function}
     */
    handleOnBlur = index => () => {
        let validationSet = this.state.validationSet.slice(0);
        validationSet[index] = true;
        this.setState({ validationSet: validationSet });
    };

    /**
     *
     * @returns {Function}
     */
    handleAddClick = () => () => {
        const additionalMetadata = [
            ...(this.props.additionalMetadata || []).slice(0),
            ['', '']
        ];

        this.props.onEditForm(this.props.meetingFormFields, {
            field: 'additionalMetadata',
            value: additionalMetadata
        });

        let validationSet = this.state.validationSet.slice(0);
        validationSet.push(false);
        this.setState({ validationSet: validationSet });
    };

    /**
     *
     * @param index - index of the additionalMetadata property
     * @returns {Function}
     */
    handleRemoveClick = index => () => {
        const additionalMetadata = [
            ...this.props.additionalMetadata.slice(0, index),
            ...this.props.additionalMetadata.slice(index + 1)
        ];

        this.props.onEditForm(this.props.meetingFormFields, {
            field: 'additionalMetadata',
            value: additionalMetadata
        });

        let validationSet = this.state.validationSet.slice(0);
        validationSet.splice(index, 1);
        this.setState({ validationSet: validationSet });
    };

    renderAdditionalMetadataField(metadataField, index) {
        return (
            <div className="edit-metadata-input-group" key={index}>
                <div className="p-grid edit-metadata-inputs">
                    <div className="p-col-6 metadata-input">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        this.state.validationSet[index] &&
                                        this.props.error[index]
                                })}
                                value={
                                    metadataField[0] ||
                                    this.defaultAdditionalMetadata.key
                                }
                                onChange={this.handleOnChange(
                                    metadataField,
                                    'key',
                                    index
                                )}
                                onBlur={this.handleOnBlur(index)}
                            />
                            <label>
                                <TranslatedString
                                    id={'meetingAdditionalMetadataLabel'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-6 metadata-input">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={
                                    metadataField[1] ||
                                    this.defaultAdditionalMetadata.value
                                }
                                onChange={this.handleOnChange(
                                    metadataField,
                                    'value',
                                    index
                                )}
                            />
                            <label>
                                <TranslatedString
                                    id={'meetingAdditionalMetadataValue'}
                                />
                            </label>
                        </span>
                    </div>
                </div>

                <Button
                    className="delete-metadata-button"
                    icon="pi-md-delete"
                    disabled={this.props.isLoading}
                    onClick={this.handleRemoveClick(index)}
                />
            </div>
        );
    }

    renderAddAdditionalMetadataButton() {
        return (
            <div className="add-metadata-button-wrapper">
                <Button
                    icon="pi-md-add-circle-outline"
                    disabled={this.props.isLoading}
                    onClick={this.handleAddClick()}
                />
            </div>
        );
    }

    render() {
        const additionalMetadataFields = (
            this.props.additionalMetadata || []
        ).map((metadataField, index) =>
            this.renderAdditionalMetadataField(metadataField, index)
        );
        const addAdditionalMetadataButton = this.renderAddAdditionalMetadataButton();

        return (
            <div className="p-col-12 edit-metadata-input-wrapper">
                {additionalMetadataFields.length > 0 && <br />}
                {additionalMetadataFields}
                {addAdditionalMetadataButton}
            </div>
        );
    }
}

class MeetingParticipants extends Component {
    constructor(props) {
        super(props);

        this.defaultMeetingParticipant = {
            name: '',
            email: '',
            adviserId: '',
            isPublic: true
        };
    }

    /**
     * onChange event handler for participant properties
     * @param participant - participant object before the change
     * @param index - index of the participant object in the participants array
     * @param fieldId - object property name of the value to be changed
     * @returns {Function}
     */
    handleOnChange = (participant, index, fieldId) => e => {
        this.handleEdit({ ...participant, [fieldId]: e.target.value }, index);
    };

    /**
     * onClick event handler for the 'add participant button'
     * @returns {Function}
     */
    handleAddClick = () => () => {
        let meetingParticipants;
        if (this.props.meetingParticipants) {
            meetingParticipants = [...this.props.meetingParticipants];
            meetingParticipants.push({ ...this.defaultMeetingParticipant });
        } else {
            meetingParticipants = [{ ...this.defaultMeetingParticipant }];
        }

        this.props.onEditForm(this.props.meetingFormFields, {
            field: 'participants',
            value: meetingParticipants
        });
    };

    /**
     * onClick event handler for the 'remove participant button'
     * @param index - index of the participant object in the participants array
     * @returns {Function}
     */
    handleRemoveClick = index => () => {
        const meetingParticipants = [
            ...this.props.meetingParticipants.slice(0, index),
            ...this.props.meetingParticipants.slice(index + 1)
        ];

        this.props.onEditForm(this.props.meetingFormFields, {
            field: 'participants',
            value: meetingParticipants
        });
    };

    /**
     *
     * @param participant - participant being edited
     * @param index - index of the participant being edited
     */
    handleEdit(participant, index) {
        const meetingParticipants = [
            ...this.props.meetingParticipants.slice(0, index),
            participant,
            ...this.props.meetingParticipants.slice(index + 1)
        ];

        this.props.onEditForm(this.props.meetingFormFields, {
            field: 'participants',
            value: meetingParticipants
        });
    }

    renderParticipant(participant, index) {
        const name = participant.name || this.defaultMeetingParticipant.name;
        const email = participant.email || this.defaultMeetingParticipant.email;
        const adviserId =
            participant.adviserId || this.defaultMeetingParticipant.adviserId;
        const header =
            name ||
            getTranslatedString(this.props.language, 'meetingNewParticipant');
        const onChange = fieldId =>
            this.handleOnChange(participant, index, fieldId);

        return (
            <AccordionTab header={header} key={index}>
                <br />
                <div className="p-grid form-group" key={index}>
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={name}
                                onChange={onChange('name')}
                            />
                            <label>
                                <TranslatedString
                                    id={'meetingParticipantName'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={email}
                                onChange={onChange('email')}
                            />
                            <label>
                                <TranslatedString
                                    id={'meetingParticipantEmail'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className="form-input"
                                value={adviserId}
                                onChange={onChange('adviserId')}
                            />
                            <label>
                                <TranslatedString
                                    id={'meetingParticipantAdviserId'}
                                />
                            </label>
                        </span>
                    </div>

                    <div className="p-col-12">
                        <Checkbox
                            value={!participant.isPublic}
                            checked={participant.isPublic}
                            onChange={onChange('isPublic')}
                        />
                        <label className="p-checkbox-label">
                            <TranslatedString id={'meetingParticipantPublic'} />
                        </label>
                    </div>

                    {participant.link && (
                        <div className="p-col-12">
                            <span className="md-inputfield">
                                <InputText
                                    className="form-input"
                                    value={participant.link}
                                    readOnly={true}
                                />
                                <label>
                                    <TranslatedString
                                        id={'meetingParticipantLink'}
                                    />
                                </label>
                            </span>
                        </div>
                    )}
                </div>

                <div className="delete-custom-field-button-wrapper">
                    <Button
                        icon="pi-md-delete"
                        disabled={this.props.isLoading}
                        onClick={this.handleRemoveClick(index)}
                    />
                </div>
            </AccordionTab>
        );
    }

    renderAddParticipantButton(numberOfParticipants) {
        const className = classNames('add-participant-button-wrapper', {
            hasParticipants: numberOfParticipants > 0
        });
        return (
            <div className={className}>
                <Button
                    icon="pi-md-add-circle-outline"
                    disabled={this.props.isLoading}
                    onClick={this.handleAddClick()}
                />
            </div>
        );
    }

    render() {
        const meetingParticipants = (
            this.props.meetingParticipants || []
        ).map((participant, index) =>
            this.renderParticipant(participant, index)
        );
        const addParticipantButton = this.renderAddParticipantButton(
            meetingParticipants.length
        );

        return (
            <React.Fragment>
                <Accordion className="accordion" multiple={true}>
                    {meetingParticipants}
                </Accordion>
                {addParticipantButton}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.meetings.isLoading,
        isEditing: state.meetings.isEditing,
        meetingToAdd: state.meetings.meetingToAdd,
        addValidationSet: state.meetings.addValidationSet,
        meetingToEdit: state.meetings.meetingToEdit,
        editValidationSet: state.meetings.editValidationSet,
        meetings: state.meetings.meetings,
        contentRange: state.meetings.contentRange,

        service: state.auth.service,
        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestMeetings,
    addMeeting,
    updateMeeting,
    deleteMeeting,
    startEditingMeeting,
    stopEditingMeeting,
    editMeeting,
    addMeetingValidation,
    resetMeetingsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Meetings);
