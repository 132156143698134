export const french = {
    language: 'Langue',

    de: 'Allemand',
    en: 'Anglais',
    fr: 'Français',
    it: 'Italien',

    // ServiceInputPage
    next: 'Suivant',

    // LoginPage
    applicationName: 'adiaLive Manager',
    serviceInputFormHeader: 'Service',
    loginFormHeader: 'Login',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: "S'identifier",

    edit: 'Modifier',
    save: 'Sauvegarder',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    ok: 'OK',
    yes: 'Oui',
    no: 'Non',
    download: 'Télécharger',

    excelExportHeader: 'Exportation Excel',
    excelExportLoadingMessage:
        "Un instant s'il vous plaît, votre téléchargement est en cours de préparation.",
    excelExportDownloadMessage:
        'Terminé. Vous pouvez commencer le téléchargement maintenant.',
    excelExportErrorMessage: 'Oops! Une erreur est survenue.',

    // ADMINISTRATION
    administration: 'ADMINISTRATION',

    // Service Users
    users: 'Utilisateurs',
    usersSearch: 'Rechercher des utilisateurs',

    userTableId: "ID de l'utilisateur",
    userTableAdditionalId: 'ID supplémentaire',
    userTableName: 'Nom',
    userTableUsername: "Nom d'utilisateur",
    userTableRoles: 'Rôles',
    userAdd: 'Ajouter un utilisateur',
    userEdit: 'Modifier un utilisateur',
    userDeleteHeader: 'Supprimer un utilisateur',
    userDeleteDialog: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
    userGeneralSettings: "Paramètres généraux de l'utilisateur",
    userId: "ID de l'utilisateur",
    userUsername: "Nom d'utilisateur *",
    userName: 'Nom *',
    userRoles: 'Rôles *',
    userAdditionalId: 'ID supplémentaire',
    userPassword: 'Mot de passe *',
    userConfirmPassword: 'Confirmer le mot de passe *',
    userNewPassword: 'Nouveau mot de passe',
    userConfirmNewPassword: 'Confirmer le nouveau mot de passe',
    userIdTooltip:
        "Facultatif, par exemple une adresse Email ou un numéro d'employé",

    // Meetings
    meetings: 'Meetings',
    meetingTableDate: 'Date',
    meetingTableTitle: 'Titre',
    meetingTableDescription: 'Description',
    meetingTableParticipants: 'Participants',
    meetingTableState: 'Statut',
    meetingAdd: 'Ajouter un nouveau meeting',
    meetingEdit: 'Modifier un meeting',
    meetingDeleteHeader: 'Supprimer un meeting?',
    meetingDeleteDialog: 'Êtes-vous sûr de vouloir supprimer ce meeting?',
    meetingGeneral: 'Paramètres généraux',
    meetingDate: 'Date *',
    meetingTitle: 'Titre *',
    meetingDescription: 'Description',
    meetingDuration: 'Durée (en minutes)',
    meetingLink: 'Lien',
    meetingType: 'Type',
    meetingState: 'Statut',
    meetingStateOpen: 'ouvert',
    meetingStateOngoing: 'en cours',
    meetingStateCompleted: 'terminé',
    meetingStateArchived: 'archivé',
    meetingStateDeleted: 'supprimé',
    meetingAdditionalMetadata: 'Métadonnées supplémentaires',
    meetingAdditionalMetadataLabel: 'Propriété',
    meetingAdditionalMetadataValue: 'Valeur',
    meetingParticipants: 'Participant',
    meetingNewParticipant: 'Nouveau participant',
    meetingParticipantName: 'Nom *',
    meetingParticipantEmail: 'E-Mail',
    meetingParticipantAdviserId: 'ID de conseiller',
    meetingParticipantLink: 'Lien',
    meetingParticipantPublic: 'Public',

    //ApiKeys
    apiKeys: "Clé de l'API",
    apiKeyTableTitle: 'Titre',
    apiKeyTableRoles: 'Rôles',
    apiKeyTableIp: 'IP',
    apiKeyTableActive: 'Actif',
    apiKeyAdd: "Ajouter une nouvelle clé de l'API",
    apiKeyEdit: "Modifier une clé de l'API",
    apiKeyDeleteHeader: "Supprimer une clé de l'API",
    apiKeyDeleteDialog:
        "Êtes-vous sûr de vouloir supprimer cette clé de l'API?",
    apiKeyTitle: 'Titre *',
    apiKeyRoles: 'Rôles *',
    apiKeyToken: 'Clé',
    apiKeyIp: 'IP',
    apiKeyActive: 'Actif',

    // ANALYTICS
    analytics: 'ANALYTICS',

    // Sessions
    sessions: 'Sessions',
    sessionFilter: 'Filtre',
    sessionFilterHasRecordings: 'Enregistré',

    sessionExportSelectDateMessage:
        "Sélectionnez la période pour l'exportation:",
    sessionExportSelectUntil: "jusqu'à",
    sessionExportSelectMaxDataMessage:
        'Notez qu’un maximum de 20 000 enregistrements peuvent être exportés en une fois.',
    sessionExportType: "Type d'exportation:",

    sessionDownloadRecordings: 'Télécharger les enregistrements',
    sessionDownloadAudioRecordings: 'Télécharger les enregistrements audio',
    sessionPrepareRecordingsMessage:
        'Les enregistrements seront préparés pour le téléchargement.',
    sessionDownloadMessage:
        'Les enregistrements sont prêts pour le téléchargement.',
    sessionDownloadOngoingMessage:
        'Les enregistrements seront téléchargés. Cela peut prendre du temps.',
    sessionBackgroundDownload: 'Télécharger en arrière-plan',
    sessionDownloadNoRecordingsFound:
        "Il n'y a aucun enregistrement pour cette session.",
    sessionDownloadNoDownloadAvailable:
        "Il n'y a pas encore de téléchargement disponible pour ces enregistrements.",
    sessionDownloadErrorMessage:
        "Une erreur s'est produite lors de la préparation du téléchargement.",
    sessionTableDate: 'Date',
    sessionTableStartTime: 'Début de session',
    sessionTableDuration: 'Durée',
    sessionTableAdviser: 'Conseiller',
    sessionTableParticipants: 'Participants',
    sessionTableRecordings: 'Enregistrements',
    sessionInfo: 'Information de la session',
    sessionInfoMessage:
        "Sélectionnez une session, afin d'afficher des informations supplémentaires.",
    sessionMeetingInfo: 'Information du meeting',
    sessionMeetingInfoDate: 'Date',
    sessionMeetingInfoTitle: 'Titre',
    sessionMeetingInfoDescription: 'Description',
    sessionMeetingInfoDuration: 'Durée du meeting',
    sessionParticipantInfo: 'Information du participant',
    sessionParticipantInfoName: 'Nom',
    sessionParticipantInfoEmail: 'E-Mail',
    sessionParticipantInfoIsAdviser: 'Conseiller',
    sessionLog: 'Log de la session',
    sessionInfoLogUnavailableMessage:
        "Aucun log n'est disponible pour la session sélectionnée.",

    // ADMIN
    sysAdmin: 'SYS-ADMIN',

    // Admin Users
    adminUsers: 'Administrateurs',
    adminUser: 'Administrateur',
    adminUserTableId: 'ID',
    adminUserTableName: 'Nom',
    adminUserTableUsername: "Nom d'utilisateur",
    adminUserAdd: 'Ajouter un administrateur',
    adminUserEdit: 'Modifier un administrateur',
    adminUserDeleteHeader: 'Supprimer un administrateur?',
    adminUserDeleteDialog:
        'Êtes-vous sûr de vouloir supprimer cet administrateur?',
    adminUserUsername: "Nom d'utilisateur *",
    adminUserName: 'Nom *',
    adminUserId: 'ID',

    // Services
    services: 'Services',
    servicesSearch: 'Recherche de services',

    servicesTableId: 'ID',
    servicesTableName: 'Nom',
    servicesTableLogo: 'Logo',
    servicesTableActive: 'Actif',
    servicesTableSessionLog: 'Log de session',
    servicesTableChatLog: 'Log de la discussion',
    servicesAdd: 'Ajouter un service',
    servicesEdit: 'Modifier un service',
    servicesDeleteHeader: 'Supprimer un service?',
    servicesDeleteDialog: 'Êtes-vous sûr de vouloir supprimer ce service?',
    servicesGeneral: 'Paramètres généraux',
    servicesId: 'ID *',
    servicesName: 'Nom *',
    servicesLogo: 'Logo',
    servicesAuthMethod: "Méthode d'authentification",
    servicesPublicServiceFields: 'Public Service Fields',
    servicesOriginRestrictions: 'Origin Restrictions',
    servicesActive: 'Actif',
    servicesServiceEmailAddress: 'Adresse e-mail de contact',

    servicesMeetingsSettings: 'Paramètres des meetings',
    servicesSharedApplicationsUrl: 'Shared Applications Url',
    servicesMeetingsManagerUrl: 'Meetings Manager URL',
    servicesCommunicatorUrl: 'Communicator URL',
    servicesAdviserUrl: 'Adviser URL',
    servicesParticipantLimit: 'Limite des participants',
    servicesEnableAdHoc: 'Fonction Ad hoc activé',
    servicesEnableRecording: "Activer l'enregistrement",
    servicesEnableRecordingConfirmation:
        "Activer la confirmation d'enregistrement",
    servicesEnableAutoRecording: "Activer l'enregistrement automatique",
    servicesDisableCommunicatorFileTransfer:
        'Transfert de documents au Communicator est désactivé',
    servicesEnableCommunicatorContactForm:
        'Activer le formulaire de contact au Communicator',

    servicesClientSettings: 'Client Settings',

    servicesDisableFileAttachmentsForParticipants:
        'Disable File Attachments for Participants',
    servicesDisableFileDownloadsForParticipants:
        'Disable File Downloads for Participants',
    servicesMaxTotalAttachmentsSize: 'Maximum Total Attachment Size',
    servicesFileMaxSize: 'Maximum File Size',
    servicesAcceptDocumentsTypesModerators:
        'Accepted Document Types (Moderators)',
    servicesAcceptDocumentsTypesAttendees:
        'Accepted Document Types (Attendees)',
    servicesAllowedMimes: 'Allowed Mime Types',
    servicesEnableNepatec: 'Activer Nepatec',
    servicesShowInsign: 'Activer Insign',
    servicesMeetingsLimit: 'Meetings Limit',
    servicesAddCurrentUserToNewMeeting: 'Add current user to new meeting',
    servicesAddAdditionalModerators: 'Add additional moderators',
    servicesKeldanoFeDomain: 'Keldano Fe Domain',
    servicesKeldanoApiDomain: 'Keldano Api Domain',
    servicesAttachAgenda: 'Attach Agenda',
    servicesAttachProtocol: 'Attach Protocol',
    servicesAttendeesSendPostProcessingMailEnabled:
        'Send Post-Processing Mail Enabled (Attendees)',
    servicesHideToolbar: 'Hide Toolbar',
    servicesPreselectInvitationMailModerators:
        'Preselect Invitation Email (Moderators)',
    servicesPreselectInvitationMailAttendees:
        'Preselect Invitation Email (Attendees)',
    servicesPreselectPostProcessingMailModerators:
        'Preselect Post-Processing Email (Moderators)',
    servicesPreselectPostProcessingMailAttendees:
        'Preselect Post-Processing Email (Attendees)',

    servicesMailTemplateSettings: 'Mail Template Settings',
    servicesMailTemplateLogo: 'Mail Logo *',
    servicesMailTemplateCompany: 'Mail Company',
    servicesMailTemplateAddress: 'Mail Address',
    servicesMailTemplatePhone: 'Mail Phone',
    servicesMailTemplateWebPage: 'Mail Webpage',
    servicesMailTemplateFaxNumber: 'Mail Fax Number',

    servicesMailSettings: 'Mail Settings',
    servicesMailCredentialsUsername: 'Username',
    servicesMailCredentialsPassword: 'Password',
    servicesMailFromAddress: 'From Address',
    servicesMailFromName: 'From Name',
    servicesMailReplyToAddress: 'Reply To Address',
    servicesMailReplyToName: 'Reply To Name',
    servicesMailReturnPathAddress: 'Return Path Address',
    servicesMailReturnPathName: 'Return Path Name',
    servicesMailSmtpServer: 'SMTP Server',
    servicesMailSmtpPort: 'SMTP Port',
    servicesMailMethod: 'Method',
    servicesKeycloakDomain: 'Keycloak Domain',

    servicesLocalesOverwrites: 'Locales Overwrites',

    servicesWebRtcSettings: 'Paramètres WebRTC',
    servicesWebRtcMode: 'Mode',
    servicesWebRtcUrl: 'URL *',
    servicesWebRtcSecret: 'Secret *',
    servicesWebRtcApplicationId: 'Application ID *',
    servicesWebRtcApiKey: 'Api Key *',
    servicesWebRtcMaxAudioBitrate: 'Audio Input Max Bitrate (kbps)',
    servicesWebRtcMaxVideoBitrate: 'Video Input Max Bitrate (kbps)',
    servicesWebRtcUseSimulcast: 'Activer Simulcast',
    servicesWebRtcIceServerAddress: 'Turn Server',
    servicesWebRtcRelayUsername: 'Turn Username',
    servicesWebRtcRelayPassword: 'Turn Password',

    servicesKeycloakSettings: 'Paramètres de Keycloak',
    servicesKeycloakUrl: 'URL',
    servicesKeycloakRealm: 'Realm',
    servicesKeycloakClientId: 'Client Id',
    servicesKeycloakKid: 'KID',
    servicesKeycloakPublicKey: 'Public Key',
    servicesKeldanoApiSettings: "Paramètres de l'API",
    servicesKeldanoApiUrl: 'URL *',
    servicesKeldanoApiClientId: 'Client Id *',
    servicesKeldanoApiClientSecret: 'Client Secret *',
    servicesKeldanoApiUsername: "Nom d'utilisateur *",
    servicesKeldanoApiPassword: 'Mot de passe *',
    servicesKeldanoApiEndpoint: 'Keldano API Endpoint',

    servicesWebinarSettings: 'Paramètres du webinaire',
    servicesWebinarAdvisers: 'Nombre maximum de conseillers',
    servicesWebinarParticipants: 'Nombre maximum de participants',
    servicesWebinarDisableVideoForParticipants:
        'Désactiver la vidéo pour les participants',

    servicesPhoneConsultingSettings: 'Paramètres des conseils téléphoniques',

    servicesOptions: 'Options',
    servicesExperimentalMode: 'Experimental Mode',
    servicesMeetings: 'Meetings',
    servicesOneToOne: 'One-To-One',
    servicesVideoIdent: 'Identification Vidéo',
    servicesBranch: 'Branch',
    servicesKeldanoApi: 'Keldano API',
    servicesWebinar: 'Webinaire',
    servicesHideAdviserList: 'Hide Adviserlist',
    servicesPhoneConsulting: 'Conseils téléphoniques',

    servicesLogging: 'Logging',
    servicesSessionLog: 'Log de session',
    servicesChatLog: 'Log de la discussion',
    servicesAdviserTracking: 'Adviser Tracking',
    //servicesUserCustomFields: 'Champs de saisie personnalisés (Service utilisateurs)',
    servicesAdviserUserCustomFields:
        'Champs de saisie personnalisés (Utilisateur)',
    servicesNewCustomField: 'Nouveau champs de saisie personnalisés',
    servicesFieldId: 'ID',
    servicesFieldType: 'Type',
    servicesFieldLabel: 'Label',
    servicesFieldDescription: 'Description',
    servicesFieldRequired: 'Requis',
    servicesFieldDefault: 'Par défaut',
    servicesFieldPattern: 'Pattern de validtion',
    servicesFieldUsage: 'Usage',
    servicesFieldOptions: 'Options de la liste déroulante',
    servicesFieldOptionsLabel: 'Label de la liste déroulante',
    servicesFieldOptionsValue: 'Valeur',
    invalidCustomFieldId:
        'Un identifiant vide est invalide. Les identifiants suivants ne doivent pas être utilisés: name, id, username, password, passwordConfirmation.',

    calendar: {
        firstDayOfWeek: 1,
        dayNames: [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi'
        ],
        dayNamesShort: ['Di.', 'Lu.', 'Ma.', 'Me.', 'Je.', 'Ve.', 'Sa.'],
        dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        monthNames: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ],
        monthNamesShort: [
            'Jan.',
            'Fév.',
            'Mar.',
            'Avr.',
            'Mai',
            'Juin',
            'Jul.',
            'Août',
            'Sep.',
            'Oct.',
            'Nov.',
            'Déc.'
        ]
    },
    pathDoesNotExist: "Cette page n'existe pas!",
    invalidUsername:
        'Seulement les lettres, les chiffres, "-", "_" et "." sont autorisés.',
    invalidPassword: 'Le mot de passe doit comporter au moins 8 caractères.',
    invalidPasswordConfirmation: 'Les mots de passe ne correspondent pas.',
    logoutDialogHeader: 'Logout',
    logoutDialog:
        'En cliquant sur "Oui", vous serez déconnecté de toutes les applications adiaLive5. Vous perdrez la connexion avec toutes les réunions en cours auxquelles vous participez.',

    // messages
    connectionError: 'La connexion au serveur adiaLive a échoué.',
    noServiceError:
        'Au moins un service doit exister pour effectuer cette action.',
    changeServiceError: "Le service n'a pas pu être changé.",
    editCurrentUserErrorMessage:
        "Il n'est pas possible de modifier ou de supprimer un utilisateur avec lequel vous êtes connecté.",
    serverError: 'Oops! Une erreur est survenue.',
    accessDenied: 'Accès refusé!',
    notAuthorized: 'Pas autorisé!',
    authInvalidIp: 'IP invalide.',
    authInvalidUser: 'Utilisateur invalide.',
    authInvalidCaptcha: 'Captcha invalide.',
    authInvalidCredentials: "Données d'identification invalides.",
    userRequestError: "L'utilisateur n'a pas pu être récupéré.",
    userAddSuccess: "L'utilisateur a été ajouté.",
    userConflict: "Ce nom d'utilisateur est déjà utilisé.",
    userKeycloakConflict:
        "Ce nom d'utilisateur et / ou cet email est déjà utilisé.",
    userAddError: "L'utilisateur n'a pas pu être ajouté.",
    userUpdateSuccess: "L'utilisateur a été mis à jour.",
    userNotFound: "Cet utilisateur n'existe pas.",
    userUpdateError: "L'utilisateur n'a pas pu être mis à jour.",
    userDeleteSuccess: "L'utilisateur a été supprimé.",
    userDeleteError: "L'utilisateur n'a pas pu être supprimé.",
    userSyncSuccess: 'Les utilisateurs ont été synchronisés.',
    userSyncError:
        "Une erreur s'est produite lors de la synchronisation des utilisateurs.",
    meetingRequestError: "Les meetings n'ont pas pu être récupérées.",
    meetingAddSuccess: 'Le meeting a été ajouté.',
    meetingAddNotSupported:
        'Les meetings Keldano ne peuvent pas être ajoutées dans le Manager.',
    meetingAddError: "Le meeting n'a pas pu être ajouté.",
    meetingUpdateSuccess: 'Le meeting a été mis à jour.',
    meetingNotFound: "Ce meeting n'existe pas.",
    meetingUpdateError: "Le meeting n'a pas pu être mis à jour.",
    meetingDeleteSuccess: 'Le meeting a été supprimé.',
    meetingDeleteNotSupported:
        'Les meetings Keldano ne peuvent pas être supprimés dans le manager.',
    meetingDeleteError: "Le meeting n'a pas pu être supprimé.",
    apiKeyRequestError: "La clé API n'a pas pu être récupérée.",
    apiKeyAddSuccess: 'La clé API a été ajoutée.',
    apiKeyAddError: "La clé API n'a pas pu être ajoutée.",
    apiKeyUpdateSuccess: 'La clé API a été mise à jour.',
    apiKeyNotFound: "Cette clé API n'existe pas.",
    apiKeyUpdateError: "La clé API n'a pas pu être mise à jour.",
    apiKeyDeleteSuccess: 'La clé API a été supprimée.',
    apiKeyDeleteError: "La clé API n'a pas pu être supprimée.",
    sessionRequestError: "Les sessions n'ont pas pu être récupérées.",
    sessionDownloadNotAllowed:
        "Vous n'êtes pas autorisé à télécharger des enregistrements.",
    adminUserRequestError: "Les administrateurs n'ont pas pu être récupérés.",
    adminUserAddSuccess: "L'Administrateur a été ajouté.",
    adminUserConflict:
        "Un administrateur avec ce nom d'utilisateur existe déjà.",
    adminUserAddError: "L'administrateur n'a pas pu être ajouté.",
    adminUserUpdateSuccess: "L'administrateur a été mis à jour.",
    adminUserNotFound: "Cet administrateur n'existe pas.",
    adminUserUpdateError: "L'administrateur n'a pas pu être mis à jour.",
    adminUserDeleteSuccess: "L'administrateur a été supprimé.",
    adminUserDeleteError: "L'administrateur n'a pas pu être supprimé.",
    serviceRequestError: "Les services n'ont pas pu être récupérés.",
    serviceAddSuccess: 'Le service a été ajouté',
    serviceConflict: 'Un service avec cet ID existe déjà.',
    serviceWebRtcError: "Les paramètres WebRTC n'ont pas pu être définis.",
    serviceAddError: "Le service n'a pas pu être ajouté.",
    serviceUpdateSuccess: 'Le service a été mis à jour.',
    serviceNotFound: "Ce service n'existe pas.",
    serviceUpdateError: "Le service n'a pas pu être mis à jour.",
    serviceDeleteSuccess: 'Le service a été supprimé.',
    serviceDeleteError: "Le service n'a pas pu être supprimé."
};
