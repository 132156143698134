export default {
    HOME: '/',

    // service routes
    SERVICE_LOGIN: '/:serviceId',
    SERVICE_USERS: '/:serviceId/service/users',
    SERVICE_MEETINGS: '/:serviceId/service/meetings',
    SERVICE_SESSIONS: '/:serviceId/analytics/sessions',

    // admin routes
    ADMIN_LOGIN: '/admin',
    USERS: '/service/users',
    MEETINGS: '/service/meetings',
    API_KEYS: '/service/apikeys',
    SESSIONS: '/analytics/sessions',
    ADMIN_USERS: '/admin/users',
    SERVICES: '/admin/services'
};
