import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect, Route, Switch } from 'react-router';
import Routes from './constants/routes';
import { log, routeWithServiceId } from './features/base/util/helpers';
import {
    hasMenuAccess,
    hasRouteAccess,
    isAdminUser,
    setRouteAccess
} from './features/base/util/accessControl';

import { cancelLogout, checkReLogin, logout } from './features/auth/actions';

import './css/tables.scss';
import './css/forms.scss';
import './css/icons.scss';

import '@fortawesome/fontawesome-free/css/all.min.css';

import './serenity/ripple';
import SerenityLayout from './features/layout/SerenityLayout';
import CustomGrowl from './features/base/CustomGrowl';

import ServiceInputPage from './features/auth/ServiceInputPage';
import LoginPage from './features/auth/LoginPage';
import Users from './features/users/Users';
import Meetings from './features/meetings/Meetings';
import ApiKeys from './features/apikeys/ApiKeys';
import Sessions from './features/sessions/Sessions';
import AdminUsers from './features/adminusers/AdminUsers';
import Services from './features/services/Services';
import NoContentPage from './features/base/NoContentPage';
import ErrorPage from './features/base/errorpage/ErrorPage';
import TranslatedString from './features/base/i18n/TranslatedString';
import LogoutDialog from './features/base/LogoutDialog';

class Manager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentService: undefined,
            currentUser: undefined,
            hasService: undefined,
            menuModel: undefined,
            contentPages: undefined
        };
    }

    componentDidMount() {
        this.props.checkReLogin();
    }

    /**
     * check for certain props changes; depending on those, decide if it's necessary to recompute the menu model and the contentPages
     * @param props
     * @param state
     * @returns {{currentUser: *, contentPages: *, menuModel: *, currentService: *, hasService: boolean}|null}
     */
    static getDerivedStateFromProps(props, state) {
        if (props.authenticated) {
            const hasService = !!(
                props.service && props.service.hasOwnProperty('_id')
            );

            // check if the user changed (user access rules might have changed) and
            // check if there is an active service (if not, we don't allow access to certain pages)
            // check for service properties (add checks for other service properties here)
            if (
                (props.user && props.user !== state.currentUser) ||
                hasService !== state.hasService ||
                (hasService &&
                    state.currentService &&
                    (props.service.meetings !== state.currentService.meetings ||
                        props.service.logSessions !==
                            state.currentService.logSessions))
            ) {
                log.debug('[updating menuModel and contentPages]');

                const serviceId = hasService ? props.service._id : undefined; // serviceId of active service (undefined if no service exists yet)
                const roles = (props.user && props.user.roles) || []; // roles of the current user (empty array if user object is missing for some reason)
                const isAdmin = isAdminUser(props.user); // true if then current user is an admin
                let menuModel,
                    contentPages,
                    routes,
                    meetingsEnabled,
                    sessionsEnabled,
                    sessionRoute,
                    meetingRoute,
                    serviceLoginRoute,
                    noContent;

                // check if the 'meetings'/'logSession' flag is set for the service
                meetingsEnabled = !!(hasService && props.service.meetings);
                sessionsEnabled = !!(hasService && props.service.logSessions);

                // ---------- compute menuItems for current user ----------
                const menuItems = [
                    // only show administration/analytics menu points if there is a service
                    ...(hasService
                        ? [
                              {
                                  label: (
                                      <TranslatedString id={'administration'} />
                                  ),
                                  icon: 'verified_user',
                                  items: [
                                      {
                                          id: 'users',
                                          label: (
                                              <TranslatedString id={'users'} />
                                          ),
                                          icon: 'people',
                                          to: isAdmin
                                              ? Routes.USERS
                                              : routeWithServiceId(
                                                    Routes.SERVICE_USERS,
                                                    serviceId
                                                )
                                      },

                                      //only show meetings menu point if meetings are enabled
                                      ...(meetingsEnabled
                                          ? [
                                                {
                                                    id: 'meetings',
                                                    label: (
                                                        <TranslatedString
                                                            id={'meetings'}
                                                        />
                                                    ),
                                                    icon: (
                                                        <i className="menu-icon fas fa-calendar-alt " />
                                                    ),
                                                    to: isAdmin
                                                        ? Routes.MEETINGS
                                                        : routeWithServiceId(
                                                              Routes.SERVICE_MEETINGS,
                                                              serviceId
                                                          )
                                                }
                                            ]
                                          : []),
                                      {
                                          id: 'apiKeys',
                                          label: (
                                              <TranslatedString
                                                  id={'apiKeys'}
                                              />
                                          ),
                                          icon: 'vpn_key',
                                          to: Routes.API_KEYS
                                      }
                                  ]
                              },
                              ...(sessionsEnabled
                                  ? [
                                        {
                                            label: (
                                                <TranslatedString
                                                    id={'analytics'}
                                                />
                                            ),
                                            icon: 'show_chart',
                                            items: [
                                                {
                                                    id: 'sessions',
                                                    label: (
                                                        <TranslatedString
                                                            id={'sessions'}
                                                        />
                                                    ),
                                                    icon: (
                                                        <i className="menu-icon fas fa-handshake" />
                                                    ),
                                                    to: isAdmin
                                                        ? Routes.SESSIONS
                                                        : routeWithServiceId(
                                                              Routes.SERVICE_SESSIONS,
                                                              serviceId
                                                          )
                                                }
                                            ]
                                        }
                                    ]
                                  : [])
                          ]
                        : []),
                    {
                        label: <TranslatedString id={'sysAdmin'} />,
                        icon: 'build',
                        items: [
                            {
                                id: 'adminUsers',
                                label: <TranslatedString id={'adminUser'} />,
                                icon: (
                                    <i className="menu-icon fas fa-user-shield" />
                                ),
                                to: Routes.ADMIN_USERS
                            },
                            {
                                id: 'services',
                                label: <TranslatedString id={'services'} />,
                                icon: 'settings',
                                to: Routes.SERVICES
                            }
                        ]
                    }
                ];

                menuModel = menuItems
                    .map(menuItem => {
                        let children;
                        if (menuItem.hasOwnProperty('items')) {
                            children = menuItem.items.filter(child =>
                                hasMenuAccess(roles, child.id)
                            );
                        }
                        if (children.length) {
                            menuItem.items = children;
                            return menuItem;
                        } else {
                            return undefined;
                        }
                    })
                    .filter(menuItem => menuItem);
                // ----------------------------------------

                // ---------- compute routing for current user ----------
                // change route access rules depending on meetingsEnabled, sessionsEnabled
                // set default route for non-admin users
                if (isAdmin) {
                    if (sessionsEnabled) {
                        sessionRoute = Sessions;
                    } else {
                        sessionRoute = Routes.SERVICES;
                    }

                    if (meetingsEnabled) {
                        meetingRoute = Meetings;
                    } else {
                        meetingRoute = Routes.SERVICES;
                    }

                    setRouteAccess(
                        true,
                        'MEETINGS',
                        meetingsEnabled
                            ? ['admin', 'restricted-admin']
                            : 'redirect'
                    );
                    setRouteAccess(
                        true,
                        'SESSIONS',
                        sessionsEnabled
                            ? ['admin', 'restricted-admin']
                            : 'redirect'
                    );
                } else {
                    if (sessionsEnabled) {
                        sessionRoute = Sessions;
                    } else {
                        sessionRoute = routeWithServiceId(
                            Routes.SERVICE_MEETINGS,
                            serviceId
                        );
                    }

                    if (meetingsEnabled) {
                        meetingRoute = Meetings;
                    } else {
                        if (roles.includes('service')) {
                            meetingRoute = routeWithServiceId(
                                Routes.SERVICE_USERS,
                                serviceId
                            );
                        } else {
                            meetingRoute = routeWithServiceId(
                                Routes.SERVICE_LOGIN,
                                serviceId
                            );
                            noContent = true;
                        }
                    }

                    if (noContent) {
                        serviceLoginRoute = NoContentPage;
                    } else {
                        if (roles.includes('service')) {
                            serviceLoginRoute = routeWithServiceId(
                                Routes.SERVICE_USERS,
                                serviceId
                            );
                        } else if (roles.includes('meeting')) {
                            serviceLoginRoute = routeWithServiceId(
                                Routes.SERVICE_MEETINGS,
                                serviceId
                            );
                        } else {
                            serviceLoginRoute = routeWithServiceId(
                                Routes.SERVICE_SESSIONS,
                                serviceId
                            );
                        }
                    }

                    setRouteAccess(
                        false,
                        'SERVICE_MEETINGS',
                        meetingsEnabled ? ['meeting'] : 'redirect'
                    );
                    setRouteAccess(
                        false,
                        'SERVICE_SESSIONS',
                        sessionsEnabled ? ['analytics'] : 'redirect'
                    );
                    setRouteAccess(
                        false,
                        'SERVICE_LOGIN',
                        noContent
                            ? ['service', 'adviser', 'meeting', 'analytics']
                            : 'redirect'
                    );
                }

                const routeToPageMapping = {
                    admin: {
                        // Routes.SERVICES is the default route

                        HOME: Routes.SERVICES,

                        // service routes
                        SERVICE_LOGIN: Routes.SERVICES,
                        SERVICE_USERS: Routes.USERS,
                        SERVICE_MEETINGS: Routes.MEETINGS,
                        SERVICE_SESSIONS: Routes.SESSIONS,

                        // admin routes
                        ADMIN_LOGIN: Routes.SERVICES,

                        // only add administration/analytics routes if there is a service
                        ...(hasService && {
                            USERS: Users,
                            MEETINGS: meetingRoute,
                            API_KEYS: ApiKeys,
                            SESSIONS: sessionRoute
                        }),

                        ADMIN_USERS: AdminUsers,
                        SERVICES: Services
                    },
                    service: {
                        //Route.SERVICE_SESSIONS is the default route

                        HOME: routeWithServiceId(
                            Routes.SERVICE_SESSIONS,
                            serviceId
                        ),

                        // service routes
                        SERVICE_LOGIN: serviceLoginRoute,
                        SERVICE_USERS: Users,
                        SERVICE_MEETINGS: meetingRoute,
                        SERVICE_SESSIONS: sessionRoute,

                        // admin routes
                        ADMIN_LOGIN: routeWithServiceId(
                            Routes.SERVICE_SESSIONS,
                            serviceId
                        ),
                        //ADMIN_LOGIN: '/TEST',
                        USERS: routeWithServiceId(
                            Routes.SERVICE_USERS,
                            serviceId
                        ),
                        MEETINGS: routeWithServiceId(
                            Routes.SERVICE_MEETINGS,
                            serviceId
                        ),
                        API_KEYS: ApiKeys,
                        SESSIONS: routeWithServiceId(
                            Routes.SERVICE_SESSIONS,
                            serviceId
                        ),
                        ADMIN_USERS: AdminUsers,
                        SERVICES: Services
                    }
                };

                routes = Object.keys(Routes)
                    .map((route, key) => {
                        const routeMapping = isAdmin
                            ? routeToPageMapping.admin
                            : routeToPageMapping.service;

                        if (routeMapping[route]) {
                            const routeAccess = hasRouteAccess(
                                isAdmin,
                                roles,
                                route
                            );

                            switch (routeAccess) {
                                case 'hasAccess':
                                    return (
                                        <Route
                                            path={Routes[route]}
                                            exact
                                            component={routeMapping[route]}
                                            key={key}
                                        />
                                    );
                                case 'noAccess':
                                    return (
                                        <Route
                                            path={Routes[route]}
                                            exact
                                            render={() => (
                                                <ErrorPage error={401} />
                                            )}
                                            key={key}
                                        />
                                    );
                                case 'redirect':
                                    return (
                                        <Route
                                            path={Routes[route]}
                                            exact
                                            render={() => {
                                                return (
                                                    <Redirect
                                                        to={routeMapping[route]}
                                                    />
                                                );
                                            }}
                                            key={key}
                                        />
                                    );
                                default:
                            }
                        }
                        return undefined;
                    })
                    .filter(route => route);
                routes.push(
                    <Route
                        render={() => <ErrorPage error={404} />}
                        key={routes.length}
                    />
                );
                contentPages = <Switch>{routes}</Switch>;
                // ----------------------------------------

                return {
                    currentService: hasService ? props.service : undefined,
                    currentUser: props.user,
                    hasService: hasService,
                    menuModel: menuModel,
                    contentPages: contentPages
                };
            }
        }

        return null;
    }

    renderLogin() {
        return (
            <Switch>
                {/*redirect all admin routes to the admin login*/}
                <Route
                    path={[
                        Routes.ADMIN_LOGIN,
                        Routes.USERS,
                        Routes.MEETINGS,
                        Routes.API_KEYS,
                        Routes.SESSIONS,
                        Routes.ADMIN_USERS,
                        Routes.SERVICES
                    ]}
                    exact
                    render={() => <LoginPage loginType={'admin'} />}
                />
                {/*redirect all service routes to the service login*/}
                <Route
                    path={[
                        Routes.SERVICE_LOGIN,
                        Routes.SERVICE_USERS,
                        Routes.SERVICE_MEETINGS,
                        Routes.SERVICE_SESSIONS
                    ]}
                    exact
                    render={props => (
                        <LoginPage
                            loginType={'service'}
                            serviceId={props.match.params.serviceId}
                        />
                    )}
                />
                {/*redirect the home route and all other unknown routes to the service input page*/}
                <Route path={Routes.HOME} exact component={ServiceInputPage} />
                <Route render={() => <Redirect to={Routes.HOME} />} />
            </Switch>
        );
    }

    renderContentPages() {
        return (
            <React.Fragment>
                <SerenityLayout
                    menuModel={this.state.menuModel}
                    contentPages={this.state.contentPages}
                    showTopbar={true}
                    showBreadcrumb={true}
                    showFooter={false}
                />

                <CustomGrowl />

                <LogoutDialog
                    visible={this.props.logoutConfirmationNeeded}
                    header={'logoutDialogHeader'}
                    dialog={'logoutDialog'}
                    user={this.props.user}
                    onLogoutConfirm={this.props.logout}
                    onLogoutCancel={this.props.cancelLogout}
                />
            </React.Fragment>
        );
    }

    render() {
        if (this.props.reLoginChecked) {
            if (!this.props.authenticated) {
                return this.renderLogin();
            } else {
                return this.renderContentPages();
            }
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        reLoginChecked: state.auth.reLoginChecked,
        authenticated: state.auth.authenticated,
        logoutConfirmationNeeded: state.auth.logoutConfirmationNeeded,
        user: state.auth.user,
        service: state.auth.service
    };
};

const mapDispatchToProps = {
    checkReLogin,
    logout,
    cancelLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(Manager);
