import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';

import { login, requestPublicServiceInfo } from './actions';

import logo from '../../assets/images/adiaLive.svg';

import '../../css/features/LoginPage.scss';
import classNames from 'classnames';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Message } from 'primereact/message';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

export class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loginType: this.props.loginType,
            serviceId: this.props.serviceId,

            username: '',
            password: '',
            captchaResponse: '',

            invalidUsername: false,
            invalidPassword: false,
            invalidCaptcha: false
        };
    }

    componentDidMount() {
        if (this.state.loginType === 'service') {
            const keycloakLogin = () =>
                this.props.login({
                    loginType: this.state.loginType,
                    serviceId: this.state.serviceId,
                    credentials: undefined
                });

            this.props.requestPublicServiceInfo(
                this.state.serviceId,
                keycloakLogin
            );
        }
    }

    /**
     * onClick event handler for login button
     * @returns {Function}
     */
    handleLoginClick = () => e => {
        e.preventDefault();

        const credentials = {
            username: this.state.username,
            password: this.state.password,
            captcha: this.state.captchaResponse
        };

        const invalidInputFields = {
            invalidUsername: credentials.username === '',
            invalidPassword: credentials.password === '',
            invalidCaptcha: !!(this.props.captcha && credentials.captcha === '')
        };
        this.setState(invalidInputFields);

        if (
            !Object.keys(invalidInputFields).reduce(
                (acc, curr) => acc || invalidInputFields[curr],
                false
            )
        ) {
            // no invalid inputs; proceed with login
            this.props.login({
                loginType: this.state.loginType,
                serviceId: this.state.serviceId,
                credentials: credentials
            });
        }
    };

    /**
     * onChange event handler for login form fields
     * @param fieldId
     * @returns {Function}
     */
    handleOnChange = fieldId => e => {
        this.setState({ [fieldId]: e.target.value });
    };

    errorMessageFromError = error => {
        if (error) {
            switch (error.errorNo) {
                // authorization errors
                case 40100:
                    return 'notAuthorized';
                case 40192:
                    return 'authInvalidIp';
                case 40193:
                    return 'authInvalidUser';
                case 40300:
                    return 'accessDenied';

                // login errors
                case 40194:
                    return 'authInvalidCaptcha';
                case 40195:
                    return 'authInvalidCredentials';

                default:
                    if (error.internalError) {
                        return 'connectionError';
                    }
                    return 'serverError';
            }
        }
    };

    renderLoginForm(loginType) {
        const className = classNames('login-panel p-fluid', {
            'login-panel-w-captcha': this.props.captcha
        });

        return (
            <div className="ExampleProject">
                <div className="login-body">
                    <div className={className}>
                        <div className="login-panel-header">
                            <img src={logo} alt="Adia Live" />
                        </div>
                        <form
                            className="login-panel-content"
                            onSubmit={this.handleLoginClick()}
                        >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <h1>
                                        <TranslatedString
                                            id={'applicationName'}
                                        />
                                    </h1>

                                    <h1>
                                        {loginType +
                                            ' ' +
                                            getTranslatedString(
                                                this.props.language,
                                                'loginFormHeader'
                                            )}
                                    </h1>
                                </div>

                                {this.props.error && (
                                    <div className="p-col-12">
                                        <Message
                                            severity="warn"
                                            text={getTranslatedString(
                                                this.props.language,
                                                this.errorMessageFromError(
                                                    this.props.error
                                                ) || ''
                                            )}
                                        />
                                    </div>
                                )}

                                <div className="p-col-12">
                                    <span className="md-inputfield">
                                        <InputText
                                            className={classNames({
                                                'p-error': this.state
                                                    .invalidUsername
                                            })}
                                            value={this.state.username}
                                            onChange={this.handleOnChange(
                                                'username'
                                            )}
                                        />
                                        <label>
                                            <TranslatedString id={'username'} />
                                        </label>
                                    </span>
                                </div>

                                <div className="p-col-12">
                                    <span className="md-inputfield">
                                        <Password
                                            className={classNames({
                                                'p-error': this.state
                                                    .invalidPassword
                                            })}
                                            value={this.state.password}
                                            onChange={this.handleOnChange(
                                                'password'
                                            )}
                                            feedback={false}
                                        />
                                        <label>
                                            <TranslatedString id={'password'} />
                                        </label>
                                    </span>
                                </div>

                                {this.props.captcha && this.renderCaptcha()}

                                <div className="p-col-12">
                                    <Button
                                        label={getTranslatedString(
                                            this.props.language,
                                            'signIn'
                                        )}
                                        icon="pi-md-person"
                                        type="submit"
                                        onClick={this.handleLoginClick()}
                                        disabled={this.props.isLoggingIn}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    renderCaptcha() {
        return (
            <React.Fragment>
                <div className="p-col-12">
                    <Captcha string={this.props.captcha} />
                </div>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames({
                                'p-error': this.state.invalidCaptcha
                            })}
                            value={this.state.captchaResponse}
                            onChange={this.handleOnChange('captchaResponse')}
                        />
                        <label>
                            <TranslatedString id={'captcha'} />
                        </label>
                    </span>
                </div>
            </React.Fragment>
        );
    }

    render() {
        if (this.state.loginType === 'admin') {
            return this.renderLoginForm('admin');
        } else {
            if (
                this.props.publicServiceInfo &&
                this.props.publicServiceInfo.authMethod !== 'keycloak'
            ) {
                return this.renderLoginForm('service');
            } else {
                // waiting for public service info or logging in if authMethod is 'keycloak'
                return null;
            }
        }
    }
}

class Captcha extends PureComponent {
    render() {
        const svgString = encodeURIComponent(this.props.string);
        return (
            <img src={`data:image/svg+xml;utf8,${svgString}`} alt="Captcha" />
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        captcha: state.auth.captcha,
        error: state.auth.error,
        publicServiceInfo: state.auth.publicServiceInfo,

        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    login,
    requestPublicServiceInfo
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
