import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';

import {
    prepareRecordingsDownload,
    prepareRecordingsDownloadDenied,
    requestSessions,
    resetExportData,
    resetRecordingsDownload,
    resetSessionsPage,
    startEditingSession,
    stopEditingSession
} from './actions';

import '../../css/features/Sessions.scss';

import { getTranslatedString } from '../base/i18n/translations';
import { log, parseDate, parseTime } from '../base/util/helpers';
import authHelper from '../base/util/authHelper';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TranslatedString from '../base/i18n/TranslatedString';
import { MultiSelect } from 'primereact/multiselect';
import { Accordion, AccordionTab } from '../base/CustomAccordion';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { isAdminUser } from '../base/util/accessControl';
import { SplitButton } from 'primereact/splitbutton';

export class Sessions extends Component {
    constructor(props) {
        super(props);

        this.rows = 25;

        this.state = {
            filters: [],
            showExportDialog: false,
            exportError: false,
            exportSessions: false,
            exportType: 'default',
            showDownloadRecordingsDialog: false
        };

        this.retryTimeout = 10000;
        this.prepareDownloadRequestTimeout = null;

        this.handleFilterSelected = this.handleFilterSelected.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
        this.handleExportSelect = this.handleExportSelect.bind(this);
        this.handleExportConfirm = this.handleExportConfirm.bind(this);
        this.handleExportCancel = this.handleExportCancel.bind(this);
        this.handleDownloadRecordingsClick = this.handleDownloadRecordingsClick.bind(
            this
        );
        this.handleCancelRecordingsDownload = this.handleCancelRecordingsDownload.bind(
            this
        );
    }

    componentDidMount() {
        this.props.requestSessions({
            range: { startIndex: 0, endIndex: this.rows - 1 },
            sort: ['-_id']
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.prepareDownloadState) {
            if (this.props.downloadError || this.props.downloadCancelled) {
                if (this.prepareDownloadRequestTimeout) {
                    clearTimeout(this.prepareDownloadRequestTimeout);
                    this.prepareDownloadRequestTimeout = null;
                }
            } else {
                if (this.props.prepareDownloadState.progress < 100) {
                    if (this.prepareDownloadRequestTimeout === null) {
                        this.prepareDownloadRequestTimeout = setTimeout(() => {
                            clearTimeout(this.prepareDownloadRequestTimeout);
                            this.prepareDownloadRequestTimeout = null;

                            this.props.prepareRecordingsDownload(
                                this.props.sessionToEdit._id
                            );
                        }, ((100 - this.props.prepareDownloadState.progress) / 100) * this.retryTimeout);
                    }
                } else {
                    clearTimeout(this.prepareDownloadRequestTimeout);
                    this.prepareDownloadRequestTimeout = null;
                }
            }
        }

        // reset sessions page if the service was changed
        if (prevProps.service !== this.props.service && this.props.service) {
            if (this.prepareDownloadRequestTimeout) {
                clearTimeout(this.prepareDownloadRequestTimeout);
                this.prepareDownloadRequestTimeout = null;
            }

            this.props.resetSessionsPage(true, {
                range: { startIndex: 0, endIndex: this.rows - 1 },
                filters: this.state.filters,
                sort: ['-_id']
            });

            this.setState({
                showExportDialog: false,
                exportError: false,
                exportSessions: false,
                showDownloadRecordingsDialog: false
            });
        }
    }

    componentWillUnmount() {
        this.props.resetSessionsPage();
    }

    handleFilterSelected(filters) {
        this.setState({ filters: filters });
        this.props.requestSessions({
            range: { startIndex: 0, endIndex: this.rows - 1 },
            filters: filters,
            sort: ['-_id']
        });
    }

    handlePageClick(event) {
        this.props.requestSessions({
            range: {
                startIndex: event.first,
                endIndex: event.first + this.rows - 1
            },
            filters: this.state.filters,
            sort: ['-_id']
        });
    }

    handleEditClick(sessionId) {
        if (
            this.props.isEditing &&
            sessionId === this.props.sessionToEdit._id
        ) {
            this.props.stopEditingSession();
        } else {
            // find session object with matching sessionId to edit
            const session = this.props.sessions.find(item => {
                return item._id === sessionId;
            });

            if (session) {
                this.props.startEditingSession(session);
            }
        }
    }

    handleExportClick() {
        // make sure to clear previously requested export data (if there is any)
        if (this.props.exportData) {
            this.props.resetExportData();
        }
        this.setState({
            showExportDialog: true,
            exportError: false,
            exportSessions: false
        });
    }

    handleExportSelect(startDate, endDate, exportType = 'default') {
        // setup fields and filter parameters for the session request call to get the export data
        const fields = exportType === 'default' ? ['-log'] : [];
        const sort = ['-_id'];
        const filter = [
            `startTime[date-gte]=${startDate.setHours(0, 0, 0, 0)}`,
            `startTime[date-lte]=${endDate.setHours(23, 59, 59, 999)}`
        ];
        const range = {
            startIndex: 0,
            endIndex: this.props.contentRange
                ? this.props.contentRange.totalItems
                : undefined
        };

        this.props.requestSessions(
            { fields: fields, filters: filter, sort: sort, range: range },
            true
        );

        this.setState({ exportType: exportType });
    }

    handleExportConfirm() {
        // check that the export data received from the server is defined and has the correct format; otherwise, show an error message in the dialog
        if (this.props.exportData && Array.isArray(this.props.exportData)) {
            this.setState({ showExportDialog: false, exportSessions: true });
        } else {
            this.setState({ exportError: true });
        }
    }

    handleExportCancel() {
        // cleanup: clear the requested export data when cancelling/finishing the excel export
        if (this.props.exportData) {
            this.props.resetExportData();
        }
        this.setState({
            showExportDialog: false,
            exportError: false,
            exportSessions: false
        });
    }

    handleDownloadRecordingsClick(audioOnly) {
        const user = this.props.user;
        if (
            isAdminUser(user) ||
            (user &&
                Array.isArray(user.roles) &&
                user.roles.includes('analytics'))
        ) {
            this.setState({
                showDownloadRecordingsDialog: true
            });

            this.props.prepareRecordingsDownload(
                this.props.sessionToEdit._id,
                audioOnly
            );
        } else {
            this.props.prepareRecordingsDownloadDenied();
        }
    }

    handleCancelRecordingsDownload() {
        if (this.prepareDownloadRequestTimeout) {
            clearTimeout(this.prepareDownloadRequestTimeout);
            this.prepareDownloadRequestTimeout = null;
        }

        this.props.resetRecordingsDownload();
        this.setState({
            showDownloadRecordingsDialog: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8">
                        <SessionsTable
                            isLoading={this.props.isLoading}
                            editedRowId={
                                this.props.isEditing
                                    ? this.props.sessionToEdit._id
                                    : undefined
                            }
                            sessions={this.props.sessions}
                            language={this.props.language}
                            rows={this.rows}
                            contentRange={this.props.contentRange}
                            onFilterSelected={this.handleFilterSelected}
                            onPageClick={this.handlePageClick}
                            onEditClick={this.handleEditClick}
                            onExportClick={this.handleExportClick}
                        />
                    </div>

                    <div className="p-col-12 p-xl-4">
                        <SessionsInfo
                            isEditing={this.props.isEditing}
                            sessionInfo={this.props.sessionToEdit}
                            onDownloadRecordingsClick={
                                this.handleDownloadRecordingsClick
                            }
                            language={this.props.language}
                        />
                    </div>
                </div>

                {this.state.showExportDialog && (
                    <ExcelExportDialog
                        visible={this.state.showExportDialog}
                        error={this.state.exportError}
                        isLoading={this.props.isLoading}
                        exportData={this.props.exportData}
                        language={this.props.language}
                        user={this.props.user}
                        header={'excelExportHeader'}
                        onExportDateSelect={this.handleExportSelect}
                        onExportConfirm={this.handleExportConfirm}
                        onExportCancel={this.handleExportCancel}
                    />
                )}

                {this.state.exportSessions && (
                    <ExcelExport
                        exportData={this.props.exportData}
                        exportType={this.state.exportType}
                        service={this.props.service}
                        onExportStarted={this.handleExportCancel}
                    />
                )}

                {this.state.showDownloadRecordingsDialog && (
                    <DownloadRecordingsDialog
                        visible={this.state.showDownloadRecordingsDialog}
                        header={'sessionDownloadRecordings'}
                        language={this.props.language}
                        error={this.props.downloadError}
                        prepareDownloadState={this.props.prepareDownloadState}
                        downloadLink={this.props.downloadLink}
                        onDownloadCancel={this.handleCancelRecordingsDownload}
                    />
                )}
            </React.Fragment>
        );
    }
}

class SessionsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: []
        };

        // define filters for the session table here
        this.filters = {
            sessionFilterHasRecordings: 'hasRecordings=true'
        };
    }

    handleOnFilterChange = () => e => {
        this.props.onFilterSelected(
            e.value.map(filterName => this.filters[filterName])
        );
        this.setState({ filters: e.value });
    };

    handleOnExportButtonClick = () => e => {
        e.preventDefault();
        this.props.onExportClick();
    };

    render() {
        const sessionTableEntries = this.props.sessions.map((item, key) => {
            let session = { ...item };

            const date = parseDate(session.startTime);

            session.date = `${date.day}.${date.month}.${date.year}`;
            session.start = `${date.hours}:${date.minutes}:${date.seconds}`;
            session.duration = parseTime(session.duration);

            let advisers = (session.meeting.participants || []).reduce(
                (adviserArr, participant) => {
                    if (participant.isAdviser) {
                        adviserArr.push(`${participant.name}`);
                    }
                    return adviserArr;
                },
                []
            );
            session.advisers = advisers.join(', ');

            session.participants = session.meeting.participants.length;

            if (session.hasOwnProperty('hasRecordings')) {
                session.hasRecordings = session.hasRecordings ? (
                    <span className="pi-md-check icon active" key={key} />
                ) : (
                    <span className="pi-md-block icon inactive" key={key} />
                );
            }

            return session;
        });

        const header = (
            <div>
                <MultiSelect
                    className="filter-selector"
                    placeholder={
                        this.state.filters.length
                            ? this.state.filters
                                  .map(filterName =>
                                      getTranslatedString(
                                          this.props.language,
                                          filterName
                                      )
                                  )
                                  .join(', ')
                            : getTranslatedString(
                                  this.props.language,
                                  'sessionFilter'
                              )
                    }
                    fixedPlaceholder={true}
                    value={this.state.filters}
                    options={[
                        {
                            label: getTranslatedString(
                                this.props.language,
                                'sessionFilterHasRecordings'
                            ),
                            value: 'sessionFilterHasRecordings'
                        }
                    ]}
                    onChange={this.handleOnFilterChange()}
                />
                <Button
                    disabled={this.props.sessions.length < 1}
                    icon="pi pi-md-file-download"
                    className="export-data-button"
                    onClick={this.handleOnExportButtonClick()}
                />
            </div>
        );

        return (
            <Card title={getTranslatedString(this.props.language, 'sessions')}>
                <DataTable
                    className="table"
                    header={header}
                    autoLayout={true}
                    responsive={true}
                    value={sessionTableEntries}
                    rows={this.props.rows}
                    rowClassName={rowData => ({
                        'row-bg':
                            rowData && rowData._id === this.props.editedRowId,
                        'row-cursor': true
                    })}
                    onRowClick={e => this.props.onEditClick(e.data._id)}
                    paginator={true}
                    paginatorPosition={'top'}
                    alwaysShowPaginator={true}
                    lazy={true}
                    totalRecords={
                        this.props.contentRange
                            ? this.props.contentRange.totalItems
                            : undefined
                    }
                    first={
                        this.props.contentRange
                            ? this.props.contentRange.currentItems.startIndex
                            : 0
                    }
                    onPage={this.props.onPageClick}
                >
                    <Column
                        className="column date-col"
                        field="date"
                        header={<TranslatedString id={'sessionTableDate'} />}
                    />
                    <Column
                        className="column start-col"
                        field="start"
                        header={
                            <TranslatedString id={'sessionTableStartTime'} />
                        }
                    />
                    <Column
                        className="column duration-col"
                        field="duration"
                        header={
                            <TranslatedString id={'sessionTableDuration'} />
                        }
                    />
                    <Column
                        className="column adviser-col"
                        field="advisers"
                        header={<TranslatedString id={'sessionTableAdviser'} />}
                    />
                    <Column
                        className="column participants-col"
                        field="participants"
                        header={
                            <TranslatedString id={'sessionTableParticipants'} />
                        }
                    />
                    <Column
                        className="column recordings-col"
                        field="hasRecordings"
                        header={
                            <TranslatedString id={'sessionTableRecordings'} />
                        }
                    />
                </DataTable>
            </Card>
        );
    }
}

class SessionsInfo extends Component {
    handleDownloadRecordingsClick = audioOnly => () => {
        this.props.onDownloadRecordingsClick(audioOnly);
    };

    renderMeetingInfoPanel() {
        const meetingInfo = this.props.sessionInfo.meeting;
        const date = parseDate(meetingInfo.date);
        const tableEntries = [
            {
                rowName: <TranslatedString id={'sessionMeetingInfoDate'} />,
                rowData: `${date.day}.${date.month}.${date.year}, ${date.hours}:${date.minutes}`
            },
            {
                rowName: <TranslatedString id={'sessionMeetingInfoTitle'} />,
                rowData: meetingInfo.title
            },
            {
                rowName: (
                    <TranslatedString id={'sessionMeetingInfoDescription'} />
                ),
                rowData: meetingInfo.description
            },
            {
                rowName: <TranslatedString id={'sessionMeetingInfoDuration'} />,
                rowData: `${meetingInfo.durationInMinutes} min`
            }
        ];

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'sessionMeetingInfo'
                )}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <br />
                        <DataTable
                            tableClassName="info-table"
                            value={tableEntries}
                            autoLayout={true}
                            responsive={true}
                        >
                            <Column
                                headerClassName="column-header"
                                bodyClassName="column-body row-header meeting-info"
                                className="column"
                                field="rowName"
                            />
                            <Column
                                headerClassName="column-header"
                                bodyClassName="column-body"
                                className="column"
                                field="rowData"
                            />
                        </DataTable>
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderParticipantInfoPanel() {
        const participants = (
            this.props.sessionInfo.meeting.participants || []
        ).map((participant, index) => (
            <React.Fragment key={index}>
                {index !== 0 && <br />}
                <MeetingParticipant participant={participant} key={index} />
            </React.Fragment>
        ));

        return (
            <AccordionTab
                header={getTranslatedString(
                    this.props.language,
                    'sessionParticipantInfo'
                )}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <br />
                        {participants}
                    </div>
                </div>
            </AccordionTab>
        );
    }

    renderLogPanel() {
        const log = this.props.sessionInfo.log || [];
        let logEntries;

        if (log.length) {
            logEntries = log.map((logEntry, key) => {
                const date = parseDate(logEntry.dateTime);
                const timestamp = `${date.hours}:${date.minutes}:${date.seconds}`;

                return (
                    <React.Fragment key={key}>
                        <div className="log-entry">
                            <span className="log-entry-timestamp">
                                {timestamp}
                            </span>
                            {logEntry.issuer.isAdviser ? (
                                <span className="pi-md-verified-user log-entry-adviser" />
                            ) : (
                                <span className="log-entry-user" />
                            )}
                            <span className="log-entry-username">
                                {logEntry.issuer.info.name || ''}
                            </span>
                            <span>:</span>
                            <span className="log-entry-message">
                                {logEntry.message || ''}
                            </span>
                        </div>
                    </React.Fragment>
                );
            });
        }

        return (
            <AccordionTab
                header={getTranslatedString(this.props.language, 'sessionLog')}
            >
                <br />
                {logEntries ? (
                    <ScrollPanel className="scroll-panel">
                        <div className="p-grid scroll-panel-content">
                            <div className="p-col-12">{logEntries}</div>
                        </div>
                    </ScrollPanel>
                ) : (
                    <TranslatedString id={'sessionInfoLogUnavailableMessage'} />
                )}
            </AccordionTab>
        );
    }

    render() {
        let meetingInfoPanel, participantInfoPanel, logPanel;
        if (this.props.isEditing) {
            meetingInfoPanel = this.renderMeetingInfoPanel();
            participantInfoPanel = this.renderParticipantInfoPanel();
            logPanel = this.renderLogPanel();
        }

        return (
            <Card
                title={getTranslatedString(this.props.language, 'sessionInfo')}
            >
                <div className="p-grid">
                    {this.props.isEditing ? (
                        <React.Fragment>
                            <div className="p-col-12">
                                <Accordion
                                    className="accordion"
                                    multiple={true}
                                    activeIndex={[0]}
                                >
                                    {meetingInfoPanel}
                                    {participantInfoPanel}
                                    {logPanel}
                                </Accordion>
                            </div>
                            <div className="p-col-12">
                                <SplitButton
                                    label={getTranslatedString(
                                        this.props.language,
                                        'sessionDownloadRecordings'
                                    )}
                                    model={[
                                        {
                                            label: getTranslatedString(
                                                this.props.language,
                                                'sessionDownloadAudioRecordings'
                                            ),
                                            command: this.handleDownloadRecordingsClick(
                                                true
                                            )
                                        }
                                    ]}
                                    onClick={this.handleDownloadRecordingsClick(
                                        false
                                    )}
                                    disabled={
                                        this.props.isLoading ||
                                        (this.props.sessionInfo.hasOwnProperty(
                                            'hasRecordings'
                                        ) &&
                                            !this.props.sessionInfo
                                                .hasRecordings)
                                    }
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="p-col-12">
                            <br />
                            <TranslatedString id={'sessionInfoMessage'} />
                        </div>
                    )}
                </div>
            </Card>
        );
    }
}

class MeetingParticipant extends Component {
    render() {
        const participant = this.props.participant;
        const isAdviser = participant.isAdviser ? (
            <TranslatedString id={'yes'} />
        ) : (
            <TranslatedString id={'no'} />
        );
        const tableEntries = [
            {
                rowName: <TranslatedString id={'sessionParticipantInfoName'} />,
                rowData: participant.name
            },
            {
                rowName: (
                    <TranslatedString id={'sessionParticipantInfoEmail'} />
                ),
                rowData: participant.email
            },
            {
                rowName: (
                    <TranslatedString id={'sessionParticipantInfoIsAdviser'} />
                ),
                rowData: isAdviser
            }
        ];

        return (
            <DataTable
                tableClassName="info-table"
                value={tableEntries}
                autoLayout={true}
                responsive={true}
            >
                <Column
                    headerClassName="column-header"
                    bodyClassName="column-body row-header participant-info"
                    className="column"
                    field="rowName"
                />
                <Column
                    headerClassName="column-header"
                    bodyClassName="column-body"
                    className="column"
                    field="rowData"
                />
            </DataTable>
        );
    }
}

class ExcelExportDialog extends Component {
    constructor(props) {
        super(props);

        const now = new Date();
        let startDate = new Date(now.getTime());
        startDate = new Date(startDate.setMonth(now.getMonth() - 3));

        this.state = {
            step: 'select',
            startDate: startDate,
            endDate: now,
            exportType: 'default'
        };
    }

    handleExportTypeChange = () => e => {
        this.setState({ exportType: e.target.value });
    };

    handleOnDateChange = field => e => {
        this.setState({ [field]: e.value });
    };

    handleExportDateSelect = () => () => {
        this.props.onExportDateSelect(
            this.state.startDate,
            this.state.endDate,
            this.state.exportType
        );
        this.setState({ step: 'download' });
    };

    renderExportDateSelection() {
        const { user, language } = this.props;
        const exportDialog = (
            <div className="p-grid">
                <div className="p-col-12">
                    <TranslatedString id={'sessionExportSelectDateMessage'} />
                </div>

                <div className="p-col-12">
                    <div className="p-inputgroup">
                        <Calendar
                            inputClassName="calendar"
                            value={this.state.startDate}
                            dateFormat="dd.mm.yy"
                            readOnlyInput
                            locale={getTranslatedString(language, 'calendar')}
                            onChange={this.handleOnDateChange('startDate')}
                        />
                        <span className="p-inputgroup-addon">
                            <TranslatedString id={'sessionExportSelectUntil'} />
                        </span>
                        <Calendar
                            inputClassName="calendar"
                            value={this.state.endDate}
                            dateFormat="dd.mm.yy"
                            readOnlyInput
                            locale={getTranslatedString(language, 'calendar')}
                            onChange={this.handleOnDateChange('endDate')}
                        />
                    </div>
                </div>

                <div className="p-col-12 max-data-message">
                    <TranslatedString
                        id={'sessionExportSelectMaxDataMessage'}
                    />
                </div>

                {(isAdminUser(user) ||
                    (user &&
                        Array.isArray(user.roles) &&
                        user.roles.includes('analytics'))) && (
                    <div className="p-col-12 export-type">
                        <TranslatedString id={'sessionExportType'} />
                        <Dropdown
                            className="export-type-dropdown"
                            value={this.state.exportType}
                            options={[
                                {
                                    label: 'default',
                                    value: 'default'
                                },
                                {
                                    label: 'detailed',
                                    value: 'detailed'
                                }
                            ]}
                            onChange={this.handleExportTypeChange()}
                        />
                    </div>
                )}
            </div>
        );
        const exportDialogFooter = (
            <div>
                <Button
                    className="button confirm"
                    label={getTranslatedString(this.props.language, 'confirm')}
                    icon="pi pi-check"
                    onClick={this.handleExportDateSelect()}
                />
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onExportCancel}
                />
            </div>
        );
        return { exportDialog, exportDialogFooter };
    }

    renderLoadingExportData() {
        const exportDialog = (
            <TranslatedString id={'excelExportLoadingMessage'} />
        );
        const exportDialogFooter = (
            <div>
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onExportCancel}
                />
            </div>
        );
        return { exportDialog, exportDialogFooter };
    }

    renderExportDownload() {
        const exportDialog = (
            <TranslatedString id={'excelExportDownloadMessage'} />
        );
        const exportDialogFooter = (
            <div>
                <Button
                    className="button confirm"
                    label={getTranslatedString(this.props.language, 'download')}
                    icon="pi pi-md-file-download"
                    onClick={this.props.onExportConfirm}
                />
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onExportCancel}
                />
            </div>
        );
        return { exportDialog, exportDialogFooter };
    }

    renderExportError() {
        const exportDialog = (
            <TranslatedString id={'excelExportErrorMessage'} />
        );
        const exportDialogFooter = (
            <div>
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onExportCancel}
                />
            </div>
        );
        return { exportDialog, exportDialogFooter };
    }

    render() {
        const exportDialogHeader = <TranslatedString id={this.props.header} />;
        let exportDialog, exportDialogFooter;

        if (!this.props.error) {
            if (this.state.step === 'select') {
                ({
                    exportDialog,
                    exportDialogFooter
                } = this.renderExportDateSelection());
            } else if (this.state.step === 'download') {
                if (this.props.exportData) {
                    ({
                        exportDialog,
                        exportDialogFooter
                    } = this.renderExportDownload());
                } else if (this.props.isLoading) {
                    ({
                        exportDialog,
                        exportDialogFooter
                    } = this.renderLoadingExportData());
                } else {
                    ({
                        exportDialog,
                        exportDialogFooter
                    } = this.renderExportError());
                }
            } else {
                ({
                    exportDialog,
                    exportDialogFooter
                } = this.renderExportError());
            }
        } else {
            ({ exportDialog, exportDialogFooter } = this.renderExportError());
        }

        return (
            <Dialog
                className="dialog session-export-dialog"
                visible={this.props.visible}
                closeOnEscape={true}
                closable={false}
                header={exportDialogHeader}
                footer={exportDialogFooter}
                onHide={this.props.onExportCancel}
            >
                {exportDialog}
            </Dialog>
        );
    }
}

class ExcelExport extends Component {
    componentDidMount() {
        this.props.onExportStarted();
    }

    parseSessionLog(log) {
        const participantStats = {};

        (log || []).forEach(logEntry => {
            if (logEntry.action === 'joined' || logEntry.action === 'left') {
                const userId = logEntry.issuer.id;
                if (!participantStats.hasOwnProperty(userId)) {
                    participantStats[userId] = {
                        _id: undefined,
                        name: undefined,
                        joinLeaveTimes: []
                    };
                }

                const user = participantStats[userId];
                if (logEntry.action === 'joined') {
                    if (logEntry.issuer.info) {
                        user.name = logEntry.issuer.info.name || undefined;
                        user._id = logEntry.issuer.info._id || undefined;
                    }

                    if (logEntry.dateTime) {
                        user.joinLeaveTimes.push({
                            join: logEntry.dateTime,
                            leave: undefined
                        });
                    }
                } else if (logEntry.action === 'left') {
                    if (logEntry.dateTime) {
                        const lastJoinLeaveEntry =
                            user.joinLeaveTimes[user.joinLeaveTimes.length - 1];
                        if (!lastJoinLeaveEntry) {
                            user.joinLeaveTimes.push({
                                join: undefined,
                                leave: logEntry.dateTime
                            });
                        } else {
                            lastJoinLeaveEntry.leave = logEntry.dateTime;
                        }
                    }
                }
            }
        });

        return participantStats;
    }

    formatExportData() {
        const { exportData, exportType } = this.props;

        const data = (exportData || []).map(session => {
            const startTime = parseDate(session.startTime);
            const stopTime = parseDate(session.stopTime);
            const duration = parseTime(session.duration);
            const meetingDate = parseDate(session.meeting.date);
            const meetingDuration = parseTime(
                session.meeting.durationInMinutes * 6e4
            );

            const participantIds = [];
            const adviserIds = [];
            const adviserNames = [];
            (session.meeting.participants || []).forEach(participant => {
                participantIds.push(participant._id);

                if (participant.isAdviser) {
                    adviserIds.push(participant._id);
                    adviserNames.push(participant.name);
                }
            });

            let exportValues = [
                { value: session._id },
                {
                    value: `${startTime.day}.${startTime.month}.${startTime.year} ${startTime.hours}:${startTime.minutes}`
                },
                {
                    value: `${stopTime.day}.${stopTime.month}.${stopTime.year} ${stopTime.hours}:${stopTime.minutes}`
                },
                { value: duration },
                { value: adviserNames.join(', ') },
                { value: adviserIds.join(', ') },
                {
                    value: session.hasOwnProperty('hasRecordings')
                        ? session.hasRecordings
                            ? 'yes'
                            : 'no'
                        : ''
                },
                { value: session.meeting._id },
                {
                    value: `${meetingDate.day}.${meetingDate.month}.${meetingDate.year} ${meetingDate.hours}:${meetingDate.minutes}`
                },
                { value: session.meeting.title },
                { value: session.meeting.description },
                { value: meetingDuration },
                { value: participantIds.join(', ') }
            ];

            if (exportType === 'detailed') {
                const participantIds = [];
                const participantNames = [];
                const participantDurations = [];
                let totalParticipantDuration = 0;

                const userStats = this.parseSessionLog(session.log);

                log.debug('detailed session export', session, userStats);
                Object.values(userStats).forEach(participant => {
                    participantIds.push(participant._id);
                    participantNames.push(participant.name);

                    let duration =
                        participant.joinLeaveTimes.reduce(
                            (duration, joinLeaveEntry) => {
                                let d =
                                    new Date(joinLeaveEntry.leave).getTime() -
                                    new Date(joinLeaveEntry.join).getTime();
                                if (!isNaN(d)) {
                                    d = d / 1000;
                                }
                                return duration + d;
                            },
                            0
                        ) / 60;
                    totalParticipantDuration += duration;

                    if (!isNaN(duration)) {
                        duration = parseFloat(duration.toFixed(2));
                    }
                    participantDurations.push(duration);
                });

                if (!isNaN(totalParticipantDuration)) {
                    totalParticipantDuration = parseFloat(
                        totalParticipantDuration.toFixed(2)
                    );
                }

                exportValues = exportValues.concat([
                    { value: participantIds.join(', ') },
                    { value: participantNames.join(', ') },
                    { value: participantDurations.join(', ') },
                    { value: totalParticipantDuration.toString() }
                ]);
            }

            return exportValues;
        });

        let columns = [
            'Session ID',
            'Session Start',
            'Session End',
            'Duration',
            'Adviser Names',
            'Adviser IDs',
            'Recorded',
            'Meeting ID',
            'Planned Meeting Date',
            'Meeting Title',
            'Meeting Description',
            'Planned Meeting Duration',
            'Invited Participants'
        ];
        if (exportType === 'detailed') {
            columns = columns.concat([
                "Joined Participants' IDs",
                "Joined Participants' Names",
                "Joined Participants' Durations in Meeting (min)",
                "Joined Participants' Total Duration in Meeting (min)"
            ]);
        }

        return [
            {
                columns: columns,
                data: data
            }
        ];
    }

    render() {
        const data = this.formatExportData();
        return (
            <ReactExport.ExcelFile
                hideElement={true}
                filename={`adiaLive5-${this.props.service._id}-Sessions`}
            >
                <ReactExport.ExcelFile.ExcelSheet
                    dataSet={data}
                    name="Sessions"
                />
            </ReactExport.ExcelFile>
        );
    }
}

class DownloadRecordingsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 'init',
            error: undefined
        };

        this.nextTimeout = null;

        this.download = React.createRef();
        this.token = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('message', this.handleIFrameMessage);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.prepareDownloadState &&
            this.props.prepareDownloadState.progress === 100 &&
            this.props.downloadLink &&
            this.state.step === 'init'
        ) {
            this.nextTimeout = setTimeout(() => {
                this.setState({ step: 'ready' });
            }, 1000);
        }
    }

    componentWillUnmount() {
        if (this.nextTimeout) {
            clearTimeout(this.nextTimeout);
        }
        window.removeEventListener('message', this.handleIFrameMessage);
    }

    handleDownloadStartClick = () => () => {
        this.download.current.action = this.props.downloadLink;

        /* special case for IE (download in iframe not possible!) */
        const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
        this.download.current.target = isIE ? '_blank' : 'downloadResult';
        this.token.current.value = authHelper.getToken();
        this.download.current.submit();
        this.setState({ step: 'downloading' });
    };

    handleIFrameMessage = e => {
        if (e.data.source === 'download') {
            log.debug(e.data.payload, typeof e.data.payload);
            this.setState({ error: e.data.payload });
        }
    };

    renderRecordingsPrepare() {
        const progressValue = Math.min(
            this.props.prepareDownloadState.progress,
            100
        );
        const downloadRecordingsDialog = (
            <React.Fragment>
                <TranslatedString id={'sessionPrepareRecordingsMessage'} />
                <ProgressBar
                    className="download-recordings-progressbar"
                    value={progressValue}
                    showValue={false}
                />
            </React.Fragment>
        );
        const downloadRecordingsFooter = (
            <div>
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onDownloadCancel}
                />
            </div>
        );
        return { downloadRecordingsDialog, downloadRecordingsFooter };
    }

    renderRecordingsDownload() {
        const downloadRecordingsDialog = (
            <TranslatedString id={'sessionDownloadMessage'} />
        );
        const downloadRecordingsFooter = (
            <div>
                <Button
                    className="button confirm"
                    label={getTranslatedString(this.props.language, 'download')}
                    icon="pi pi-md-file-download"
                    onClick={this.handleDownloadStartClick()}
                />
                <Button
                    className="button cancel"
                    label={getTranslatedString(this.props.language, 'cancel')}
                    icon="pi pi-times"
                    onClick={this.props.onDownloadCancel}
                />
            </div>
        );
        return { downloadRecordingsDialog, downloadRecordingsFooter };
    }

    renderRecordingsDownloading() {
        const downloadRecordingsDialog = (
            <TranslatedString id={'sessionDownloadOngoingMessage'} />
        );
        const downloadRecordingsFooter = (
            <div>
                <Button
                    className="button neutral"
                    label={getTranslatedString(this.props.language, 'ok')}
                    onClick={this.props.onDownloadCancel}
                />
            </div>
        );
        return { downloadRecordingsDialog, downloadRecordingsFooter };
    }

    renderDownloadError() {
        let error = this.props.error || this.state.error;
        let errorMessage;

        log.debug(error);
        if (error.errorNo === 40450) {
            errorMessage = 'sessionDownloadNoRecordingsFound';
        } else {
            try {
                if (typeof error === 'string') {
                    // error response in html as stringified json
                    error = JSON.parse(error);
                }

                if (typeof error === 'object') {
                    if (error.status === 404) {
                        errorMessage = 'sessionDownloadNoDownloadAvailable';
                    }
                }
            } catch {}

            if (!errorMessage) {
                errorMessage = 'sessionDownloadErrorMessage';
            }
        }

        const downloadRecordingsDialog = <TranslatedString id={errorMessage} />;
        const downloadRecordingsFooter = (
            <div>
                <Button
                    className="button neutral"
                    label={getTranslatedString(this.props.language, 'ok')}
                    onClick={this.props.onDownloadCancel}
                />
            </div>
        );
        return { downloadRecordingsDialog, downloadRecordingsFooter };
    }

    render() {
        const downloadRecordingsHeader = (
            <TranslatedString id={this.props.header} />
        );
        let downloadRecordingsDialog, downloadRecordingsFooter;

        if (!this.props.error && !this.state.error) {
            if (this.state.step === 'ready') {
                ({
                    downloadRecordingsDialog,
                    downloadRecordingsFooter
                } = this.renderRecordingsDownload());
            } else if (this.state.step === 'downloading') {
                ({
                    downloadRecordingsDialog,
                    downloadRecordingsFooter
                } = this.renderRecordingsDownloading());
            } else {
                ({
                    downloadRecordingsDialog,
                    downloadRecordingsFooter
                } = this.renderRecordingsPrepare());
            }
        } else {
            ({
                downloadRecordingsDialog,
                downloadRecordingsFooter
            } = this.renderDownloadError());
        }

        return (
            <React.Fragment>
                <Dialog
                    className="dialog recordings-download-dialog"
                    visible={this.props.visible}
                    closeOnEscape={true}
                    closable={false}
                    header={downloadRecordingsHeader}
                    footer={downloadRecordingsFooter}
                    onHide={this.props.onDownloadCancel}
                >
                    {downloadRecordingsDialog}
                </Dialog>
                <form className="hidden" ref={this.download} method="POST">
                    <input ref={this.token} name="token" readOnly={true} />
                </form>
                <iframe
                    className="hidden"
                    title="downloadResult"
                    name="downloadResult"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.sessions.isLoading,
        isEditing: state.sessions.isEditing,
        sessionToEdit: state.sessions.sessionToEdit,
        sessions: state.sessions.sessions,
        contentRange: state.sessions.contentRange,

        exportData: state.sessions.exportData,

        prepareDownloadState: state.sessions.prepareDownloadState,
        downloadLink: state.sessions.downloadLink,
        downloadCancelled: state.sessions.downloadCancelled,
        downloadError: state.sessions.downloadError,

        user: state.auth.user,
        service: state.auth.service,
        language: state.i18n.language
    };
};

const mapDispatchToProps = {
    requestSessions,
    prepareRecordingsDownloadDenied,
    prepareRecordingsDownload,
    resetRecordingsDownload,
    resetExportData,
    startEditingSession,
    stopEditingSession,
    resetSessionsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
