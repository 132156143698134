import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './features/auth/reducer';
import users from './features/users/reducer';
import meetings from './features/meetings/reducer';
import apiKeys from './features/apikeys/reducer';
import sessions from './features/sessions/reducer';
import adminUsers from './features/adminusers/reducer';
import services from './features/services/reducer';
import i18n from './features/base/i18n/reducer';
import messages from './features/base/messages/reducer';

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth,
        users,
        meetings,
        apiKeys,
        sessions,
        adminUsers,
        services,
        i18n,
        messages
    });

export default rootReducer;
